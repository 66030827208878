import React, { FC } from "react";
import { Clause } from "../../../../@types/types";
import { Row } from "../../../../documents/components/Row";
import { asCurrency, asPercent, chunkCountriesArray, pluralize, regionToCountries } from "../../helpers";
import { parseSubmission } from "../../parseSubmission";
import { BrokerSecurity, Exhibition, PolicyDocumentProps, Region } from "../../types";

export const Annual_schedule: FC<PolicyDocumentProps> = ({ contract, quote, customClauses, regions }) => {
  const { storage, insured, ...parsedSubmission } = parseSubmission(quote.submission, "cargo-us-annual");
  const storages = storage.storages;

  const isSecurity1 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurity2 = contract.placingBroker.security === BrokerSecurity.security2;
  const firstPostLimit = parsedSubmission?.conveyance?.conveyances?.find((conveyance) => conveyance.type === "Post")
    ?.limit?.amount;
  const firstPostLimitFormatted = firstPostLimit !== undefined && asCurrency(firstPostLimit);

  const conveyanceSea = parsedSubmission?.conveyance?.conveyances?.some((conveyance) => conveyance.type === "Sea");
  const conveyanceAirOnly = parsedSubmission?.conveyance?.conveyances?.some(
    (conveyance) => conveyance.type === "Air only",
  );
  const conveyanceRoadOnly = parsedSubmission?.conveyance?.conveyances?.some(
    (conveyance) => conveyance.type === "Road only",
  );
  const conveyanceRailOnly = parsedSubmission?.conveyance?.conveyances?.some(
    (conveyance) => conveyance.type === "Rail only",
  );
  const conveyanceTypes = [];
  if (conveyanceSea) {
    conveyanceTypes.push("Sea");
  }
  if (conveyanceAirOnly) {
    conveyanceTypes.push("Air");
  }
  if (conveyanceRoadOnly) {
    conveyanceTypes.push("Road");
  }
  if (conveyanceRailOnly) {
    conveyanceTypes.push("Rail");
  }

  return (
    <section aria-labelledby="policySchedule">
      <h1 id="policySchedule">Policy schedule</h1>

      <table className="styled" style={{ marginBottom: "14px" }}>
        <colgroup>
          <col width="25%" />
        </colgroup>
        <tbody>
          <Row title="Policy number" id="policyNumber">
            {contract.ref}
          </Row>

          <Row title="Assured" id="assured">
            {insured.name} {insured.subsidiaryCompaniesFormatted && ` and/or ${insured.subsidiaryCompaniesFormatted}`}{" "}
            {insured.associatedCompaniesFormatted && ` and/or ${insured.associatedCompaniesFormatted}`}
          </Row>

          <Row title="Address" id="address">
            {insured.formattedAddress}
          </Row>

          <Row title="Period" id="period">
            <p>
              To cover all sendings and storage risks as within where risk commences on or after{" "}
              {parsedSubmission.contract.inceptionDateFormatted} for a period of{" "}
              {parsedSubmission.contract.type === "annual" ? "twelve calendar months" : "time"} ending on{" "}
              {parsedSubmission.contract.expiryDateFormatted}
            </p>
          </Row>

          <Row title="Subject-matter" id="subjectMatter">
            <p>{parsedSubmission.cargo.condition} Condition</p>

            <table className="styled nested">
              <colgroup>
                <col width="20%" />
                <col width="auto" />
                <col width="30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cargo Percentage</th>
                  <th>Cargo Type</th>
                  <th>Temperature Shipped at</th>
                </tr>
              </thead>
              <tbody>
                {parsedSubmission?.cargo?.cargos?.map((cargo, index) => (
                  <tr key={index.toString()}>
                    <td>{asPercent(cargo.percentage)}</td>
                    <td>{cargo.type} and \ or similar interests</td>
                    <td>
                      {cargo.tempControl !== undefined
                        ? cargo.tempControl === "No"
                          ? "Not Applicable"
                          : cargo.tempControl
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <p>It is a condition of this insurance that all goods are suitably packed or protected for transit.</p>

            {parsedSubmission.cargo.condition === "Fully reconditioned to a new standard" && (
              <p>It is a condition of this insurance that all goods are fully reconditioned to a new standard</p>
            )}

            {parsedSubmission.samples.hasSamples && (
              <p>Including tradesman’s tools and / or samples in vehicles owned, leased or hired by the Assured</p>
            )}
          </Row>

          <Row title="Basis of valuation" id="basisOfValuation">
            <p>
              {parsedSubmission.cargo.basisOfValuation === "CIF+"
                ? `CIF+ ${(parsedSubmission?.cargo?.basisOfValuationPercentage ?? 0) * 100}%`
                : parsedSubmission.cargo.basisOfValuation}
            </p>

            {parsedSubmission.cargo.addDuty && (
              <p>
                Also to pay increased value by reason of Duty, Excise, Surcharge and/or Landing and similar charges, if
                incurred in anticipation of arrival and provided declared to and accepted by underwriters. The assured
                agrees to take all reasonable steps to obtain a refund of such charges and return the net amount to
                underwriters.
              </p>
            )}

            {parsedSubmission.shipment.inlandTransit && (
              <p>
                <b>In respect of Inland Transits the following Basis of Valuations apply:</b>
                <br />
                Goods with an agreed sales contract – Sales Invoice Price
                <br />
                Goods with no sales contract agreed – Replacement Cost to the Assured
                <br />
                Secondhand and/or used goods with no sales contract agreed – 2nd Hand Market Value
              </p>
            )}

            {(parsedSubmission.exhibition.hasExhibitions || parsedSubmission.samples.hasSamples) && (
              <p>
                <b>
                  In respect of Samples or goods to / from or on Exhibition the following Basis of Valuation applies:
                </b>
                <br />
                Replacement Cost to the Assured less an allowance deprecation for fair wear & tear if applicable
              </p>
            )}

            {storage.hasStorages && (
              <p>
                <b>In respect of goods in agreed storage locations the following Basis of Valuations apply:</b>
                <br />
                Goods with an agreed sales contract – Sales Invoice Price
                <br />
                Goods with no sales contract agreed – Replacement Cost to the Assured
                <br />
                Secondhand and/or used goods with no sales contract agreed – 2nd Hand Market Value
              </p>
            )}
          </Row>

          <Row title="Conveyances" id="Conveyances">
            {conveyanceTypes.length !== 0 && (
              <p>
                {conveyanceTypes.length === 4 && (
                  <>
                    Per {conveyanceTypes[0]}, {conveyanceTypes[1]}, {conveyanceTypes[2]} and {conveyanceTypes[3]}{" "}
                    Conveyances
                  </>
                )}
                {conveyanceTypes.length === 3 && (
                  <>
                    Per {conveyanceTypes[0]}, {conveyanceTypes[1]} and {conveyanceTypes[2]} Conveyances
                  </>
                )}
                {conveyanceTypes.length === 2 && (
                  <>
                    Per {conveyanceTypes[0]} and {conveyanceTypes[1]} Conveyances
                  </>
                )}
                {conveyanceTypes.length === 1 && <>Per {conveyanceTypes[0]} Conveyances</>}
              </p>
            )}

            <p>
              {parsedSubmission?.conveyance?.conveyances?.some((conveyance) => conveyance.aovTransits)
                ? "Including Vehicles Owned, Hired or Leased by the Assured."
                : "Excluding Vehicles Owned, Hired or Leased by the Assured unless specifically agreed by Underwriters."}
            </p>

            <p>
              {parsedSubmission?.conveyance?.conveyances?.some((conveyance) => conveyance.type === "Post")
                ? "Including sendings by Post."
                : "Excluding sendings by Post unless specifically agreed by Underwriters."}
            </p>
          </Row>

          <Row title="Voyages" id="voyages">
            <p>
              From {insured.country} to{" "}
              {parsedSubmission.shipment.shipments?.map((shipment) => shipment.country || shipment.region).join(", ")}{" "}
              or vice versa subject to excluded or restricted countries as below
            </p>

            {parsedSubmission?.shipment.inlandTransit && (
              <p>Including inland / domestic transits within {insured.address?.country}</p>
            )}

            <p>Including trans-shipment risks whether customary or otherwise.</p>

            {parsedSubmission.exhibition.hasExhibitions &&
              parsedSubmission?.exhibition?.exhibitions?.map((exhibition: Exhibition, index) => (
                <p key={index.toString()}>
                  Including {exhibition.number} {pluralize("exhibition", exhibition.number)} per annum in{" "}
                  {exhibition.region} up to{" "}
                  {exhibition?.limit?.amount !== undefined && asCurrency(exhibition.limit.amount)}, including transit
                  to, whilst there for a period of up to{" "}
                  {exhibition.duration === "< 7 days" ? "7 days" : exhibition.duration} and including return transit
                  after exhibition if required.
                </p>
              ))}

            {storage.hasStorages && (
              <>
                <p>
                  Including whilst in storage at your own {storages?.length} {pluralize("location", storages?.length)}{" "}
                  as follows:
                </p>

                <table className="styled nested">
                  <colgroup>
                    <col width="20%" />
                    <col width="auto" />
                    <col width="30%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Location Number</th>
                      <th>Location Address</th>
                      <th>Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storages?.map((storage, index) => (
                      <tr key={index.toString()}>
                        <td>{storage.seqNumber}</td>
                        <td>{storage.formattedAddress}</td>
                        <td>{storage.limit?.amount !== undefined && asCurrency(storage.limit.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            <p>
              <b id="definitionOfGeographicalAreas">Definition of Geographical Areas</b>
              <br />
              For Definitions of Geographical Areas, please see Addendum below.
            </p>

            <p>
              <b>Excluded Countries:</b>
              <br />
              Afghanistan, Belarus, Crimea and the Donetsk People’s Republic (DPR), Cuba, Iran, Iraq, Libya, Nigeria,
              North Korea, Russia, Somalia, Sudan and Sudan South, Syria, Ukraine, Yemen, Zaire, Zimbabwe and any other
              country on which the United Nations or other recognised body has imposed trading Sanctions as per the
              Sanction Limitation and Exclusion Clause below without prior approval of Underwriters.
            </p>

            <p>Certain Countries are excluded from War and Strikes coverage as per the War and Strikes section.</p>
          </Row>

          <Row title="Limits" id="limits">
            {parsedSubmission?.conveyance?.conveyances?.length !== 0 && (
              <>
                <table className="styled nested" data-testid="conveyances">
                  <colgroup>
                    <col width="40%" />
                    <col width="auto" />
                    <col width="30%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Conveyance Type</th>
                      <th>Limit</th>
                      <th>Any own Vehicle Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parsedSubmission?.conveyance?.conveyances?.map((conveyance) => (
                      <tr key={conveyance?.seqNumber?.toString()}>
                        <td>
                          {conveyance.type} {conveyance.type === "Sea" ? conveyance.containerType : ""}
                        </td>
                        <td>
                          {conveyance?.limit?.amount !== undefined && asCurrency(conveyance.limit.amount)} <br />
                          {conveyance?.type === "Post" && "Any one sending by Post"}
                        </td>
                        <td>
                          {conveyance?.aovTransits && (
                            <>
                              {conveyance?.ownVehicleLimit?.amount !== undefined &&
                                asCurrency(conveyance.ownVehicleLimit.amount)}
                              <br />
                              In respect of any one owned, hired or leased vehicle at the control of the Assured.
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <p>any one vessel, aircraft, road or rail conveyance or location</p>
              </>
            )}

            {parsedSubmission.exhibition.hasExhibitions && (
              <table className="styled nested" data-testid="exhibitions">
                <colgroup>
                  <col width="20%" />
                  <col width="auto" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Exhibition Number</th>
                    <th>Exhibition Location</th>
                    <th>Exhibition Duration</th>
                    <th>Limit</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedSubmission?.exhibition?.exhibitions?.map((exhibition, index) => (
                    <tr key={index.toString()}>
                      <td>{exhibition.number}</td>
                      <td>{exhibition.region}</td>
                      <td>{exhibition.duration}</td>
                      <td>{exhibition?.limit?.amount !== undefined && asCurrency(exhibition.limit.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {parsedSubmission?.samples.hasSamples && (
              <p>
                {parsedSubmission?.samples.limit?.amount !== undefined &&
                  asCurrency(parsedSubmission.samples.limit.amount)}{" "}
                in respect of Samples per vehicle
              </p>
            )}

            {storage.hasStorages && (
              <table className="styled nested" data-testid="storages">
                <colgroup>
                  <col width="20%" />
                  <col width="auto" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Location Number</th>
                    <th>Location Address</th>
                    <th>Limit</th>
                  </tr>
                </thead>
                <tbody>
                  {storages?.map((storage, index) => (
                    <tr key={index.toString()}>
                      <td>{storage.seqNumber}</td>
                      <td>{storage.formattedAddress}</td>
                      <td>{storage.limit?.amount !== undefined && asCurrency(storage.limit.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Row>

          <Row title="Terms & conditions" id="termsConditions">
            <p>Standard Marine Cargo Insurance Conditions as attached</p>

            <p>
              {parsedSubmission.cargo.condition === "Used" || parsedSubmission.cargo.iccClause
                ? "Institute Cargo Clauses (C) CL384 dated 1.1.2009 extended to include the risks of theft or non-delivery of an entire consignment or full container, trailer or vehicle load."
                : "Institute Cargo Clauses (A) CL 382 dated 1.1.2009 and/or Institute Cargo Clauses (Air) CL 387 dated 1.1.2009 as applicable."}
            </p>

            {parsedSubmission.cargo.cargos?.some(
              (cargo) => cargo.tempControl === "Frozen below minus 15 Centigrade",
            ) && (
              <p>
                Institute Frozen Foods Clauses (A) – 24 Breakdown applies for frozen goods below -15 (minus fifteen)
                degrees Celsius or 5 (five) degrees Fahrenheit
              </p>
            )}

            {parsedSubmission.cargo.condition === "Used" && <p>Subject also to the Second Hand Replacement Clause.</p>}

            <p>
              Institute War Clauses (Cargo) CL 385 dated 1.1.2009 and/or Institute War Clauses (Air Cargo) CL 388 dated
              1.1.2009 or Institute War Clauses (sendings by Post) CL390 dated 1.3.2009 as applicable.
              <br />
              Institute Strikes Clauses (Cargo) CL 386 dated 1.1.2009 and/or Institute Strikes Clauses (Air Cargo) CL
              389 dated 1.1.2009 as applicable.{" "}
              {parsedSubmission.cargo.cargos?.some(
                (cargo) => cargo.tempControl === "Frozen below minus 15 Centigrade",
              ) &&
                "Institute Strikes Clauses (Frozen Food) applies for frozen goods below -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit"}
            </p>

            <p>Certain Countries are excluded from War and Strikes coverage as per the War and Strikes section</p>

            {storage.hasStorages && <p>Storage Clause as below:</p>}

            <p>
              It is a condition of this insurance that the Cargo Type(s) selected match accurately the cargo intended to
              be insured under this policy or no cover is deemed to be in force.
            </p>

            <p>
              It is a condition of this insurance that any loss history supplied to Underwriters is entered with
              accurate information.
            </p>

            {parsedSubmission.cargo.condition === "Fully reconditioned to a new standard" && (
              <p>Reconditioned cargo is subject to the Reconditioned Goods Clause.</p>
            )}

            {parsedSubmission.shipment.shipments?.some((shipment) => shipment?.contingentTurnover?.amount > 0) && (
              <p>
                Including the assured’s contingent interest in any goods for which they have no responsibility to insure
                under the terms of sale or purchase as per the Contingent Interest Clause.
              </p>
            )}

            {parsedSubmission.exhibition.hasExhibitions && <p>Exhibition Conditions as attached apply</p>}

            {parsedSubmission.samples.hasSamples && (
              <p>
                Including tradesman’s tools and / or samples as per the attached clause in{" "}
                {parsedSubmission.samples.numVehicles} vehicles.
              </p>
            )}

            {insured.address?.state === "Florida" && (
              <p>
                This insurance is issued pursuant to the Florida surplus lines law. Persons insured by surplus lines
                carriers do not have the protection of the Florida Insurance Guaranty Act to the extent of any right of
                recovery for the obligation of an insolvent unlicensed insurer.
              </p>
            )}

            {customClauses?.map((clause: Clause, index) => (
              <p key={index.toString()}>{clause.title} as attached apply</p>
            ))}
          </Row>

          <Row title="Deductibles" id="deductibles">
            <table className="styled nested" data-testid="cargos">
              <colgroup>
                <col width="20%" />
                <col width="auto" />
                <col width="30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cargo Number</th>
                  <th>Cargo Type</th>
                  <th>Deductible for each & every claim</th>
                </tr>
              </thead>
              <tbody>
                {parsedSubmission?.cargo?.cargos?.map((cargo, index) => (
                  <tr key={index.toString()}>
                    <td>{cargo.seqNumber}</td>
                    <td>{cargo.type}</td>
                    <td>{cargo?.deductible?.amount !== undefined && asCurrency(cargo.deductible.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {storage.hasStorages && (
              <p>
                <b>Storage</b> <br />
                Subject to deductible of $2,500 each and every claim.
                <br />
                Increased to $5,000 in respect of Flood, Earthquake and Windstorm claims unless shown below:
              </p>
            )}

            {storages?.some((storage) => storage.perils?.some((peril) => peril.level !== "None")) && (
              <table className="styled nested" data-testid="perils">
                <colgroup>
                  <col width="20%" />
                  <col width="auto" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Storage State</th>
                    <th>Storage Limit</th>
                    <th>Quake Deductible</th>
                    <th>Windstorm Deductible</th>
                  </tr>
                </thead>
                <tbody>
                  {storages?.map((storage, index) => (
                    <tr key={index.toString()}>
                      <td>{storage.address?.state}</td>
                      <td>{storage.limit?.amount !== undefined && asCurrency(storage.limit.amount)}</td>
                      <td>
                        {storage.perils?.find((peril) => peril.type === "Quake" && peril.level === "High") &&
                          "20% of Storage Limit Deductible minimum US$5,000 maximum US$50,000"}
                        {storage.perils?.find((peril) => peril.type === "Quake" && peril.level === "Medium") &&
                          "10% of Storage Limit Deductible minimum US$5,000 maximum US$25,000"}
                      </td>
                      <td>
                        {storage.perils?.find(
                          (peril) =>
                            (peril.type === "Windstorm" && peril.level === "High") ||
                            (peril.type === "Inland Wind" && peril.level === "High"),
                        ) && "10% of Storage Limit Deductible minimum US$5,000 maximum US$25,000"}

                        {storage.perils?.find(
                          (peril) =>
                            (peril.type === "Windstorm" && peril.level === "Medium") ||
                            (peril.type === "Inland Wind" && peril.level === "Medium"),
                        ) && "5% of Storage Limit Deductible minimum US$5,000 maximum US$15,000"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Row>

          <Row title="Premium:" id="premium">
            <p>
              Transits subject to a Minimum & Deposit premium of{" "}
              {quote?.rates?.basePremium?.grossPremium !== undefined &&
                asCurrency(quote.rates.basePremium.grossPremium)}
              . This premium is non adjustable unless the total turnover exceeds 15% of the expected turnover for the
              period when underwriters reserve the right to charge an additional premium to be calculated in proportion
              to the original premium plus any applicable taxes.
            </p>

            {storage.hasStorages && (
              <p>
                In addition{" "}
                {quote?.rates?.storagePremium?.grossPremium !== undefined &&
                  asCurrency(quote.rates.storagePremium.grossPremium)}
                {quote?.rates?.storagesPremium?.grossPremium !== undefined &&
                  asCurrency(quote.rates.storagesPremium.grossPremium)}{" "}
                in full annual minimum premium in respect of storage locations as above.
              </p>
            )}

            {parsedSubmission.exhibition.hasExhibitions && (
              <p>
                In addition{" "}
                {quote?.rates?.exhibitionsPremium?.grossPremium !== undefined &&
                  asCurrency(quote.rates.exhibitionsPremium.grossPremium)}{" "}
                annual minimum premium in respect of{" "}
                {parsedSubmission.exhibition.exhibitions?.reduce(
                  (prev, exhibition) => (exhibition.number !== undefined ? prev + exhibition.number : 0),
                  0,
                )}{" "}
                exhibitions.
              </p>
            )}

            {parsedSubmission.samples.hasSamples && (
              <p>
                In addition{" "}
                {quote?.rates?.samplesPremium?.grossPremium !== undefined &&
                  asCurrency(quote.rates.samplesPremium.grossPremium)}{" "}
                in full annual minimum premium in respect of samples in up to {parsedSubmission.samples.numVehicles}{" "}
                vehicles.
              </p>
            )}
          </Row>

          <Row title="Taxes payable by the assured:" id="taxesPayableByTheAssured">
            <p>As per local requirement.</p>
          </Row>

          <Row title="Insurer contract documentation:" id="insurerContractDocumentation">
            <p>
              This document details the contract terms entered into by the Underwriters and constitutes the contract
              document attached.
            </p>
          </Row>

          <Row title="Cancellation:" id="cancellation">
            <p>
              Subject to:
              <br />
              30 days&apos; notice of cancellation from either party at any time for Marine risks,
              <br />7 days&apos; notice of cancellation for War, Strikes, Riots and Civil Commotions risks, but 48
              hours&apos; notice of cancellation for Strikes, Riots, and Civil Commotions risks in respect of sendings
              to or from U.S.A.
            </p>
            <p>
              Cancellation shall become effective on the expiry of the appropriate number of days from midnight on the
              day on which notice of cancellation is issued by Assured or by Underwriters but shall not apply to any
              transit insurance which shall have been declared or attached in accordance with contract terms and
              conditions before the cancellation becomes effective
            </p>
          </Row>

          <Row title="Security:" id="security">
            {isSecurity1 && (
              <p>
                100% Chaucer Syndicates 1084 at Lloyd&apos;s. Lineslip reference number{" "}
                {contract.placingBroker?.annual?.binding_authority}
              </p>
            )}

            {isSecurity2 && (
              <p>
                100% Chaucer Insurance Company DAC. Lineslip reference number{" "}
                {contract.placingBroker?.annual?.binding_authority}
              </p>
            )}
          </Row>

          {parsedSubmission.contract.isInceptionBeforeToday && (
            <Row title="PRIOR NOTICE:" id="priorNotice">
              <p>
                If the insurance hereunder is attached prior to the date of acceptance, the insured warrants that there
                are no known or reported losses which might be recoverable under this insurance as of the date that this
                insurance is accepted.
              </p>
            </Row>
          )}

          {regions
            ?.slice?.()
            .sort?.()
            .map?.((region: Region) => (
              <Row
                key={region}
                id={region === "Africa" ? "addendum" : ""}
                title={region === "Africa" ? "ADDENDUM" : ""}
              >
                {region === "Africa" && (
                  <p>
                    <b id="addendumDefinitionOfGeographicalAreas">Definition of Geographical Areas:</b>
                  </p>
                )}
                <table style={{ width: "100%", pageBreakInside: "avoid", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th colSpan={3}>{region}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {chunkCountriesArray(regionToCountries(region)).map((countries, index) => (
                        <td key={`countries-${index}`} style={{ verticalAlign: "top", lineHeight: "1.5" }}>
                          {countries.map((country) => (
                            <React.Fragment key={country}>
                              {country}
                              <br />
                            </React.Fragment>
                          ))}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Row>
            ))}
        </tbody>
      </table>
    </section>
  );
};
