import React, { FC } from "react";
import { PageBreak } from "../../../../documents/components/PageBreak";
import { BrokerSecurity, PolicyDocumentProps } from "../../types";
import { Single_info_provided_to_underwriters } from "./10_2_Single_info_provided_to_underwriters";
import { Single_summary_of_coverage } from "./11_2_Single_summary_of_coverage";
import { Single_household_goods_and_personal_effects } from "./12_0_Single_household_goods_and_personal_effects";
import { Single_motor_vehicle_conditions } from "./13_0_Single_motor_vehicle_conditions";
import { Single_Intro } from "./1_0_Single_intro";
import { Single_schedule } from "./2_3_Single_schedule";
import { Single_standard } from "./3_3_Single_standard";
import { Single_own_vehicle } from "./4_0_Single_own_vehicle";
import { Single_exhibition } from "./5_1_Single_exhibition";
import { Single_insureds_obligation } from "./6_2_Single_insureds_obligation";
import { Single_claims } from "./7_2_Single_claims";
import { Single_general_information_and_1084_security } from "./8_1_Single_general_information_and_1084_security";
import { Single_CIC_security_and_complaints } from "./9_2_Single_CIC_security_and_complaints";

export const SinglePolicyDocument: FC<PolicyDocumentProps> = ({
  contract,
  quote,
  clauses,
  customClauses,
  parsedSubmission,
  regions,
}) => {
  const isSecurityCSL1084 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurityCIC = contract.placingBroker.security === BrokerSecurity.security2;

  return (
    <div className="document-single">
      <Single_Intro contract={contract} quote={quote} />
      <PageBreak />

      <Single_schedule contract={contract} quote={quote} />
      <PageBreak />

      <Single_standard contract={contract} quote={quote} />
      <PageBreak />

      {parsedSubmission?.cargo.cargos?.[0]?.group === 5 && (
        <>
          <Single_household_goods_and_personal_effects contract={contract} quote={quote} />
          <PageBreak />
        </>
      )}

      {parsedSubmission?.cargo.cargos?.[0]?.group === 6 && (
        <>
          <Single_motor_vehicle_conditions contract={contract} quote={quote} />
          <PageBreak />
        </>
      )}

      {parsedSubmission?.conveyance?.conveyances?.[0]?.aovTransits && (
        <>
          <Single_own_vehicle />
          <PageBreak />
        </>
      )}
      {parsedSubmission?.exhibition.hasExhibitions && (
        <>
          <Single_exhibition />
          <PageBreak />
        </>
      )}
      <Single_insureds_obligation contract={contract} quote={quote} />
      <PageBreak />

      <Single_claims contract={contract} quote={quote} />
      <PageBreak />

      {isSecurityCSL1084 && (
        <>
          <Single_general_information_and_1084_security contract={contract} quote={quote} />
          <PageBreak />
        </>
      )}
      {isSecurityCIC && (
        <>
          <Single_CIC_security_and_complaints contract={contract} quote={quote} />
          <PageBreak />
        </>
      )}

      <Single_info_provided_to_underwriters contract={contract} quote={quote} />
      <PageBreak />

      <Single_summary_of_coverage contract={contract} quote={quote} />
    </div>
  );
};
