export const UMR = {
  ROPNER: ["B085620L2002"],
  GALLAGHER: ["B1262FSM0017921", "B1262FSM0017922"],
  LIG: ["B1586LIG2021LS89", "B1586LIG2022LS89"],
  RAP: ["B1368M215170", "B1368M225207"],
  AH: ["B0999DUA12342021"],
  PB: ["B0507FRG2100008", "B0507FRG2200008"],
  SURE: ["B1922WA000160V"],
};

export default UMR;
