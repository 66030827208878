import clsx from "clsx";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import sortBy from "lodash.sortby";
import React, { useEffect } from "react";
import formatErrors from "./formatErrors";
import { getComponentType } from "./getComponentType";

export const MakeComponent = ({
  ctx,
  className,
  errors,
  formValues,
  isAddButtonEnabled,
  isReadOnly,
  isRemoveButtonEnabled,
  isRequired,
  item,
  mapChildren,
  noErrorLabel,
  onBlur,
  onChange,
  parentKey,
  parentSchema,
  setFormValues,
  showQuestionId,
  useParentKey,
  validationErrors,
  showQuestionKey,
}) => {
  const Component = getComponentType(item);
  const isHidden = get(item, "ui:hidden", get(item, "hidden"));
  const constantValue = get(item, "const");
  const componentClassName = get(item, "ui:component:className", '');
  const isInternalEnv = ["local", "development"].includes(process.env.ENVIRONMENT);
  let ownKey = parentKey === "" ? item.key : [parentKey, item.key].join(".");

  if (useParentKey) {
    ownKey = parentKey;
  }

  useEffect(() => {
    if (constantValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, ownKey, constantValue);
      setFormValues(clonedFormValues);
    }
  }, [constantValue]);

  return (
    <Component
      className={clsx("mb-8", isHidden && "hidden", className, componentClassName)}
      ctx={ctx}
      descriptionText={get(item, "ui:description")}
      errors={
        errors ||
        validationErrors?.find((error) => error.path === ownKey)?.message ||
        validationErrors?.find((error) => error.keyword === "total100" && error.schema === item.key)?.message
      }
      formValues={formValues}
      id={item.key}
      isAddButtonEnabled={isAddButtonEnabled || get(item, "ui:component:add-button-enabled")}
      isReadOnly={isReadOnly || get(item, "ui:component:readonly")}
      isRemoveButtonEnabled={isRemoveButtonEnabled || get(item, "ui:component:remove-button-enabled")}
      isRequired={isRequired || get(parentSchema, ["required"], []).includes(item.key)}
      item={item}
      key={item.key}
      keyFormValues={formValues[parentKey]}
      labelText={
        (showQuestionId && `${item.qid}. ${item.title}`) ||
        (showQuestionKey && (
          <span>
            <mark>{item.key}</mark> {item.title}
          </span>
        )) ||
        item.title
      }
      MakeComponent={MakeComponent}
      mapChildren={mapChildren}
      name={ownKey}
      noErrorLabel={noErrorLabel}
      onBlur={onBlur}
      onChange={onChange}
      options={"enum" in item ? item.enum : undefined}
      ownKey={ownKey}
      parentKey={parentKey}
      parentSchema={parentSchema}
      setFormValues={setFormValues}
      showQuestionId={showQuestionId}
      showQuestionKey={showQuestionKey}
      validationErrors={validationErrors}
      value={get(formValues, ownKey, item.value)}
    />
  );
};

const mapChildren = ({
  ctx,
  className,
  errors,
  formValues = {},
  isAddButtonEnabled = undefined,
  isReadOnly = false,
  isRemoveButtonEnabled = undefined,
  isRequired = false,
  noErrorLabel,
  onBlur,
  onChange,
  parentKey,
  parentSchema,
  setFormValues,
  useParentKey,
  validationErrors,
  showQuestionId,
  showQuestionKey,
}) => {
  const mappedProperties = Object.keys(parentSchema.properties).map((key) => ({
    key,
    ...parentSchema.properties[key],
  }));
  const sortedProperties = sortBy(mappedProperties, (c) => c.qid);

  return sortedProperties.map((item) => (
    <MakeComponent
      ctx={ctx}
      className={className}
      errors={errors}
      formValues={formValues}
      isAddButtonEnabled={isAddButtonEnabled}
      isReadOnly={isReadOnly}
      isRemoveButtonEnabled={isRemoveButtonEnabled}
      isRequired={isRequired}
      item={item}
      key={item.key}
      mapChildren={mapChildren}
      noErrorLabel={noErrorLabel}
      onBlur={onBlur}
      onChange={onChange}
      parentKey={parentKey}
      parentSchema={parentSchema}
      setFormValues={setFormValues}
      showQuestionId={showQuestionId}
      showQuestionKey={showQuestionKey}
      useParentKey={useParentKey}
      validationErrors={formatErrors(validationErrors)}
    />
  ));
};

export default mapChildren;
