// https://drive.google.com/drive/folders/1w68tdRoqC0rG4urCizZhkCUEO1qgLr7o

import React, { FC } from "react";
import { CL } from "../../../../constants/standardClauses";
import { PageBreak } from "../../../../documents/components/PageBreak";
import { BrokerSecurity, PolicyDocumentProps } from "../../types";
import { Annual_Insureds_obligations } from "./10_1_Annual_Insureds_obligations";
import { Annual_Claims } from "./11_1_Annual_Claims";
import { Annual_General_information } from "./12_0_Annual_General_information";
import { Annual_Security_and_customer_complaints } from "./13_0_Annual_Security_and_customer_complaints";
import { Annual_info_provided_to_underwriters } from "./14_1_Annual_info_provided_to_underwriters";
import { Annual_Summary_of_coverage } from "./15_2_Annual_Summary_of_coverage";
import { Annual_Intro } from "./1_0_Annual_Intro";
import { Annual_schedule } from "./2_1_Annual_schedule";
import { Annual_standard } from "./3_3_Annual_standard";
import countries from "../../refData/countries";
import { sanitizeHtml } from "../../helpers";
import { Clause } from "../../../../@types/types";

export const AnnualPolicyDocument: FC<PolicyDocumentProps> = ({
  contract,
  quote,
  clauses,
  customClauses,
  parsedSubmission,
  regions,
}) => {
  const inceptionDateFormatted = parsedSubmission?.contract?.inceptionDateFormatted || "";

  const isSecurity1 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurity2 = contract.placingBroker.security === BrokerSecurity.security2;

  const wordings = clauses?.filter((clause) => clause.type === "wording");
  const exclusions = clauses?.filter((clause) => clause.type === "exclusion");

  const annualStorageClause = wordings?.find((clause) => clause.reference === CL.CLAUSE04);
  const annualOwnVehicle = wordings?.find((clause) => clause.reference === CL.CLAUSE05);
  const annualExhibition = exclusions?.find((clause) => clause.reference === CL.CLAUSE06);
  const annualToolAndSamples = exclusions?.find((clause) => clause.reference === CL.CLAUSE07);
  const annualMotorVehicle = exclusions?.find((clause) => clause.reference === CL.CLAUSE081);
  const annualWarAndStrikes = exclusions?.find((clause) => clause.reference === CL.CLAUSE09);

  let countriesAsHtml = "";

  countries
    .filter((row) => !row?.[4])
    .forEach((row) => (countriesAsHtml += `<div key=${row[0]}>${row[0]}<br /></div>`));

  return (
    <div className="document-annual">
      <Annual_Intro contract={contract} quote={quote} />
      <PageBreak />

      <Annual_schedule contract={contract} quote={quote} customClauses={customClauses} regions={regions} />
      <PageBreak />

      <Annual_standard contract={contract} quote={quote} />
      <PageBreak />

      {parsedSubmission?.storage.hasStorages && annualStorageClause && (
        <>
          <section
            aria-labelledby="storageClause"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(annualStorageClause.body.replace(/{{inceptionDate}}/g, inceptionDateFormatted)),
            }}
          />
          <PageBreak />
        </>
      )}

      {parsedSubmission?.conveyance.conveyances?.some((conveyance) => conveyance.aovTransits) && annualOwnVehicle && (
        <>
          <section
            aria-labelledby="ownVehicle"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(annualOwnVehicle.body) }}
          />
          <PageBreak />
        </>
      )}

      {parsedSubmission?.exhibition.hasExhibitions && annualExhibition && (
        <>
          <section
            aria-labelledby="exhibitionClause"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(annualExhibition.body) }}
          />
          <PageBreak />
        </>
      )}

      {parsedSubmission?.samples.hasSamples && annualToolAndSamples && (
        <>
          <section
            aria-labelledby="toolsAndSamples"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(annualToolAndSamples.body) }}
          />
          <PageBreak />
        </>
      )}

      {annualMotorVehicle && (
        <>
          <section
            aria-labelledby="annualMotorVehicle"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(annualMotorVehicle.body) }}
          />
          <PageBreak />
        </>
      )}

      {customClauses && (
        <div data-testid="custom-clauses">
          {customClauses?.map((clause: Clause, index) => (
            <div key={index.toString()}>
              <section>
                <h1 className="annual-schedule-clauses-title">{clause.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(clause.body.replace(/{{inceptionDate}}/g, inceptionDateFormatted)),
                  }}
                  className="annual-schedule-clauses"
                ></div>
              </section>
              <PageBreak />
            </div>
          ))}
        </div>
      )}

      {annualWarAndStrikes && (
        <>
          <section
            aria-labelledby="warAndStrikes"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(annualWarAndStrikes.body.replace(/{{countries}}/g, countriesAsHtml)),
            }}
          />
          <PageBreak />
        </>
      )}

      <Annual_Insureds_obligations />
      <PageBreak />

      <Annual_Claims />
      <PageBreak />

      {isSecurity1 && (
        <>
          <Annual_General_information />
          <PageBreak />
        </>
      )}

      {isSecurity2 && (
        <>
          <Annual_Security_and_customer_complaints />
          <PageBreak />
        </>
      )}

      <Annual_info_provided_to_underwriters contract={contract} quote={quote} />
      <PageBreak />

      <Annual_Summary_of_coverage contract={contract} quote={quote} />
    </div>
  );
};
