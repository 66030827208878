import { SchemaRef } from "../../@types/types";
import { onlyUnique } from "./helpers";
import { parseSubmission } from "./parseSubmission";
import countries from "./refData/countries";
import { Conveyance } from "./types";

export const checkDeclined = (
  submission: unknown,
  schemaRef: SchemaRef,
): { isDeclined: boolean; reasons: string[] } => {
  const { conveyance, storage, shipment, insured, cargo } = parseSubmission(submission, schemaRef);
  const isSingle = schemaRef === "cargo-us-single";

  const reasons = [];

  if (countries.find((row) => row[1] === insured.country)?.[6]) {
    reasons.push("Declined due to chosen Country");
  }

  if (shipment?.shipments?.some(({ isDeclined }) => isDeclined)) {
    reasons.push("Declined due to chosen Country");
  }

  if (cargo?.cargos?.every((cargo) => cargo.isExcluded)) {
    reasons.push("Declined due to all cargo types being excluded");
  }

  if (cargo?.cargos?.some((cargo) => cargo.isTempControlOther)) {
    reasons.push("Declined due to temperature being 'Other'");
  }

  if (cargo?.cargos?.some((cargo) => cargo.isNotSuitablyPacked)) {
    reasons.push("Declined due to packing declaration");
  }

  if (
    !isSingle &&
    conveyance?.conveyances?.some(
      ({ limit, aovTransits }: Conveyance) => !aovTransits && limit?.amount !== undefined && limit.amount > 1_000_000,
    )
  ) {
    reasons.push("Declined due to a limit being greater than $1million");
  }

  if (
    isSingle &&
    conveyance?.conveyances?.some(
      ({ insuredValue, aovTransits }: Conveyance) => !aovTransits && insuredValue?.amount !== undefined && insuredValue.amount > 1_000_000,
    )
  ) {
    reasons.push("Declined due to a limit being greater than $1million");
  }

  if (
    !isSingle &&
    conveyance?.conveyances?.some(
      ({ ownVehicleLimit, aovTransits }: Conveyance) =>
        aovTransits && ownVehicleLimit?.amount !== undefined && ownVehicleLimit.amount > 1_000_000,
    )
  ) {
    reasons.push("Declined due to AOV limit being greater than $1million");
  }

  if (
    isSingle &&
    conveyance?.conveyances?.some(
      ({ insuredValue, aovTransits }: Conveyance) =>
        aovTransits && insuredValue?.amount !== undefined && insuredValue.amount > 1_000_000,
    )
  ) {
    reasons.push("Declined due to AOV limit being greater than $1million");
  }

  if (storage?.storages?.some(({ locationType }) => locationType === "Retail")) {
    reasons.push("Declined due to location type 'Retail'");
  }

  if (storage?.storages?.some(({ buildingAge }) => buildingAge !== undefined && buildingAge >= 36)) {
    reasons.push("Declined due to building age 36+");
  }

  if (storage?.storages?.some(({ compositePanels }) => compositePanels)) {
    reasons.push("Declined due to composite panels");
  }

  if (shipment?.shipments?.some(({ isDeclined }) => isDeclined)) {
    reasons.push("Declined due to chosen Country");
  }

  if (shipment?.shipments?.some(({ isDeclined }) => isDeclined)) {
    reasons.push("Declined due to chosen Country");
  }

  if (
    insured?.lossHistoryTotal?.losses !== undefined &&
    insured?.lossHistoryTotal?.premiums !== undefined &&
    insured.lossHistoryTotal.losses > insured.lossHistoryTotal.premiums * 0.75
  ) {
    reasons.push("Declined due to the losses being greater than 75% of the premium");
  }

  if (countries.find((row) => row[0] === shipment?.countryTo)?.[6]) {
    reasons.push("Declined due to chosen To Country");
  }

  if (countries.find((row) => row[0] === shipment?.countryFrom)?.[6]) {
    reasons.push("Declined due to chosen From Country");
  }

  const uniqueReasons = reasons.filter(onlyUnique);

  return { isDeclined: uniqueReasons.length > 0, reasons: uniqueReasons };
};
