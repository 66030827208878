import { DMSDocument } from "../../@types/types";
import { parseSubmission } from "./helpers";
import { MelSubmission } from "./types";
import { ENDORSEMENT_MATERIAL_REASONS } from "./adjustablePremium";

type ReferredOutput = {
  isReferred: boolean;
  hasEndoReasons?: boolean;
  hasMaterialReasons?: boolean;
  hasOriginalReasons?: boolean;
  reasons: string[];
};

const referableBusinessTypes = [
  "Anchor handler",
  "Casino boat operations",
  "Contractor - Caterer",
  "Contractor - Dredging",
  "Contractor - Drilling",
  "Contractor - Oil Spill Response",
  "Diver",
  "Labour supply company",
  "Other",
  "Rigger",
  "Roustabout",
  "Vessel owner/operator",
];

const onlyUnique = (value: string, index: number, arr: string[]): boolean => arr.indexOf(value) === index;

const getOnlyNewReasons = (arr1: string[], arr2: string[]): string[] =>
  arr2.filter((obj: string) => !arr1.some((obj2: string) => obj == obj2));

export const checkEndoReferred = ({
  documents,
  isBespoke,
  isCommercial,
  isFinalAdjustmentPremium,
  shouldReferIfJonesActEndorsed,
  hasExistingMaterialEndorsements,
  nextSubmission,
  submission,
}: {
  documents?: DMSDocument[];
  isBespoke?: boolean;
  isCommercial?: boolean;
  isFinalAdjustmentPremium?: boolean;
  shouldReferIfJonesActEndorsed?: boolean;
  hasExistingMaterialEndorsements?: boolean;
  nextSubmission?: MelSubmission;
  submission: MelSubmission;
}): ReferredOutput => {
  const reasons = [];
  const endoReasons = [];
  const endoMaterialReasons = [];
  const parsedSubmission = parseSubmission(submission);
  const nextParsedSubmission = parseSubmission(nextSubmission);

  if (documents && documents.length > 0) {
    reasons.push("The broker has uploaded an attachment.");
  }

  // TODO: add test
  if (
    parsedSubmission?.businessType !== nextParsedSubmission?.businessType &&
    nextParsedSubmission.businessType &&
    referableBusinessTypes.includes(nextParsedSubmission.businessType)
  ) {
    reasons.push("Business type has been endorsed.");
  }

  if (parsedSubmission?.diving !== nextParsedSubmission?.diving) {
    reasons.push("Diving operations question has been endorsed.");
  }

  if (parsedSubmission?.timeOnboardWatercraft !== nextParsedSubmission?.timeOnboardWatercraft) {
    reasons.push("Time employees spent on watercraft question has been endorsed.");
  }

  if (
    parsedSubmission?.hasJonesActClaims !== nextParsedSubmission?.hasJonesActClaims ||
    parsedSubmission?.jonesActClaimsPayload !== nextParsedSubmission?.jonesActClaimsPayload
  ) {
    reasons.push("Jones act claim question has been endorsed.");
  }

  if (parsedSubmission?.notWorkingOnPlatforms !== nextParsedSubmission?.notWorkingOnPlatforms) {
    reasons.push("Floating platforms/semisubmersible question has been endorsed.");
  }

  if (parsedSubmission?.oneOffJob !== nextParsedSubmission?.oneOffJob) {
    reasons.push("One off contract question has been endorsed.");
  }

  if (parsedSubmission?.watercraftHasCaptain !== nextParsedSubmission?.watercraftHasCaptain) {
    reasons.push("Watercraft has dedicated captain or crew question has been endorsed.");
  }

  const isJonesActEndorsed =
    parsedSubmission?.jonesActPayrollEmployees !== nextParsedSubmission?.jonesActPayrollEmployees ||
    parsedSubmission?.jonesActPayroll?.amount !== nextParsedSubmission?.jonesActPayroll?.amount;
  if (
    (isJonesActEndorsed && !isFinalAdjustmentPremium) ||
    (isJonesActEndorsed && isFinalAdjustmentPremium && shouldReferIfJonesActEndorsed)
  ) {
    reasons.push("Jones Act payroll has been endorsed.");
  }

  if (parsedSubmission?.overwaterOperationDescription !== nextParsedSubmission?.overwaterOperationDescription) {
    endoReasons.push("Insured's overwater operation has been endorsed.");
  }

  if (parsedSubmission?.expiryDate !== nextParsedSubmission?.expiryDate) {
    endoReasons.push("Policy Expiry date has been endorsed.");
  }

  if (isFinalAdjustmentPremium && hasExistingMaterialEndorsements) {
    endoReasons.push("This final adjustment premium endorsement has existing material endorsements.");
  }

  if (parsedSubmission?.limit?.amount !== nextParsedSubmission?.limit?.amount) {
    endoMaterialReasons.push(ENDORSEMENT_MATERIAL_REASONS.LIMIT);
  }

  if (
    !isFinalAdjustmentPremium &&
    parsedSubmission?.totalPayrollNextYear?.amount !== nextParsedSubmission?.totalPayrollNextYear?.amount
  ) {
    endoMaterialReasons.push(ENDORSEMENT_MATERIAL_REASONS.PAYROLL);
  }

  if (parsedSubmission?.trialTrips !== nextParsedSubmission?.trialTrips) {
    endoMaterialReasons.push(ENDORSEMENT_MATERIAL_REASONS.TRIAL_TRIPS_SELECTION);
  }

  if (parsedSubmission?.tripra !== nextParsedSubmission?.tripra) {
    endoMaterialReasons.push(ENDORSEMENT_MATERIAL_REASONS.TRIPRA);
  }

  // TODO: check if deductible can be changed and if this will ever trigger
  if (parsedSubmission?.deductible?.amount !== nextParsedSubmission?.deductible?.amount) {
    endoMaterialReasons.push(ENDORSEMENT_MATERIAL_REASONS.DEDUCTIBLE);
  }

  const finalReasons = [...reasons, ...endoReasons, ...endoMaterialReasons];
  const isTechnical = !isCommercial;
  const hasEndoReasons = endoReasons.length > 0;
  const hasMaterialReasons = endoMaterialReasons.length > 0;
  const hasOriginalReasons = reasons.length > 0;

  let isReferred = true;

  if (isTechnical && hasMaterialReasons && !hasEndoReasons && !hasOriginalReasons) {
    isReferred = false;
  }

  if (isTechnical && !hasMaterialReasons && !hasEndoReasons && !hasOriginalReasons) {
    isReferred = false;
  }

  if (isCommercial && !hasMaterialReasons && !hasEndoReasons && !hasOriginalReasons) {
    isReferred = false;
  }

  if (isBespoke) {
    isReferred = true;
    finalReasons.push("A bespoke endorsement has been added.");
  }

  return { isReferred, reasons: finalReasons, hasEndoReasons, hasMaterialReasons, hasOriginalReasons };
};

export const checkReferred = ({
  documents,
  isBespoke,
  isCommercial,
  isEndorsement,
  nextSubmission,
  submission,
  isRenewal,
  renewalParentReferralReasons,
  hasBespokeEndorsements,
  isFinalAdjustmentPremium,
  shouldReferIfJonesActEndorsed,
  hasExistingMaterialEndorsements,
}: {
  documents?: DMSDocument[];
  isBespoke?: boolean;
  isCommercial?: boolean;
  isEndorsement?: boolean;
  nextSubmission?: MelSubmission;
  submission: MelSubmission;
  isRenewal?: boolean;
  renewalParentReferralReasons?: [];
  hasBespokeEndorsements?: boolean;
  isFinalAdjustmentPremium?: boolean;
  shouldReferIfJonesActEndorsed?: boolean;
  hasExistingMaterialEndorsements?: boolean;
}): ReferredOutput => {
  const parsedSubmission = parseSubmission(submission);
  const reasons = [];

  if (isEndorsement && nextSubmission !== undefined) {
    return checkEndoReferred({ submission, nextSubmission, isCommercial, documents, isBespoke, isFinalAdjustmentPremium, shouldReferIfJonesActEndorsed, hasExistingMaterialEndorsements });
  }

  if (nextSubmission !== undefined) {
    // TODO: comparing reasons most likely not enough, e.g. business type
    const { reasons: prevReasons } = checkReferred({ submission: submission });
    const { reasons: nextReasons } = checkReferred({ submission: nextSubmission });
    const reasons = getOnlyNewReasons(prevReasons, nextReasons);

    return { isReferred: reasons.length > 0, reasons };
  }

  if (isRenewal) {
    if (hasBespokeEndorsements) {
      reasons.push("This quote has bespoke endorsement(s).");
    }
    if ((renewalParentReferralReasons ?? []).length > 0) {
      reasons.push("The original policy was referred.");
    }
  }

  if (documents && documents?.length > 0) {
    reasons.push("The broker has uploaded an attachment.");
  }

  if (parsedSubmission.businessType && referableBusinessTypes.includes(parsedSubmission.businessType)) {
    reasons.push("Business type requires referral.");
  }

  if (parsedSubmission.notWorkingOnPlatforms) {
    reasons.push("Insured will undertake work on floating platforms/semisubmersible during the policy period.");
  }

  if (parsedSubmission.diving) {
    reasons.push("The insured engages in diving operations.");
  }

  if (parsedSubmission.watercraftHasCaptain) {
    reasons.push("Watercraft has dedicated captain or crew.");
  }

  if (parsedSubmission.timeOnboardWatercraft) {
    reasons.push("Employees spend more than 25% of time employed on watercraft, either on or off duty.");
  }

  if (parsedSubmission.hasJonesActClaims) {
    reasons.push("Insured has had Jones Act claims.");
  }

  if (parsedSubmission.oneOffJob) {
    reasons.push("The job is a one off contract.");
  }

  if (parsedSubmission.daysSinceInception && parsedSubmission.daysSinceInception >= 3) {
    reasons.push("Inception date is 3 or more days in the past.");
  }

  if (parsedSubmission.policyPeriodDays && parsedSubmission.policyPeriodDays > 365) {
    reasons.push("Policy period longer than 12 months.");
  }

  if (parsedSubmission.policyPeriodDays && parsedSubmission.policyPeriodDays < 365) {
    reasons.push("Policy period is less than 12 months.");
  }

  if (parsedSubmission.hasStateActPayroll && !parsedSubmission.hasOtherPayroll) {
    reasons.push("The insured has only provided State Act - On land/dock for Payroll.");
  }

  if (parsedSubmission.payroll.amount && parsedSubmission.payroll.amount > 5_000_000) {
    reasons.push("Sum of next year payroll for longshore greater than $5,000,000.");
  }

  if (parsedSubmission.hasJonesActPayroll) {
    reasons.push("The insured has provided Jones Act payroll.");
  }

  const filteredReasons = reasons.filter(onlyUnique);

  return { isReferred: filteredReasons.length > 0, reasons: filteredReasons };
};
