import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import unset from "lodash.unset";
import moment from "moment";
import { useEffect } from "react";
import { SchemaRef } from "../../@types/types";
import ajv from "../../helpers/ajv";
import { countryToWNRAD } from "./helpers";
import { parseSubmission } from "./parseSubmission";

export const useCargoEffects = (
  initialFormValues: any,
  formValues: any,
  setFormValues: any,
  schema: any,
  schemaRef: SchemaRef,
  tenant: any,
  formName: string,
): void => {
  const isSingle = schemaRef === "cargo-us-single";

  if (
    isSingle &&
    formName === "SubmissionForm" &&
    formValues?.cargo_details?.cargo_details_breakdown &&
    formValues?.cargo_details?.cargo_details_breakdown[0].cargo_type_percentage === undefined
  ) {
    const nextFormValues = cloneDeep(formValues);
    set(nextFormValues, "cargo_details.cargo_details_breakdown.0.cargo_type_percentage", 100);
    setFormValues(nextFormValues);
  }

  if (isSingle && formName === "SubmissionForm" && formValues?.additional_information?.surveyor_name === undefined) {
    const nextFormValues = cloneDeep(formValues);

    set(nextFormValues, "additional_information.surveyor_name", tenant?.single?.surveyor?.name ?? '');
    set(nextFormValues, "additional_information.surveyor_address.address_line_1", tenant?.single?.surveyor?.address?.addressLine1 ?? '');
    set(nextFormValues, "additional_information.surveyor_address.address_line_2", tenant?.single?.surveyor?.address?.addressLine2 ?? '');
    set(nextFormValues, "additional_information.surveyor_address.city", tenant?.single?.surveyor?.address?.city ?? '');
    set(nextFormValues, "additional_information.surveyor_address.zipcode", tenant?.single?.surveyor?.address?.postCode ?? '');
    set(nextFormValues, "additional_information.surveyor_address.state", tenant?.single?.surveyor?.address?.region ?? '');

    setFormValues(nextFormValues);
  }

  // default country iso value
  if (formName === "SubmissionForm" && formValues?.insured?.country_iso === undefined) {
    const nextFormValues = cloneDeep(formValues);
    set(nextFormValues, "insured.country_iso", "US - United States of America");
    setFormValues(nextFormValues);
  }

  // default trading currency value
  if (formName === "SubmissionForm" && formValues?.insured?.trading_currency === undefined) {
    const nextFormValues = cloneDeep(formValues);
    set(nextFormValues, "insured.trading_currency", "USD");
    setFormValues(nextFormValues);
  }

  // unset cargo_type that is not temperature controlled if temperature_control changes and selection becomes invalid
  useEffect(() => {
    const nextFormValues = cloneDeep(formValues);

    nextFormValues?.cargo_details?.cargo_details_breakdown?.forEach((item: any) => {
      const validate = ajv.compile(schema?.properties?.cargo_details?.properties?.cargo_details_breakdown?.items);
      const isValid = validate(item);

      if (!isValid && validate.errors?.some((error) => error.dataPath === ".cargo_type")) {
        unset(item, "cargo_type");
      }
    });

    setFormValues(nextFormValues);
  }, [
    JSON.stringify(formValues?.cargo_details?.cargo_details_breakdown?.map((cargo: any) => cargo.temperature_control)),
  ]);

  // update cargo_type excluded prop to display notification https://www.bugherd.com/projects/235305/tasks/62
  useEffect(() => {
    const nextFormValues = cloneDeep(formValues);
    const parsedSubmission = parseSubmission(formValues, schemaRef);

    nextFormValues?.cargo_details?.cargo_details_breakdown?.forEach((item: any) => {
      const parsedCargo = parsedSubmission.cargo?.cargos?.find((cargo) => item.cargo_type === cargo.type);

      item.cargo_type_excluded = parsedCargo?.isExcluded ? "Yes" : "No";
    });

    setFormValues(nextFormValues);
  }, [JSON.stringify(formValues?.cargo_details?.cargo_details_breakdown?.map((item: any) => item.cargo_type))]);

  // temp workaround to clear out dependent field (aov_transits) when parent is removed
  useEffect(() => {
    const nextFormValues = cloneDeep(formValues);

    formValues?.conveyance_limits?.conveyance_breakdown?.forEach((item: any, index: number) => {
      if (item.conveyance_type !== "Road only") {
        unset(nextFormValues, `conveyance_limits.conveyance_breakdown.${index}.aov_transits`);
      }

      if (item.conveyance_type !== "Sea") {
        unset(nextFormValues, `conveyance_limits.conveyance_breakdown.${index}.container_load_type`);
      }

      if (!item.aov_transits || item.aov_transits === "No") {
        unset(nextFormValues, `conveyance_limits.conveyance_breakdown.${index}.own_vehicle_transit_limit`);
        unset(nextFormValues, `conveyance_limits.conveyance_breakdown.${index}.own_vehicle_percentage_of_turnover`);
      }

      const containsAovTransits = nextFormValues?.conveyance_limits?.conveyance_breakdown?.some(
        (item: any) => item.aov_transits === "Yes",
      );
      if (!containsAovTransits) {
        unset(nextFormValues, `samples`);
      }
    });

    setFormValues(nextFormValues);
  }, [JSON.stringify(formValues?.conveyance_limits?.conveyance_breakdown)]);

  // remap "District of Columbia" to "Washington" as DC is not on known states list
  useEffect(() => {
    if (formValues?.insured?.insured_address?.state === "District of Columbia") {
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "insured.insured_address.state", "Washington");
      setFormValues(nextFormValues);
    }
  }, [formValues?.insured?.insured_address?.state]);

  // set expiry date to inception date + 12 months - 1 day
  useEffect(() => {
    if (formValues?.contract?.inception_date) {
      const newExpiryDate = moment(formValues?.contract?.inception_date)
        .add(12, "months")
        .subtract(1, "day")
        .toISOString();
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "contract.expiry_date", newExpiryDate);
      setFormValues(nextFormValues);
    }
  }, [formValues?.contract?.inception_date]);

  // default expiry date back to 12 months if contract_period is No
  useEffect(() => {
    if (formValues?.contract?.contract_period === "No") {
      const newExpiryDate = moment(formValues?.contract?.inception_date)
        .add(12, "months")
        .subtract(1, "day")
        .toISOString();
      const nextFormValues = cloneDeep(formValues);

      if (formValues?.contract?.inception_date !== undefined) {
        set(nextFormValues, "contract.expiry_date", newExpiryDate);
        setFormValues(nextFormValues);
      }
    }
  }, [formValues?.contract?.contract_period]);

  // unset iception_date if conveyance_type changes to something other than "Sea" and inception_date before today
  // becomes invalid
  useEffect(() => {
    if (
      formValues?.conveyance_limits?.conveyance_type !== "Sea" &&
      formValues?.contract?.inception_date !== undefined
    ) {
      const todayMoment = moment().startOf("day");
      const inceptionDate = moment(formValues?.contract?.inception_date).startOf("day");
      const nextFormValues = cloneDeep(formValues);

      if (!inceptionDate.isSameOrAfter(todayMoment)) {
        unset(nextFormValues, "contract.inception_date");
        setFormValues(nextFormValues);
      }
    }

    if (isSingle) {
      let nextFormValuesChanged = false;
      const nextFormValues = cloneDeep(formValues);

      if (formValues?.conveyance_limits?.conveyance_type !== "Road only") {
        nextFormValuesChanged = true;
        unset(nextFormValues, `conveyance_limits.aov_transits`);
      }

      if (formValues?.conveyance_limits?.conveyance_type !== "Air only") {
        nextFormValuesChanged = true;
        unset(nextFormValues, `conveyance_limits.flight_airline_number`);
      }

      if (formValues?.conveyance_limits?.conveyance_type !== "Sea") {
        nextFormValuesChanged = true;
        unset(nextFormValues, `conveyance_limits.container_load_type`);
        unset(nextFormValues, `conveyance_limits.vessel_name`);
        unset(nextFormValues, `conveyance_limits.imo_number`);
      }

      if (nextFormValuesChanged) {
        setFormValues(nextFormValues);
      }
    }
  }, [formValues?.conveyance_limits?.conveyance_type]);

  // set loss_history_breakdown default years
  useEffect(() => {
    if (formValues?.insured?.loss_history_five_years === "Yes" && formValues?.insured?.trading_years !== undefined) {
      const nextFormValues = cloneDeep(formValues);
      const year = new Date().getUTCFullYear();

      const lossHistoryMaximum = schema.properties.insured.properties.loss_history_breakdown.maximum;
      const tradingYears = formValues.insured.trading_years;

      Array.from(Array(tradingYears).keys())?.forEach((item, index) => {
        if (index < lossHistoryMaximum) {
          set(nextFormValues, `insured.loss_history_breakdown.[${index}].loss_history_year`, year - index);
        }
      });

      set(
        nextFormValues,
        "insured.loss_history_breakdown.length",
        tradingYears < lossHistoryMaximum ? tradingYears : lossHistoryMaximum,
      );
      setFormValues(nextFormValues);
    }
  }, [formValues?.insured?.loss_history_five_years, formValues?.insured?.trading_years]);

  // unset loss_history_breakdown
  useEffect(() => {
    if (formValues?.insured?.loss_history_five_years === "No") {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, `insured.loss_history_breakdown`);
      setFormValues(nextFormValues);
    }
  }, [formValues?.insured?.loss_history_five_years]);

  // unset storages
  useEffect(() => {
    if (formValues?.storage?.storage_cover === "No") {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, `storage.storages`);
      setFormValues(nextFormValues);
    }
  }, [formValues?.storage?.storage_cover]);

  // unset exhibitions
  useEffect(() => {
    if (formValues?.exhibitions?.exhibitions_cover === "No") {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, `exhibitions.exhibitions`);
      setFormValues(nextFormValues);
    }
  }, [formValues?.exhibitions?.exhibitions_cover]);

  // unset basis_of_valuation_percentage
  useEffect(() => {
    if (formValues?.cargo_details?.basis_of_valuation !== "CIF+") {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, `cargo_details.basis_of_valuation_percentage`);
      setFormValues(nextFormValues);
    }
  }, [formValues?.cargo_details?.basis_of_valuation]);

  // reduce only - limit_per_conveyance
  useEffect(() => {
    const isReduceOnly = schema?.properties?.conveyance_limits?.properties?.conveyance_breakdown?.items?.properties?.limit_per_conveyance?.['ui:component:reduce-only'];
    if (isReduceOnly) {
      formValues?.conveyance_limits?.conveyance_breakdown?.forEach((item: any, index: number) => {
        const newValue = item?.limit_per_conveyance?.amount;
        const initialValue =
          initialFormValues?.conveyance_limits?.conveyance_breakdown?.[index]?.limit_per_conveyance?.amount;

        if (newValue !== undefined && initialValue !== undefined && newValue > initialValue) {
          const clonedFormValues = cloneDeep(formValues);
          set(
            clonedFormValues,
            `conveyance_limits.conveyance_breakdown.[${index}].limit_per_conveyance.amount`,
            initialValue,
          );
          setFormValues(clonedFormValues);
        }
      });
    }
  }, [formValues?.conveyance_limits?.conveyance_breakdown]);

  // reduce only - insured_value - single
  useEffect(() => {
    const isReduceOnly = schema?.properties?.conveyance_limits?.properties?.insured_value?.['ui:component:reduce-only'];
    if (isReduceOnly) {
      const newValue = formValues?.conveyance_limits?.insured_value?.amount;
      const initialValue = initialFormValues?.conveyance_limits?.insured_value?.amount;

      if (newValue !== undefined && initialValue !== undefined && newValue > initialValue) {
        const clonedFormValues = cloneDeep(formValues);
        set(clonedFormValues, `conveyance_limits.insured_value.amount`, initialValue);
        setFormValues(clonedFormValues);
      }
    }
  }, [formValues?.conveyance_limits?.insured_value]);

  // WNRAD logic - hide exhibitions section if country is designated as WNRAD country / https://www.bugherd.com/projects/235305/tasks/158
  useEffect(() => {
    if (formValues?.shipment_details_single?.voyage_to_country) {
      const nextFormValues = cloneDeep(formValues);
      const isWNRAD = countryToWNRAD(formValues.shipment_details_single.voyage_to_country);

      set(nextFormValues, `shipment_details_single.voyage_to_country_wnrad`, isWNRAD === true ? "Yes" : "No");

      if (isWNRAD === true) {
        unset(nextFormValues, "exhibitions");
      }

      setFormValues(nextFormValues);
    }
  }, [formValues?.shipment_details_single?.voyage_to_country]);

  // TODO: unset formValues.shipment_details_annual.shipments[].geographical_area_by_country_region
};
