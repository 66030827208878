import React, { FC } from "react";
import { Single_own_vehicle_copy } from "./4_0_Single_own_vehicle_copy";

export const Single_own_vehicle: FC = () => {
  return (
    <section aria-labelledby="own_vehicle">
      <h1 id="own_vehicle">CLAUSES</h1>
      <h3>Applicable only if stated in Policy Schedule</h3>

      <Single_own_vehicle_copy />
    </section>
  );
};
