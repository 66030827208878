import React, { FC } from "react";
import { PolicyDocumentProps } from "../../types";

export const Single_motor_vehicle_conditions: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  return (
    <section aria-labelledby="single_motor_vehicle_conditions">
      <h1>MOTOR VEHICLE CONDITIONS</h1>

      <p>Excluding vehicles shipped on deck other than in containers.</p>
      <p>
        This Insurance will be subject to the following Institute Clauses along with any other policy conditions:
        Institute Cargo Clauses (A) or (Air) or (C), Institute War Clauses (Cargo) or (Air Cargo), Institute Strikes
        Clauses (Cargo) or (Air Cargo), Institute Replacement Clause, Institute Classification Clause. All as
        appropriate to the mode of transit and as stated on the certificate of marine cargo insurance.
      </p>

      <p>In addition the clauses below are deemed to apply where applicable:</p>

      <p>
        <strong>DURATION CLAUSE</strong>
        <br />
        This insurance attaches from the time the vehicle is handed over to the carrier or agent at the place named for
        the commencement of the transit (or Airport or Port or Border for a “Port Only” restricted country) including
        where required a period not exceeding 15 days in compound whilst awaiting loading, continues during the ordinary
        course of transit and terminates either:
        <br />
        <ol type="I" style={{ marginLeft: "0" }}>
          <li>
            <p style={{ marginLeft: "2em" }}>
              when the vehicle is handed over to the assured or his agent at the destination named, or
            </p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>
              on delivery to a place of storage other than in the ordinary course of transit, or
            </p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>
              on expiry of 10 days from the time the vehicle becomes available for collection by the assured or his
              agent or
            </p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>at Airport or Port or Border for a “Port Only” restricted country</p>
          </li>
        </ol>
        ...whichever shall first occur
      </p>

      <p>
        <strong>VALUATION &amp; AVERAGE CLAUSE</strong>
        <br />
        The vehicle should be insured for it’s full market value at destination including freight if required. In the
        event of the sum insured being less than sound market value of the vehicle at the time and place of loss, the
        assured shall only be entitled to recover hereunder such proportion of the loss as the sum insured bears to the
        sound market value of the vehicle.
      </p>

      <p>
        <strong>DEPRECIATION</strong>
        <br />
        Underwriters’ liability is restricted to the reasonable cost of repair and no claim is to attach hereto for
        depreciation consequent thereon. In no case shall liability hereunder for such repairs exceed the sum insured in
        respect of the damaged vehicle.
      </p>

      <p>
        <strong>RESPRAYING</strong>
        <br />
        Respraying of vehicles is limited to damaged parts only.
      </p>

      <p>
        <strong>EXCESS / DEDUCTIBLE</strong>
        <br />
        Deductible as stipulated each &amp; every claim.
      </p>

      <p>
        <strong>EXCLUSIONS</strong>
      </p>

      <p>
        <strong>VEHICLES OVER 5 YEARS OF AGE OR VEHICLES WITH NO CERTIFICATE OF CONDITION *</strong>
        <br />
        Excluding the risks of scratching, denting, chipping, bruising, marring, staining, rust, oxidisation &amp;
        discolouration.
        <br />
        <strong>* Certificate of Condition is defined as:</strong> A document stating the condition of the vehicle at
        the time the vehicle enters the custody of the carrier, freight forwarder or steamship company noting vehicle
        condition and defects. The certificate of condition must be agreed by the carrier, freight forwarder or
        steamship company and the owner of the vehicle and also signed at the same time.
      </p>

      <p>
        <strong>CLASSIC CARS</strong>
        <br />
        Excluding any motor vehicles over twelve (12) years of age without prior approval from Underwriters.
      </p>

      <p>
        <strong>MECHANICAL, ELECTRICAL DERANGEMENT</strong>
        <br />
        Excluding the risks of mechanical, electrical or electronic breakdown &amp;/or derangement.
      </p>

      <p>
        <strong>CLIMATIC CONDITIONS</strong>
        <br />
        Excluding loss or damage arising from climatic or atmospheric conditions or extremes of temperature or freezing
        of coolant.
      </p>

      <p>
        <strong>RUST, OXIDISATION &amp; DISCOLOURATION</strong>
        <br />
        Excluding the risks of rust, oxidisation and discolouration unless caused by a peril insured under the terms of
        the Institute Cargo Clauses (C).
      </p>

      <p>
        <strong>ACCESSORIES</strong>
        <br />
        Excluding loss or damage to accessories &amp;\or portable items unless declared prior to shipment.
      </p>

      <p>
        <strong>AUDIO AND ELECTRICAL EQUIPMENT</strong>
        <br />
        Excluding theft or pilferage of audio and electrical equipment including but not limited to CD and DVD players,
        radios, speakers, satellite navigational devices and similar items unless stolen with the vehicle.
      </p>

      <p>
        <strong>OWN POWER</strong>
        <br />
        Excluding loss or damage whilst the insured vehicle is being driven under it’s own power or whilst being towed,
        except whilst being loaded or unloaded from the carrying conveyance including containers.
      </p>

      <p>
        <strong>THIRD PARTY LIABILITY</strong>
        <br />
        Excluding damages, injury or liability to any third party absolutely.
      </p>

      <p>
        <strong>MOTOR INSURANCE</strong>
        <br />
        Excluding any claim recoverable under a policy of Motor Insurance.
      </p>

      <p>
        <strong>CONFISCATION</strong>
        <br />
        Excluding the risks of confiscation &amp; seizure.
      </p>
    </section>
  );
};
