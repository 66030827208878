import React, { FC } from "react";
import { Row } from "../../../../documents/components/Row";
import { BrokerSecurity, PolicyDocumentProps } from "../../types";
import Chaucer_sign_underwriters from "../../../../assets/Chaucer_sign_underwriters.png";

export const Annual_Intro: FC<PolicyDocumentProps> = ({ contract }) => {
  const isSecurity1 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurity2 = contract.placingBroker.security === BrokerSecurity.security2;

  return (
    <section aria-labelledby="introduction">
      <h1>MARINE CARGO INSURANCE POLICY</h1>

      <table className="styled" style={{ marginBottom: "14px" }}>
        <colgroup>
          <col width="25%" />
        </colgroup>
        <tbody>
          <Row title="Introduction" id="introduction">
            {isSecurity1 && (
              <p>
                Chaucer Syndicates Limited (Lloyd’s syndicate 1084) referred to as the Underwriters and the Assured
                agree that this Policy comprising of this Policy document all Schedules including any endorsements and
                Proposal shall be read as one contract (the Policy).
              </p>
            )}

            {isSecurity2 && (
              <p>
                Chaucer Insurance Company DAC (CIC DAC referred to as the Underwriters) and the Assured agree that this
                Policy comprising of this Policy document all Schedules including any endorsements and Proposal shall be
                read as one contract (the Policy).
              </p>
            )}

            <p>
              For the purpose of this clause “Proposal” shall mean all information provided and all statements or
              declarations made to the Underwriters by or on behalf of the Assured the Underwriters will provide the
              insurance described in this Policy, subject to the terms exclusions and conditions, for the Period of
              Insurance shown in the Schedule and any subsequent period for which the Assured shall pay and the
              Underwriters shall agree to accept premium
            </p>

            <p style={{ marginTop: "208px" }}>
              Signed for and on behalf of <b>Underwriters</b>
            </p>

            <img src={Chaucer_sign_underwriters} style={{ maxWidth: "208px" }} alt="sign" />

            <p>Graham R. Hooper</p>

            <p>Class Underwriter - Cargo & Logistics,</p>

            {isSecurity1 && <p>Chaucer Syndicates Limited on behalf of Syndicate 1084</p>}

            {isSecurity2 && <p>Chaucer Insurance Company DAC</p>}
          </Row>
        </tbody>
      </table>
    </section>
  );
};
