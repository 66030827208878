
import { FC } from "react";
import { PolicyDocumentProps } from "../types";
import { AnnualPolicyDocument } from "./annual/PolicyDocument";
import { SinglePolicyDocument } from "./single/PolicyDocument";

export const PolicyDocument: FC<PolicyDocumentProps> = ({
  contract,
  quote,
  clauses,
  customClauses,
  parsedSubmission,
  regions,
  schemaRef,
}) => {
  if (schemaRef === "cargo-us-single") {
    return SinglePolicyDocument({ contract, quote, clauses, customClauses, parsedSubmission, regions, schemaRef });
  } else {
    return AnnualPolicyDocument({ contract, quote, clauses, customClauses, parsedSubmission, regions, schemaRef });
  }
};
