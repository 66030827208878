import React, { FC } from "react";
import { PolicyDocumentProps } from "../../types";

export const Single_claims: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  return (
    <section aria-labelledby="single_claims">
      <p>
        <strong>CLAIMS HANDLING AND NOTIFICATION</strong>
        <br />
        In the event of an incident that may give rise to a claim under this Policy where such claim would be, or likely
        to be in excess of the applicable deductible the Assured and/or the brokers must give immediate notification to
        the following: -
      </p>
      <p>
        LONHAM
        <br />
        The Maltings
        <br />
        Princes Street
        <br />
        Ipswich
        <br />
        Suffolk
        <br />
        IP1 1SB
        <br />
        United Kingdom
        <br />
        <strong>T +44 01473 216 116</strong>
        <br />
        <a href="mailto:claims@lonham.co.uk">claims@lonham.co.uk.</a>
      </p>

      <p>
        <strong>DOCUMENTATION OF CLAIMS</strong>
        <br />
        To enable claims to be dealt with promptly and to avoid prejudicing your claim under the Policy, you are
        required to submit all available supporting documents to Underwriters or our agents as soon as possible,
        including when applicable:
      </p>

      <p>
        1. Transit Documents, including Original Bills of Lading, Charter Parties, Air Waybills or Contracts of Carriage
        <br />
        2. The Original Marine Cargo Insurance Single Shipment Policy or Certificate of Insurance if applicable
        <br />
        3. Original or copy invoices, shipping specification together with weight notes, indicating quantity and value
        <br />
        4. Confirmation of contract of carriage and/or trading conditions
        <br />
        5. Packing list (if applicable) <br />
        6. All correspondence exchanged with the carriers and other parties regarding their liability for the loss or
        damage. <br />
        7. Claused delivery note <br />
        8. Statement of events
        <br />
        9. Survey report or other documentary evidence to the show extent of loss or damage
      </p>
      <p>
        <strong>FRAUDULENT CLAIMS</strong>
        <br />
        If any claim is fraudulent or false or intentionally exaggerated (whether ultimately material or not) in any
        respect, Underwriters may refuse to pay the whole or part of the claim to the extent permitted by law, and also
        may be entitled to void or cancel the Policy.
      </p>
    </section>
  );
};
