import moment from "moment";
import { SchemaRef } from "../../../@types/types";
import { CargoRaterOutput } from "../types";

export const calculateProRataFactor = (
  schemaRef: SchemaRef,
  cancellationDateIso?: string,
  inceptionDateIso?: string,
  expiryDateIso?: string,
): number => {
  if (schemaRef === "cargo-us-single") {
    return 1;
  }

  if (cancellationDateIso && inceptionDateIso && expiryDateIso) {
    const inceptionDay = moment.utc(inceptionDateIso).startOf("day");
    const expiryDay = moment.utc(expiryDateIso).startOf("day");
    const cancellationDay = moment.utc(cancellationDateIso).startOf("day");
    const periodOfCover = expiryDay.diff(inceptionDay, "days");
    const daysRemaining = expiryDay.diff(cancellationDay, "days");
    return daysRemaining / periodOfCover;
  }

  return 1;
};

// annual rater - https://drive.google.com/file/d/13mx2AJoAgfPsJrQRW0P_FsgDgQ1UFxy5/view
// single rater - https://docs.google.com/spreadsheets/d/1CkzAlcQDDKinmFkZ1ADe9hYBMH4geLxV/edit#gid=1289723442
// https://trello.com/c/U67TxoOb/109-cancellation
export const calculateCancellationRefundPremium = (
  schemaRef: SchemaRef,
  proRataFactor: number,
  rates: CargoRaterOutput,
): number => {
  const basePremium = rates?.basePremium.grossPremium ?? 0;
  const exhibitionsPremium = rates?.exhibitionsPremium.grossPremium ?? 0;
  const samplesPremium = rates?.samplesPremium.grossPremium ?? 0;
  const storagePremium = rates?.storagesPremium?.grossPremium ?? rates?.storagePremium?.grossPremium ?? 0;

  const premium = basePremium + storagePremium + exhibitionsPremium + samplesPremium;

  return premium * proRataFactor;
};
