import { get } from "lodash";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import React from "react";
import resolveSchema from "../../../helpers/resolveSchema";
import Button from "../Button";
import Card from "../Card";
import H4 from "../H4";

const SectionRepeaterWithMatrix = ({
  className,
  formValues,
  id,
  item,
  isAddButtonEnabled,
  isReadOnly,
  isRemoveButtonEnabled,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  value,
  validationErrors,
  showQuestionKey,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [{}];

  const schemaItem = item.items.type === "object" ? item.items : { properties: { [id]: item.items } };

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, {}]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex) => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  const isButtonEnabled = (len, readOnly, enabled) => {
    if (len > 1) {
      if (enabled === true) {
        return true;
      }
      if (readOnly === true) {
        return false;
      }
      if (!readOnly && enabled === undefined) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  return (
    <div className={className}>
      <H4 className="my-8">{item.title}</H4>

      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(schemaItem);

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex justify-between items-center p-4">
                <div className="font-medium">
                  {item.items.title} #{index + 1}
                </div>

                {isButtonEnabled(initialItems.length, isReadOnly, isRemoveButtonEnabled) && (
                  <Button className="h-10 px-3 -m-2" kind="danger-secondary" onClick={() => handleRemove(index)}>
                    Delete
                  </Button>
                )}
              </Card.Header>
            }
          >
            <div className="p-4 flex">
              {mapChildren({
                className: "ml-4 mr-4",
                formValues,
                onChange,
                parentKey: key,
                parentSchema: clonedSchema,
                setFormValues,
                validationErrors,
                showQuestionKey,
                // useParentKey: true,
              })}
            </div>
          </Card>
        );
      })}
      {isButtonEnabled(999, isReadOnly, isAddButtonEnabled) && <Button onClick={handleAdd}>Add new</Button>}
    </div>
  );
};

export default SectionRepeaterWithMatrix;
