import React, { FC } from "react";
import { Row } from "../../../../documents/components/Row";
import { asCurrency, asDate } from "../../helpers";
import { parseSubmission } from "../../parseSubmission";
import { BrokerSecurity, PolicyDocumentProps } from "../../types";
import { v4 as uuid } from "uuid";

export const Single_schedule: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  const { insured, additionalInformation, ...parsedSubmission } = parseSubmission(quote.submission, "cargo-us-single");

  const isSecurityCSL1084 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurityCIC = contract.placingBroker.security === BrokerSecurity.security2;

  return (
    <section aria-labelledby="policySchedule">
      <h1 id="policySchedule">Policy schedule</h1>

      <table className="styled">
        <colgroup>
          <col width="25%" />
        </colgroup>
        <tbody>
          <Row title="POLICY NUMBER:" id="policyNumber">
            {contract.ref}
            <br />
            <br />
            We, the Underwriters, hereby agree in consideration of the payment to the Underwriters by or on behalf of
            the Assured of the premium specified in the Schedule, to insure against loss or damage liability or expense
            in the manner hereinafter provided.
          </Row>

          <Row title="ASSURED:" id="assured">
            {insured.name} {insured.subsidiaryCompaniesFormatted && ` and/or ${insured.subsidiaryCompaniesFormatted}`}{" "}
            {insured.associatedCompaniesFormatted && ` and/or ${insured.associatedCompaniesFormatted}`}
          </Row>

          <Row title="ADDRESS:" id="address">
            {insured.formattedAddress}
          </Row>

          {additionalInformation?.isLossPayeeDifferentToInsured && (
            <>
              <Row title="LOSS PAYABLE TO:" id="lossPayableTo">
                {additionalInformation?.lossPayeeDetailsName ?? ""}
              </Row>

              <Row title="LOSS PAYABLE ADDRESS:" id="lossPayableAddress">
                {additionalInformation?.lossPayeeDetailsFormattedAddress}
              </Row>
            </>
          )}

          <Row title="SUM INSURED:" id="sumInsured">
            {parsedSubmission.conveyance?.conveyances?.[0]?.insuredValue
              ? asCurrency(parsedSubmission.conveyance.conveyances?.[0].insuredValue?.amount)
              : "-"}
          </Row>

          <Row title="TRANSIT COMMENCES ON OR ABOUT:" id="transitCommencesOnOrAbout">
            {parsedSubmission.shipment.dateOfTransitFormatted}
            <br />
            <br />
            {parsedSubmission.shipment.isDateOfTransitBeforeToday && (
              <p>Warranted no known or reported losses as at {quote.boundAt ? asDate(quote.boundAt) : "-"}</p>
            )}
          </Row>

          <Row title="VOYAGES:" id="voyages">
            From {parsedSubmission.shipment.cityFrom} {parsedSubmission.shipment.countryFrom} to{" "}
            {parsedSubmission.shipment.cityTo} {parsedSubmission.shipment.countryTo}
            <br />
            <br />
            {parsedSubmission.shipment.WNRADTo === true && (
              <p>
                Risk ceasing absolutely upon discharge from vessel or aircraft at the port or airport of discharge or up
                to border only if by land conveyance
              </p>
            )}
            {parsedSubmission.exhibition.hasExhibitions && (
              <p>
                Including whilst on exhibition in {parsedSubmission.shipment.cityTo},{" "}
                {parsedSubmission.shipment.countryTo} including whilst there for a maximum period of
                {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "< 7 days" && ` 7 days`}
                {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "8-14 days" && ` 14 days`}
                {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "15-30 days" && ` 30 days`}
              </p>
            )}
            {parsedSubmission.exhibition.exhibitions?.[0]?.returnBack && (
              <p>including return transit after exhibition.</p>
            )}
          </Row>

          <Row title="VESSEL/CONVEYANCE:" id="vesselConveyance">
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
              !parsedSubmission.conveyance.conveyances?.[0].vesselName && (
                <>
                  First available vessel and related land conveyances as applicable
                  <br />
                  <br />
                </>
              )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
              parsedSubmission.conveyance.conveyances?.[0].vesselName && (
                <>
                  {parsedSubmission.conveyance.conveyances[0].vesselName} and related land conveyances as applicable
                  <br />
                  <br />
                </>
              )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Air only" &&
              !parsedSubmission.conveyance.conveyances?.[0].flightAirlineNumber && (
                <>
                  First available flight and related land conveyances as applicable
                  <br />
                  <br />
                </>
              )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Air only" &&
              parsedSubmission.conveyance.conveyances?.[0].flightAirlineNumber && (
                <>
                  {parsedSubmission.conveyance.conveyances?.[0].flightAirlineNumber} and related land conveyances as
                  applicable
                  <br />
                  <br />
                </>
              )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" && (
              <>
                By Road conveyances only
                <br />
                <br />
              </>
            )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Rail only" && (
              <>
                By Rail conveyances only
                <br />
                <br />
              </>
            )}
            {parsedSubmission.conveyance?.conveyances?.[0]?.aovTransits && (
              <>
                Including transit by Vehicles Owned, Hired or Leased by the Assured.
                <br />
                <br />
              </>
            )}
            Excluding sendings by Post or Parcel Carrier.
          </Row>

          <Row title="INTEREST INSURED:" id="interestInsured">
            {parsedSubmission.cargo.cargos?.[0]?.descriptionOfInterestForCert}
            <br />
            <br />
            <p>
              Warranted that the Interests above comply with the description of Interests for{" "}
              {parsedSubmission.cargo.cargos?.[0]?.type} or no cover deemed to be in force.
              <br />
              {parsedSubmission.cargo.cargos?.[0]?.group !== 5 &&
                parsedSubmission.cargo.cargos?.[0]?.group !== 6 &&
                parsedSubmission.cargo.cargos?.[0]?.group !== 7 && (
                  <>
                    {parsedSubmission.cargo.condition === "New" && (
                      <>
                        Warranted that the Interest insured is New or no cover deemed to be in force.
                        <br />
                      </>
                    )}
                    {parsedSubmission.cargo.condition === "Fully reconditioned to a new standard" && (
                      <>
                        Warranted that the Interest insured is fully refurbished / reconditioned to a new standard.
                        Excluding Rust, Oxidisation, Discoloration, Wetting, Staining, Scratching, Bruising, Chipping,
                        Denting, Marring and the Cost of Repainting as applicable unless caused by an Insured peril.
                        Subject to the Secondhand Replacement Clause.
                      </>
                    )}
                  </>
                )}
            </p>
            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
              parsedSubmission.conveyance?.conveyances?.[0]?.containerType === "FCL" && (
                <p>Suitably packed for transit in Full Container Load.</p>
              )}

            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
              parsedSubmission.conveyance?.conveyances?.[0]?.containerType === "LCL" && (
                <p>Suitably packed for transit in Less than Container Load.</p>
              )}

            {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
              parsedSubmission.conveyance?.conveyances?.[0]?.containerType === "Breakbulk" && (
                <p>Warranted that the cargo is suitably packed or stowed for transit</p>
              )}

            {(parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" ||
              parsedSubmission.conveyance?.conveyances?.[0]?.type === "Rail only" ||
              parsedSubmission.conveyance?.conveyances?.[0]?.type === "Air only") &&
              !parsedSubmission.cargo.cargos?.[0]?.isNotSuitablyPacked && (
                <p>Warranted that cargo is suitably packed or protected for transit.</p>
              )}

            {(parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" ||
              parsedSubmission.conveyance?.conveyances?.[0]?.type === "Rail only" ||
              parsedSubmission.conveyance?.conveyances?.[0]?.type === "Air only") &&
              parsedSubmission.cargo.cargos?.[0]?.isNotSuitablyPacked && (
                <p>
                  Warranted that the cargo is suitably stowed for transit. Unpacked or Unprotected items: Excluding
                  Rust, Oxidation, Discoloration, Wetting, Staining, Scratching, Bruising, Chipping, Denting, Marring
                  and the Cost of Repainting as applicable.
                </p>
              )}
          </Row>

          {parsedSubmission.cargo.cargos?.[0]?.marksNumbers && (
            <Row title="MARKS & NUMBERS:" id="marksAndNumbers">
              {parsedSubmission.cargo.cargos?.[0]?.marksNumbers}
            </Row>
          )}

          {additionalInformation?.assuredOwnReference && (
            <Row title="ASSURED REFERENCE:" id="assuredReference">
              {additionalInformation?.assuredOwnReference}
            </Row>
          )}

          <Row title="CONDITIONS OF INSURANCE:" id="conditionsOfInsurance">
            Standard Marine Cargo Insurance Conditions as attached in addition to any specific clauses, exclusions or
            warranties above, primarily:
            <br />
            <br />
            {!parsedSubmission.cargo.iccClause && parsedSubmission.cargo.cargos?.[0]?.tempControl === "No" && (
              <p>
                Institute Cargo Clauses (A) CL382 dated 1.1.2009 and/or Institute Cargo Clauses (Air) CL 387 dated
                1.1.2009 as applicable.{" "}
              </p>
            )}
            {!parsedSubmission.cargo.iccClause &&
              parsedSubmission.cargo.cargos?.[0]?.tempControl === "Frozen below minus 15 Centigrade" && (
                <p>Institute Frozen Foods Clauses (A) CL236 dated 1.1.86 – 24 Breakdown as applicable.</p>
              )}
            {parsedSubmission.cargo.iccClause && parsedSubmission.cargo.cargos?.[0]?.tempControl === "No" && (
              <p>Institute Cargo Clauses (C) CL384 dated 1.1.2009 as applicable.</p>
            )}
            {parsedSubmission.cargo.iccClause &&
              parsedSubmission.cargo.cargos?.[0]?.tempControl === "Frozen below minus 15 Centigrade" && (
                <p>Institute Cargo Clauses (C) CL384 dated 1.1.2009 as applicable.</p>
              )}
            {parsedSubmission.cargo.condition === "Used" && parsedSubmission.cargo.cargos?.[0]?.group !== 6 && (
              <p>
                Unless otherwise agreed with Underwriters prior to the commencement of transit, the Institute Cargo
                Clauses (A) or the Institute Cargo Clauses (Air) are deleted and replaced by the Institute Cargo Clauses
                (C) CL 384 dated 1.1.2009 extended to include the risks of theft or non-delivery of an entire
                consignment or full container, trailer or vehicle load. Subject also to the Secondhand Replacement
                Clause.{" "}
              </p>
            )}
            {parsedSubmission.shipment.countryFromIsWarAndStrikesIncluded &&
              parsedSubmission.shipment.countryToIsWarAndStrikesIncluded && (
                <p>
                  Institute War Clauses (Cargo) CL 385 dated 1.1.2009 and/or Institute War Clauses (Air Cargo) CL 388
                  dated 1.1.2009 as applicable.
                  <br />
                  Institute Strikes Clauses (Cargo) CL 386 dated 1.1.2009 and/or Institute Strikes Clauses (Air Cargo)
                  CL 389 dated 1.1.2009 as applicable.
                </p>
              )}
            {parsedSubmission.cargo.cargos?.[0]?.tempControl === "Frozen below minus 15 Centigrade" && (
              <p>Institute Strikes Clauses (Frozen Foods) CL286 dated 1.1.86</p>
            )}
            {parsedSubmission.exhibition.hasExhibitions && <p>Exhibition clause as below applies.</p>}
            <p>
              Subject to a{" "}
              {parsedSubmission.cargo.cargos?.[0]?.deductible?.amount
                ? asCurrency(parsedSubmission.cargo.cargos?.[0]?.deductible?.amount)
                : "-"}{" "}
              Deductible each and every claim.{" "}
            </p>
            <p>
              DRIED FOODSTUFFS IN BAGS & SACKS
              <br />
              Excluding Heating and Sweating
              <br />
              Excluding Infestation arising from weevil, grub or web
              <br />
              Excluding Rejection Risks
              <br />
              Excluding any Natural loss in Weight
              <br />
              Warranted shipped in ventilated container(s)
            </p>
            <p>
              LOSS OF DATA
              <br />
              In respect of electronic or computerised items: Including loss or damage for installed or other included
              software such as operating systems or office suites but excluding claims for loss of data or any other
              proprietary information.
            </p>
            <p>
              STONE, MARBLE, SLATE, GRANITE AND SIMILAR SLABS
              <br />
              Warranted slabs are individually separated and suitably packed or protected for transit in crates or
              cases. Excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying
              conveyance or dropping whilst loading or unloading.
            </p>
            <p>
              YACHTS AND OTHER BOATS
              <br />
              Warranted new and transported in fully enclosed containers or trucks. Excluding loss or damage to
              accessories or portable items unless declared prior to shipment and they are suitably packed or protected
              for transit. Excluding theft or pilferage of audio, electronic communication or display equipment,
              entertainment or computer or navigational equipment and similar items unless stolen with the craft.
              Warranted that unpacked craft are suitably secured to a trailer or cradle.
            </p>
            {parsedSubmission.cargo.cargos?.[0]?.group === 6 && (
              <p>
                MOTOR VEHICLES – subject to the Motor Vehicle Conditions as attached
                <br />
                Warranted shipped underdeck.
              </p>
            )}
            {parsedSubmission.cargo.cargos?.[0]?.group === 5 && (
              <p>
                HOUSEHOLD GOOD AND PERSONAL EFFECTS – Subject to the Household Goods and Personal Effects Conditions as
                attached
              </p>
            )}
            <p>
              ITEMS IN NON-WATERPROOF CRATES OR PACKAGING
              <br />
              Excluding Rust, Oxidation, Discoloration, Wetting, Staining and the Cost of Repainting as applicable.
            </p>
            {parsedSubmission.cargo.cargos?.[0]?.group !== 7 && (
              <p>
                TEMPERATURE VARIATION
                <br />
                Excluding any loss, damage or change in the nature of the subject matter insured caused by variation in
                temperature unless caused by a peril insured under the terms of the Institute Cargo Clauses (B) CL383
                dated 1.1.2009.
              </p>
            )}
            {parsedSubmission.conveyance.conveyances?.[0]?.aovTransits && (
              <p> Subject to the Own Vehicle Security Clause as below. </p>
            )}
          </Row>
          <Row title="BASIS OF VALUATION:" id="basisOfValuation">
            It is agreed that the basis of valuation for the purpose of this single shipment policy shall be the value
            declared for insurance, but in no case shall the valuation exceed Cost Insurance & Freight (CIF) plus 20%
            unless prior written consent of the Insurer is given. In the event of declaration after loss or arrival, the
            basis of valuation will be CIF plus 10% only. Also to pay increased value by reason of Duty, Excise,
            Surcharge and/or Landing and similar charges, if incurred in anticipation of arrival and provided declared
            to and accepted by Underwriters. The Assured agrees to take all reasonable steps to obtain a refund of such
            charges and return the net amount to Underwriters.
            <br />
            <br />
            {parsedSubmission.exhibition.hasExhibitions && (
              <p>
                In respect of goods in transit to, from or whilst at Exhibitions if insured the basis of valuation will
                be Market value at the time of loss less depreciation for wear & tear if applicable.
              </p>
            )}
            <p>
              In respect of non Import or non Export transits, Purchases and Sales including goods sold FOB, C&F or
              similar the basis of valuation will be: Invoice Cost price to buyer plus freight and packing costs if
              incurred separately.
              <br />
              Other Transits: Market value at the time of loss.
            </p>
          </Row>
          {additionalInformation?.letterOfCreditWording && (
            <Row title="LETTER OF CREDIT:" id="letterOfCredit">
              <strong>
                In order to comply with Letter of Credit requirements this certificate includes specifically but not by
                way of limitation or extension:{" "}
              </strong>
              <br />
              <br />
              {additionalInformation?.letterOfCreditWording
                .split("\n")
                .filter(Boolean)
                .map((str) => (
                  <p key={uuid()}>{str}</p>
                ))}
            </Row>
          )}
          <Row title="IMPORTANT NOTICE:" id="importantNotice">
            PROCEDURE IN THE EVENT OF LOSS OR DAMAGE FOR WHICH UNDERWRITERS MAY BE LIABLE
            <br />
            In the event of loss please contact the Surveyor or Claims Agents office named below and follow the
            instructions provided.
            <br />
            <br />
            <p>
              It is the duty of the Assured and their Agents, in all cases, to take such measures as may be reasonable
              for the purpose of averting or minimising a loss and to ensure that all rights against Carriers, Bailees
              or other third parties are properly preserved and exercised. In particular, the Assured or their Agents
              are required: 1. To claim immediately on the Carriers, Port Authorities or other Bailees for any missing
              packages. 2. In no circumstances, except under written protest, to give clean receipts where goods are in
              doubtful condition. 3. When delivery is made by Container, to ensure that the Container and its seals are
              examined immediately by their responsible official. If the Container is delivered damaged or with seals
              broken or missing or with seals other than as stated in the shipping documents, to clause the delivery
              receipt accordingly and retain all defective or irregular seals for subsequent identification. 4. To apply
              immediately for survey by Carriers&apos; or other Bailees&apos; Representatives if any loss or damage be
              apparent and claim on the Carriers or other Bailees for any actual loss or damage found at such survey. 5.
              To give notice in writing to the Carriers or other Bailees within 3 days of delivery if the loss or damage
              was not apparent at the time of taking delivery.
              <br />
              NOTE: The Consignees or their Agents are recommended to make themselves familiar with the Regulations of
              the Port Authorities at the port of discharge.
            </p>
          </Row>
          <Row title="DOCUMENTATION OF CLAIMS:" id="documentationOfClaims">
            To enable claims to be dealt with promptly, the Assured or their Agents are advised to submit all available
            supporting documents without delay to the Surveyor office below or to Survey Agents if appointed including
            when applicable: 1 Original policy or certificate of insurance. 2 Original or copy shipping invoices,
            together with shipping specification and/or weight notes. 3 Original Bill of Lading and/or other contract of
            carriage. (Full set in the event of total loss.) 4 Survey report or other documentary evidence to show the
            extent of the loss or damage. 5 Landing account and weight notes at final destination. 6 Correspondence
            exchanged with the Carriers and other Parties regarding their liability for the loss or damage.
          </Row>
          <Row title="SURVEYOR OR CLAIMS AGENTS:" id="surveyorOrClaimsAgents">
            {additionalInformation?.surveyorName}
            <br />
            {additionalInformation?.surveyorFormattedAddress
              ?.split("\n")
              .filter(Boolean)
              .map((str) => (
                <>
                  {str}
                  <br />
                </>
              ))}
          </Row>
          <Row title="PREMIUM:" id="premium">
            Subject to a premium of{" "}
            {asCurrency(
              (quote?.rates?.basePremium?.grossPremium ?? 0) + (quote?.rates?.exhibitionsPremium?.grossPremium ?? 0),
            )}{" "}
            payable in full plus any applicable taxes.
          </Row>
          <Row title="SECURITY:" id="security">
            {isSecurityCSL1084 && (
              <>
                100% Chaucer Syndicates 1084 at Lloyd&apos;s. Lineslip reference number{" "}
                {contract.placingBroker?.single?.binding_authority}
              </>
            )}
            {isSecurityCIC && (
              <>
                100% Chaucer Insurance Company DAC. Lineslip reference number{" "}
                {contract.placingBroker?.single?.binding_authority}
              </>
            )}
          </Row>
          <Row title="TAXES PAYABLE BY THE INSURED:" id="taxesPayableByTheInsured">
            As per local requirement.
          </Row>
          <Row title="INSURER CONTRACT DOCUMENTATION:" id="insurerContractDocumentation">
            This document details the contract terms entered into by the Underwriters and constitutes the contract
            document attached.
          </Row>
          <Row title="CANCELLATION:" id="cancellation">
            Subject to:
            <br />
            30 days&apos; notice of cancellation from either party at any time for Marine risks,
            <br />7 days&apos; notice of cancellation for War, Strikes, Riots and Civil Commotions risks, but 48
            hours&apos; notice of cancellation for Strikes, Riots, and Civil Commotions risks in respect of sendings to
            or from U.S.A.
            <br />
            <br />
            <p>
              Cancellation shall become effective on the expiry of the appropriate number of days from midnight on the
              day on which notice of cancellation is issued by Assured or by Underwriters but shall not apply to any
              transit insurance which shall have been declared or attached in accordance with contract terms and
              conditions before the cancellation becomes effective.{" "}
            </p>
          </Row>
        </tbody>
      </table>
    </section>
  );
};
