import React from "react";
import { QUOTE_STATUSES, QUOTE_TYPES } from "../../../constants";
import H3 from "../../components/H3";

const asDate = (s: string) => Intl.DateTimeFormat("en-GB", { dateStyle: "short" }).format(Date.parse(s));

const asPercent = (n: number) =>
  Intl.NumberFormat("en-GB", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(n);

const asCurrency = (n: number) =>
  Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(n);

const bySubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;

export const DevQuoteCard = ({ expandAll, heading, headingText, isCurrent, isEndorsement, quotesData }) => {
  const defaultQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.DEFAULT);
  const commercialQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.COMMERCIAL);
  const areSubmitted = quotesData.every(bySubmitted);
  const allIds = quotesData.map((quoteData) => quoteData.id);
  const rest = quotesData.filter((quote) => quote.type !== QUOTE_TYPES.DEFAULT);
  const sortedQuotesData = defaultQuote ? [defaultQuote, ...rest] : rest;

  return (
    <div className="bg-white shadow rounded mb-4">
      {heading}
      {headingText && <H3 className="mb-4 p-4">{headingText}</H3>}
      <div>
        {(isEndorsement ? quotesData : sortedQuotesData).map((quote: any, index: number) => (
          <div className="p-4 border-b border-gray-300 last:border-0" key={quote.id}>
            <pre>
              <div className="my-2">
                <b>Summary</b>
              </div>
              <div>
                ref: <b>{quote.ref}</b>
              </div>
              <div>
                id: <b>{quote.id.split("-")[0]}</b>
              </div>
              <div>
                type: <b>{quote.type}</b>
              </div>
              <div>
                status: <b>{quote.status}</b>
              </div>
              {quote?.submission?.quote?.limit_required?.amount !== undefined && (
                <div>
                  limit_required: <b>{asCurrency(quote.submission.quote.limit_required.amount)}</b>
                </div>
              )}
              {quote?.submission?.quote?.inception_date !== undefined && (
                <div>
                  inception_date: <b>{asDate(quote.submission.quote.inception_date)}</b>
                </div>
              )}
              {quote?.submission?.quote?.expiry_date !== undefined && (
                <div>
                  expiry_date: <b>{asDate(quote.submission.quote.expiry_date)}</b>
                </div>
              )}
              {quote?.submission?.quote?.deductible?.amount !== undefined && (
                <div>
                  deductibleAmount: <b>{asCurrency(quote.submission.quote.deductible.amount)}</b>
                </div>
              )}
              {quote?.rates?.deductibleOption !== undefined && (
                <div>
                  deductibleOption: <b>{quote.rates.deductibleOption}</b>
                </div>
              )}

              <div className="my-2">
                <b>Rates</b>
              </div>

              {quote?.rates?.grossPremium !== undefined && (
                <div>
                  gross: <b>{asCurrency(quote.rates.grossPremium)}</b>
                </div>
              )}

              {quote?.rates?.grossPremium !== undefined && (
                <div>
                  gross w/o tripra: <b>{asCurrency(quote.rates.grossPremium - quote.rates.tripraAmount)}</b>
                </div>
              )}

              {quote?.rates?.tripraAmount !== undefined && (
                <div>
                  tripra amount: <b>{asCurrency(quote.rates.tripraAmount)}</b>
                </div>
              )}

              {quote?.rates?.commission !== undefined && (
                <div>
                  commission: <b>{asPercent(quote.rates.commission)}</b>
                </div>
              )}

              {quote?.rates?.commissionAmount !== undefined && (
                <div>
                  commission amount: <b>{asCurrency(quote.rates.commissionAmount)}</b>
                </div>
              )}

              {quote?.rates?.ownerCommission !== undefined && (
                <div>
                  ownerCommission: <b>{asPercent(quote.rates.ownerCommission)}</b>
                </div>
              )}

              {quote?.rates?.ownerCommissionAmount !== undefined && (
                <div>
                  ownerCommissionAmount: <b>{asCurrency(quote.rates.ownerCommissionAmount)}</b>
                </div>
              )}

              {quote?.rates?.netPremium !== undefined && (
                <div>
                  net: <b>{asCurrency(quote.rates.netPremium)}</b>
                </div>
              )}

              {quote?.rates?.aprp !== undefined && (
                <div>
                  ap/rp: <b>{asCurrency(quote.rates.aprp)}</b>
                </div>
              )}

              {quote?.rates?.tally !== undefined && (
                <div>
                  tally: <b>{asCurrency(quote.rates.tally)}</b>
                </div>
              )}

              {quote?.rates?.totalPremiumPaid !== undefined && (
                <div>
                  totalPremiumPaid: <b>{asCurrency(quote.rates.totalPremiumPaid)}</b>
                </div>
              )}
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};
