import React from "react";
import NumberFormat from "react-number-format";
import Input from "../Input";
import InputField from "../InputField";
import InputReadOnly from "../InputReadOnly";

const InputNumberSchema = ({
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  item: schema,
  labelText,
  name,
  noErrorLabel,
  onChange,
  value,
}) => {
  const handleChange = ({ floatValue }) => {
    if (onChange) {
      onChange(floatValue, name);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {isReadOnly && (
        <InputReadOnly
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={value}
        />
      )}

      {!isReadOnly && (
        <NumberFormat
          allowNegative={false}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          className="aui-input"
          customInput={Input}
          decimalScale={0}
          defaultValue={defaultValue}
          errors={errors}
          id={id || name}
          max={schema.maximum}
          maxLength={schema.maxLength ?? 22}
          min={schema.minimum}
          name={name}
          onValueChange={handleChange}
          thousandSeparator={schema.thousandSeparator ?? true}
          value={value}
        />
      )}
    </InputField>
  );
};

export default InputNumberSchema;
