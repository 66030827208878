import { STATUSES } from "./statuses";

export const getContractStatusesMap = (defaultProductRef) => {
  switch (defaultProductRef) {
    case "mc":
      return {
        [STATUSES.QUOTED]: "quote_document,static",
        [STATUSES.REFERRED_QUOTED]: "quote_document",
        [STATUSES.IN_PROGRESS]: "quote_document",
        [STATUSES.BOUND]: "policy_document,static",
        [STATUSES.INFORCE]: "quote_document,policy_document",
        [STATUSES.CANCELLED]: "quote_document,policy_document,static",
      };
    case "cargo-us":
      return {
        [STATUSES.QUOTED]: "quote_document",
        [STATUSES.REFERRED_QUOTED]: "quote_document",
        [STATUSES.IN_PROGRESS]: "quote_document",
        [STATUSES.BOUND]: "quote_document,policy_document,broker_invoice_document",
        [STATUSES.INFORCE]: "quote_document,policy_document,broker_invoice_document",
        [STATUSES.CANCELLED]: "quote_document,policy_document,policy_cancellation_document,broker_invoice_document",
      };
    default:
      return {
        [STATUSES.QUOTED]: "quote_document",
        [STATUSES.REFERRED_QUOTED]: "quote_document",
        [STATUSES.IN_PROGRESS]: "quote_document",
        [STATUSES.BOUND]: "quote_document,policy_document,broker_jacket_document",
        [STATUSES.INFORCE]: "quote_document,policy_document,broker_jacket_document",
        [STATUSES.CANCELLED]: "quote_document,policy_document,broker_jacket_document",
      };
  }
};

export const getEndoStatusesMap = (defaultProductRef) => {
  switch (defaultProductRef) {
    case "mc":
      return {
        [STATUSES.QUOTED]: "quote_endorsement_document",
        [STATUSES.REFERRED_QUOTED]: "quote_endorsement_document",
        [STATUSES.IN_PROGRESS]: "quote_endorsement_document",
        [STATUSES.APPLIED]: "bound_endorsement_document,quote_endorsement_document",
        [STATUSES.CANCELLED]: "bound_endorsement_document",
      };
    case "cargo-us":
      return {
        [STATUSES.QUOTED]: "quote_endorsement_document",
        [STATUSES.REFERRED_QUOTED]: "quote_endorsement_document",
        [STATUSES.IN_PROGRESS]: "quote_endorsement_document",
        [STATUSES.APPLIED]: "bound_endorsement_document,quote_endorsement_document",
        [STATUSES.CANCELLED]: "policy_cancellation_document",
      };
    default:
      return {
        [STATUSES.QUOTED]: "quote_endorsement_document",
        [STATUSES.REFERRED_QUOTED]: "quote_endorsement_document",
        [STATUSES.IN_PROGRESS]: "quote_endorsement_document",
        [STATUSES.APPLIED]: "bound_endorsement_document,quote_endorsement_document",
        [STATUSES.CANCELLED]: "bound_endorsement_document",
      };
  }
};
