import React, { FC } from "react";
import { parseSubmission } from "../../parseSubmission";
import { BrokerSecurity, PolicyDocumentProps } from "../../types";
import { Single_own_vehicle_copy } from "./4_0_Single_own_vehicle_copy";
import { Single_exhibition } from "./5_1_Single_exhibition";

export const Single_summary_of_coverage: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  const parsedSubmission = parseSubmission(quote.submission, "cargo-us-single");

  const isSecurityCSL1084 = contract.placingBroker.security === BrokerSecurity.security1;
  const isSecurityCIC = contract.placingBroker.security === BrokerSecurity.security2;

  return (
    <section aria-labelledby="single_summary_of_coverage">
      <h1>SUMMARY OF COVERAGE</h1>
      <h1>Summary of Important Insurance Terms</h1>

      <p>
        Please note that this summary is merely intended to provide an explanation of some of the important insurance
        terms applicable and does not alter the terms & conditions of insurance whatsoever. For full details of the
        cover, please refer to the actual clauses as stated and all conditions.
      </p>

      <p>
        You have stated that the Interest to be insured is:
        <br />
        {parsedSubmission.cargo.condition} and {parsedSubmission.cargo.cargos?.[0]?.type} Transported as{" "}
        {parsedSubmission.conveyance?.conveyances?.[0]?.type}
        {parsedSubmission.conveyance.conveyances?.[0]?.type === "Sea" && (
          <> By {parsedSubmission.conveyance.conveyances?.[0]?.containerType}</>
        )}
        {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" &&
          parsedSubmission.conveyance?.conveyances?.[0]?.aovTransits &&
          ` using your own vehicle.`}
        {!parsedSubmission.conveyance?.conveyances?.[0]?.aovTransits && `.`}
        <br />
        From {parsedSubmission.shipment.cityFrom}, {parsedSubmission.shipment.countryFrom} to{" "}
        {parsedSubmission.shipment.cityTo}, {parsedSubmission.shipment.countryTo}.
      </p>
      {parsedSubmission.cargo.condition !== "Used" && !parsedSubmission.cargo.iccClause && (
        <>
          <p>
            The Institute Cargo Clauses (A) or (Air) are normally the widest form of cover available and afford cover
            against &quot;All Risks&quot; of physical loss or damage to the goods by accidental causes during the
            ordinary course of transit as well as cover for General Average.
          </p>
          <p>
            The clauses contain a standard list of exclusions of which the principal exceptions are loss due to: delay,
            the inherent nature of the goods, insufficient or inadequate packing, ordinary wear and tear, consequential
            loss or loss of market.
          </p>
        </>
      )}
      {(parsedSubmission.cargo.condition === "Used" || parsedSubmission.cargo.iccClause) && (
        <>
          <p>
            The Institute Cargo Clauses (C) offer a restricted form of cover against perils that by their nature, might
            might likely result in a total loss of the cargo. The major perils covered are:
          </p>
          <ol style={{ listStyleType: "none" }}>
            <li>Fire or explosion</li>
            <li>Vessel or craft being stranded grounded sunk or capsized</li>
            <li>Overturning or derailment of land conveyance</li>
            <li>Collision or contact of vessel craft or conveyance with any external object other than water</li>
            <li>Discharge of cargo at a port of distress,</li>
            <li>Loss of or damage to the subject-matter insured caused by</li>
            <li>General average sacrifice</li>
            <li>Jettison</li>
          </ol>
        </>
      )}
      {parsedSubmission.shipment.countryFromIsWarAndStrikesIncluded &&
        parsedSubmission.shipment.countryToIsWarAndStrikesIncluded && (
          <p>
            War & Strikes
            <br />
            Where this policy is extended to include the Institute War Clauses (Cargo) and Institute Strikes Clauses
            (Cargo) or the applicable Air clauses; which cover the goods against loss of or damage caused by warlike
            activities whilst at sea or on the aircraft and/or caused by rioters during civil commotions and strikes or
            terrorist or politically motivated acts. The basic exclusions apply and it should be noted that the strikes
            element does not include expense incurred where the goods are diverted from a strike bound port or from a
            strike bound airport.
          </p>
        )}
      {parsedSubmission.cargo.cargos?.[0]?.group === 6 && (
        <p>MOTOR VEHICLES – subject to the Motor Vehicle Conditions as attached Warranted shipped underdeck.</p>
      )}
      {parsedSubmission.cargo.cargos?.[0]?.group === 5 && (
        <p>
          HOUSEHOLD GOOD AND PERSONAL EFFECTS – Subject to the Household Goods and Personal Effects Conditions as
          attached
        </p>
      )}
      <p>
        Duration
        <br />
        The cover attaches from the time the goods are first moved in the warehouse or place of storage named in the
        contract of insurance for the immediate loading into the carrying conveyance for the commencement of transit,
        continues during the ordinary sea voyage or air transit and terminates either on completion of unloading from
        the carrying vehicle at the destination (or elected storage location) or on the expiry of 60 days after
        completion of discharge overside from the overseas vessel or on the expiry of 30 days after completion of
        discharge from the aircraft, whichever occurs first.
      </p>
      {parsedSubmission.exhibition.hasExhibitions && !parsedSubmission.exhibition.exhibitions?.[0]?.returnBack && (
        <p>
          For exhibitions cover shall terminate after a maximum of
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "< 7 days" &&
            ` 7 days at the exhibition premises.`}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "8-14 days" &&
            ` 30 days at the exhibition premises.`}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "15-30 days" &&
            ` 14 days at the exhibition premises.`}
        </p>
      )}
      {parsedSubmission.exhibition.exhibitions?.[0]?.returnBack && (
        <p>
          For exhibitions cover includes a maximum of
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "< 7 days" && ` 7 days `}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "8-14 days" && ` 30 days `}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "15-30 days" && ` 14 days `}
          at the exhibition premises and shall terminate upon return to your domicile after exhibition or on the expiry
          of 60 days after completion of discharge overside from the overseas vessel or on the expiry of 30 days after
          completion of discharge from the aircraft, whichever occurs first.
        </p>
      )}
      {parsedSubmission.cargo.condition === "New" && !parsedSubmission.cargo.iccClause && (
        <p>It is a condition of this insurance that the interest insured is in a brand new condition.</p>
      )}
      {parsedSubmission.cargo.condition === "Fully reconditioned to a new standard" &&
        !parsedSubmission.cargo.iccClause && (
          <p>It is a condition of this insurance that the interest insured is in a fully Reconditioned condition.</p>
        )}
      <p>
        Deductible: Where a deductible is stated, this is the amount deducted by underwriters from any recoverable claim
        that is submitted.
      </p>
      <p>
        Basis of Valuation
        <br />
        It is agreed that the basis of valuation for the purpose of this single shipment policy shall be the value
        declared for insurance, but in no case shall the valuation exceed Cost Insurance & Freight (CIF) plus 20% unless
        prior written consent of the Insurer is given. In the event of declaration after loss or arrival, the basis of
        valuation will be CIF plus 10% only. Also to pay increased value by reason of Duty, Excise, Surcharge and/or
        Landing and similar charges, if incurred in anticipation of arrival and provided declared to and accepted by
        underwriters. The assured agrees to take all reasonable steps to obtain a refund of such charges and return the
        net amount to underwriters.
      </p>
      {parsedSubmission.exhibition.hasExhibitions && (
        <p>
          In respect of goods in transit to, from or whilst at Exhibitions if insured the basis of valuation will be
          Market value at the time of loss less depreciation for wear & tear if applicable.
        </p>
      )}
      <p>
        In respect of non Import or non Export transits, Purchases and Sales including goods sold FOB, C&F or similar
        the basis of valuation will be: Invoice Cost price to buyer plus freight and packing costs if incurred
        separately. Other Transits: Market value at the time of loss.
      </p>
      <p>
        Debris Removal Clause: This insurance is extended to cover, in addition to any other amount recoverable under
        this insurance, extra expenses reasonably incurred by the Assured for the removal and disposal of debris of the
        subject matter insured, or part thereof, by reason of damage thereto caused by an insured risk, but excluding
        absolutely:
        <br />
        - any expenses incurred in consequence of or to prevent or mitigate pollution or contamination, or any threat or
        liability thereof <br />
        - the cost of removal of cargo from any vessel or craft
        <br />
      </p>
      <p>
        In no case shall the insurers be liable under this clause for more than 10% in all of the proportionate insured
        value under this policy of the damaged subject-matter removed.
      </p>

      <p>
        Cuttings Clause: Applicable to items where dimensions are not imperative.
        <br />
        In the event of damage or breakage caused by an insured peril it is agreed that the damaged or broken length or
        portion shall be cut off, the remaining length or portion to be considered as sound and the Underwriters only to
        be liable for the insured value of the length or portion which has been lost by being broken off or cut off and
        for the cost of cutting.
      </p>

      <p>
        Electrical & Mechanical Derangement: Excluded unless caused by a peril insured against under the terms of this
        policy and there is evidence of an external damage.
      </p>

      <p>
        ITEMS IN NON-WATERPROOF CRATES OR PACKAGING Excluding Rust, Oxidation, Discoloration, Wetting, Staining and the
        Cost of Repainting as applicable.
      </p>

      <p>
        Law and Practice: UK risks - This insurance is subject to English law and practice and to the exclusive
        jurisdiction of the English Courts sitting in London. Non UK risks - This insurance is subject to English law
        and practice.
      </p>

      <p>
        Loss of Data: In respect of electronic or computerised items: Including loss or damage for installed or other
        included software such as operating systems or office suites but excluding claims for loss of data or any other
        proprietary information.
      </p>

      <p>
        Onus of Proof: The onus of proof rests with the Assured to show that loss of or damage to the subject matter
        insured occurred within the period of cover and occurred within the scope and ambit of the Policy.
      </p>

      <p>
        Open Top Containers: In respect of goods in open top containers: Excluding claims for loss or damage to any part
        or parts of the subject matter that protrude outside of the boundaries of the top of an open top container
        unless following a peril insured under the terms of the Institute Cargo Clauses (B) CL 383 dated 1.1.2009
      </p>
      <p>Communicable Diseases: Excluding losses attributable to communicable diseases and other organisms.</p>
      <p>
        DRIED FOODSTUFFS IN BAGS & SACKS
        <br />
        Excluding Heating and Sweating Excluding Infestation arising from weevil, grub or web Excluding Rejection Risks
        Excluding any Natural loss in Weight Warranted shipped in ventilated container(s)
      </p>

      <p>
        STONE, MARBLE, SLATE, GRANITE AND SIMILAR SLABS
        <br />
        Warranted slabs are individually separated and suitably packed or protected for transit in crates or cases.
        Excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or
        dropping whilst loading or unloading.
      </p>
      {parsedSubmission.cargo.cargos?.[0]?.group !== 7 && (
        <p>
          Temperature Variation: Excluding claims for loss, damage or change in the nature of the subject matter insured
          caused by variation in temperature unless caused by a peril insured under the terms of the Institute Cargo
          Clauses (B) CL383 dated 1.1.2009.
        </p>
      )}
      {parsedSubmission.cargo.condition === "Used" && (
        <p>
          USED GOODS
          <br />
          Unless otherwise agreed with Underwriters prior to the commencement of transit, the Institute Cargo Clauses
          (A) or the Institute Cargo Clauses (Air) are deleted and replaced by the Institute Cargo Clauses (C) CL 384
          dated 1.1.2009 extended to include the risks of theft or non-delivery of an entire consignment or full
          container, trailer or vehicle load. Subject also to the Secondhand Replacement Clause.
        </p>
      )}
      {parsedSubmission.cargo.condition === "Fully reconditioned to a new standard" && (
        <p>
          Reconditioned Goods and / or Refurbished Used Goods
          <br />
          Warranted that the Interest insured is fully refurbished / reconditioned to a new standard. Excluding Rust,
          Oxidation, Discoloration, Wetting, Staining, Scratching, Bruising Chipping, Denting, Marring and the Cost of
          Repainting as applicable unless caused by an Insured peril. Subject to the Secondhand Replacement Clause as
          above.
        </p>
      )}
      <p>
        YACHTS AND OTHER BOATS Yachts and other Boats Warranted new and transported in fully enclosed containers or
        trucks. Excluding loss or damage to accessories or portable items unless declared prior to shipment and they are
        suitably packed or protected for transit. Excluding theft or pilferage of audio, electronic communication or
        display equipment, entertainment or computer or navigational equipment and similar items unless stolen with the
        craft. Warranted that unpacked craft are suitably secured to a trailer or cradle.
      </p>

      {parsedSubmission?.conveyance?.conveyances?.[0]?.aovTransits && <Single_own_vehicle_copy />}
      {parsedSubmission?.exhibition.hasExhibitions && <Single_exhibition />}

      {(parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" ||
        parsedSubmission.conveyance?.conveyances?.[0]?.type === "Rail only" ||
        parsedSubmission.conveyance?.conveyances?.[0]?.type === "Air only" ||
        (parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
          parsedSubmission.conveyance?.conveyances?.[0]?.containerType === "Breakbulk")) && (
        <p>
          UNPACKED / UNPROTECTED ITEMS Unpacked or Unprotected items: Excluding Rust, Oxidation, Discoloration, Wetting,
          Staining, Scratching, Bruising, Chipping, Denting, Marring and the Cost of Repainting as applicable.
        </p>
      )}
      <p>
        Cancellation: The insurance as evidenced by the marine cargo insurance policy may be cancelled at any time prior
        to commencement of the risk.
      </p>
      <p>
        Claims: In the event of a claim there are clear instructions stated on the marine cargo insurance policy for the
        procedure to be undertaken, including documentation required. Information regarding a surveyor if necessary is
        also shown. The claims matter may be referred directly to Underwriters at any time.
      </p>
      {isSecurityCSL1084 && (
        <>
          <p>
            <strong>CUSTOMER COMPLAINTS PROCEDURE</strong>
            <br />
            In the event of a complaint, in the first instance please contact your broker.
            <br />
            We will send you and/or your Broker written acknowledgement of your complaint within five business days of
            its receipt, giving the name or job title of the individual handling the complaint for us
          </p>

          <p>
            If we are able to provide a final response within five business days of receipt of a complaint we may
            combine our acknowledgement of the complaint with the final response.
          </p>
          <p>We will, within four weeks of receiving your complaint, send you and/or your broker either:</p>

          <p>
            a final response or <br />
            a holding response, which explains why we have not been able to respond as yet we will by the end of eight
            weeks after its receipt of your complaint, send you and/or your broker either;
            <br />
            a final response or
            <br />a holding response, which explains why we have not been able to respond yet
          </p>

          <p>
            If you remain dissatisfied with our response, you may refer your complaint to the Financial Ombudsman
            Service (FOS) and must do so within six months to be eligible. You can contact the FOS by telephone on 0800
            023 4567 and further information is available at http://www.financial‐ombudsman.org.uk/
          </p>
          <p>
            Financial Ombudsman Service
            <br />
            South Quay Plaza
            <br />
            183 Marsh Wall, London E14 9SR
            <br />
            Tel: +44 (0) 207 964 1000
            <br />
            Fax: +44 (0) 207 964 1001
            <br />
            Email : complaint.info@financial‐ombudsman.org.uk
          </p>
          <p>
            Financial Conduct Authority
            <br />
            25 The North Colonnade
            <br />
            Canary Wharf, London E14 5HS
            <br />
            Tel: +44 (0) 207 066 1000
            <br />
            Fax: +44 (0) 207 066 1099
          </p>
          <p>
            Policyholder and Market Assistance
            <br />
            Lloyd’s
            <br />
            One Lime Street, London EC3M 7HA
            <br />
            Tel: +44 (0) 207 327 5693
            <br />
            Fax: +44 (0) 207 327 5225
            <br />
            Email:Complaints@Lloyds
          </p>

          <p>The Financial Services Compensation Scheme</p>

          <p>
            Insurance products underwritten by Lloyd’s Syndicates are covered by the Financial Services Compensation
            Scheme (FSCS). You may be entitled to compensation from the scheme if they become insolvent and cannot meet
            their obligations. This depends on the type of business and the circumstances of the claim. Where you are
            entitled to claim, insurance advising and arranging is covered for 90% of the claim, without any upper
            limit.
          </p>
          <p>
            You can contact the FSCS with the below details:
            <br />
            Financial Services Compensation Scheme (FSCS),
            <br />
            10th Floor, Beaufort House,
            <br />
            15 St Boltolph Street, London, EC3A 7QU
            <br />
            Website: www.fscs.org.uk&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telephone: 0207 892 7300
          </p>
          <p>
            Chaucer Syndicates Ltd a Lloyd’s Managing Agent are authorised and regulated by the Prudential Regulation
            Authority and regulated by the Financial Conduct Authority, their Financial Services Registration Number is
            204915.
          </p>
          <p>
            You can check this on the FS Register by visiting the Financial Conduct Authority website
            www.fsa.gov.uk/register/home.do or by contacting the Financial Conduct Authority on 0800 111 6768.
          </p>
        </>
      )}
      {isSecurityCIC && (
        <>
          <p>
            We are dedicated to providing a high-quality service and we want to ensure that we maintain this at all
            times. If you have any questions or concerns about the policy or the handling of a claim please contact your
            broker through whom this policy was arranged.
          </p>
          <p>
            If you wish to make a complaint in respect of your policy, you can do so at any time by referring the matter
            in the first instance to the broker through whom this policy was arranged. If they are unable to resolve
            your complaint, it will be escalated to our Complaints Manager
          </p>
          <p>
            If you wish to make a complaint in respect of a claim, you can do so at any time by referring the matter to
            the appointed claim handler that has been allocated to your claim. Your broker will advise you of their
            contact details. If they are unable to resolve your complaint, it will be escalated to our Complaints
            Manager
          </p>
          <p>
            Your State Department of Insurance should be contacted only after discussions with the insurer, its agent,
            or representative, have failed to produce a satisfactory resolution.
          </p>
          <p>
            Alternatively, if you are classified as a “micro” or “small commercial” customer, as your insurer is based
            in Ireland, and if you remain dissatisfied after we have considered your complaint, or you have not received
            a decision by the time we have taken 40 working days to consider your complaint, you may be eligible to
            refer your complaint to the Financial Services and Pensions Ombudsman (FSPO), an independent body that
            adjudicates on complaints, at the following address: Financial Services and Pensions Ombudsman 3rd Floor,
            Lincoln House Lincoln Place Dublin 2 Ireland Tel: +353 1 567 7000 Email: info@fspo.ie{" "}
            <a href="https://www.fspo.ie">www.fspo.ie</a>
          </p>
          <p>The complaints handling arrangements above are without prejudice to your rights in law.</p>
        </>
      )}
    </section>
  );
};
