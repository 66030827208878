import moment from "moment";
import { Money, QuoteStatus, QuoteType } from "../../@types/types";
import { Deductible, Limit, ParsedSubmission } from "./types";
import { getEndorsements } from "../../services/aws/contracts";
import get from "lodash.get";
import { ENDORSEMENT_MATERIAL_REASONS } from "./adjustablePremium";

export const isAutoQuote = ({ type }: { type: QuoteType }): boolean => type === "default" || type === "additional";

export const byActive = ({ status }: { status: QuoteStatus }): boolean => status === "Submitted" || status === "Bound";

export const sanitizeHtml = (text: string): string =>
  text.replace("<body>", "").replace("</body>", "").replace("<html>", "").replace("</html>", "");

const getPolicyPeriodDays = (startDate: moment.Moment, endDate: moment.Moment) => {
  let diffInDays = endDate.diff(startDate, "day");
  if (diffInDays === 366 && ((startDate.isLeapYear() && startDate.isBefore(`${startDate.year()}-03-01`)) || (endDate.isLeapYear() && endDate.isAfter(`${endDate.year()}-02-28`)))) {
    diffInDays = 365;
  }
  return diffInDays;
};

export const parseSubmission = (submission: any): ParsedSubmission => {
  const today = moment.utc().startOf("day");
  const businessType = submission?.general_information?.business_type;
  const diving =
    submission?.diving?.does_insured_engage_in_diving && submission.diving.does_insured_engage_in_diving === "Yes";
  const expiryDate = submission?.quote?.expiry_date;
  const expiryDateMoment = moment.utc(expiryDate).startOf("day");
  const inceptionDate = submission?.quote?.inception_date;
  const effectiveFrom = submission?.quote?.effective_from;
  const inceptionDateMoment = moment.utc(inceptionDate).startOf("day");
  const daysSinceInception = inceptionDate && today.diff(moment.utc(inceptionDate).startOf("day"), "day");
  const insuredName = submission?.general_information?.insured_name;
  const jonesActClaims = submission?.loss_record?.has_insured_had_jones_act_claims;
  const hasJonesActClaims =
    (jonesActClaims && jonesActClaims === "Yes (provide details)") ||
    (jonesActClaims && jonesActClaims === "Yes (upload document)");
  const jonesActClaimsPayload = JSON.stringify(submission?.loss_record?.loss_details);
  const limit = submission?.quote?.limit_required as Limit;
  const oneOffJob = submission?.quote?.is_job_one_off_contract && submission.quote.is_job_one_off_contract === "Yes";
  const ownsWatercraft = submission?.watercraft?.owns_watercraft && submission.watercraft.owns_watercraft === "Yes";

  const policyPeriodDays = expiryDate && inceptionDate && getPolicyPeriodDays(inceptionDateMoment, expiryDateMoment);
  const separateInsurance =
    submission?.watercraft?.separate_insurance && submission.watercraft.separate_insurance === "Yes";
  const timeOnboardWatercraft =
    submission?.watercraft?.time_onboard_watercraft && submission.watercraft.time_onboard_watercraft === "Yes";
  const trialTrips = submission?.watercraft?.trial_trips && submission.watercraft.trial_trips === "Yes";
  const tripra = submission?.quote?.tripra && submission?.quote?.tripra === "Yes";
  const watercraftHasCaptain =
    submission?.watercraft?.watercraft_has_captain && submission.watercraft.watercraft_has_captain === "Yes";
  const watercraftTransport =
    submission?.watercraft?.watercraft_transport && submission.watercraft.watercraft_transport === "Yes";
  const notWorkingOnPlatforms =
    submission?.rigs?.insured_will_not_undertake_work_on_platforms &&
    submission.rigs.insured_will_not_undertake_work_on_platforms === "No";
  const overwaterOperationDescription = submission?.general_information?.overwater_operation_description;
  const deductible = submission?.quote?.deductible as Deductible;
  const payrollBreakdown = submission?.payroll?.payroll_breakdown;

  const stateIsTexas =
    submission?.general_information?.address?.state && submission.general_information.address.state === "Texas";
  const doesRequireMaintenance =
    submission?.general_information?.does_company_require_maintenance &&
    submission.general_information.does_company_require_maintenance === "Yes";
  const areEmployeesExposed =
    submission?.general_information?.are_employees_exposed &&
    submission.general_information.are_employees_exposed === "Yes";

  const hasStateActPayroll =
    payrollBreakdown?.state_act_on_land_dock?.current_year?.amount &&
    payrollBreakdown?.state_act_on_land_dock?.next_year?.amount;

  const hasOtherPayroll =
    payrollBreakdown?.longshore_on_land_dock?.current_year?.amount ||
    payrollBreakdown?.longshore_on_land_dock?.next_year?.amount ||
    payrollBreakdown?.longshore_on_watercraft_dockside?.current_year?.amount ||
    payrollBreakdown?.longshore_on_watercraft_dockside?.next_year?.amount ||
    payrollBreakdown?.longshore_on_watercraft_away_from_dock?.current_year?.amount ||
    payrollBreakdown?.longshore_on_watercraft_away_from_dock?.next_year?.amount ||
    payrollBreakdown?.jones_act?.current_year?.amount ||
    payrollBreakdown?.jones_act?.next_year?.amount;

  const hasJonesActPayroll =
    payrollBreakdown?.jones_act?.current_year?.amount || payrollBreakdown?.jones_act?.next_year?.amount;

  const jonesActPayroll = payrollBreakdown?.jones_act?.next_year as Money;
  const jonesActPayrollEmployees = payrollBreakdown?.jones_act?.next_year_employees;

  const longshoresPayrollNextYear =
    (payrollBreakdown?.longshore_on_land_dock?.next_year?.amount || 0) +
    (payrollBreakdown?.longshore_on_watercraft_dockside?.next_year?.amount || 0) +
    (payrollBreakdown?.longshore_on_watercraft_away_from_dock?.next_year?.amount || 0);

  const totalPayrollNextYear =
    (payrollBreakdown?.state_act_on_land_dock?.next_year.amount || 0) +
    (payrollBreakdown?.longshore_on_land_dock?.next_year.amount || 0) +
    (payrollBreakdown?.longshore_on_watercraft_dockside?.next_year.amount || 0) +
    (payrollBreakdown?.longshore_on_watercraft_away_from_dock?.next_year.amount || 0) +
    (payrollBreakdown?.jones_act?.next_year.amount || 0);

  /* https://trello.com/c/LQAdGYnK/95-512-rating-engine-change */
  const s1 = ownsWatercraft && watercraftTransport && separateInsurance && !watercraftHasCaptain;
  const s2 = ownsWatercraft && !watercraftTransport && separateInsurance && !watercraftHasCaptain;
  const s3 = ownsWatercraft && watercraftTransport && !separateInsurance && !watercraftHasCaptain;
  const s4 = ownsWatercraft && !watercraftTransport && !separateInsurance && !watercraftHasCaptain;

  return {
    areEmployeesExposed,
    businessType,
    daysSinceInception,
    deductible,
    diving,
    doesRequireMaintenance,
    effectiveFrom,
    expiryDate,
    hasJonesActClaims,
    hasJonesActPayroll: Boolean(hasJonesActPayroll),
    hasOtherPayroll: Boolean(hasOtherPayroll),
    hasStateActPayroll,
    inceptionDate,
    insuredName,
    jonesActClaimsPayload,
    jonesActPayroll,
    jonesActPayrollEmployees,
    limit,
    notWorkingOnPlatforms,
    oneOffJob,
    overwaterOperationDescription,
    ownsWatercraft,
    payroll: { amount: longshoresPayrollNextYear, currency: "USD" },
    policyPeriodDays,
    separateInsurance,
    stateIsTexas,
    timeOnboardWatercraft,
    totalPayrollNextYear: { amount: totalPayrollNextYear, currency: "USD" },
    trialTrips,
    tripra,
    watercraft: s1 || s2 || s3 || s4,
    watercraftHasCaptain,
    watercraftTransport,
  };
};

export const maybeAddRemoveTripra = (
  prevTripra: boolean,
  nextTripra: boolean,
  grossPremium: number,
  tripraAmount: number,
): { grossPremium: number; tripraAmount: number } => {
  if (prevTripra && !nextTripra) {
    return { grossPremium: grossPremium / 1.05, tripraAmount: 0 };
  }

  if (!prevTripra && nextTripra) {
    return { grossPremium: grossPremium * 1.05, tripraAmount: grossPremium * 1.05 - grossPremium };
  }

  return { grossPremium, tripraAmount };
};

export const hasExistingMaterialEndorsements = async (productRef: ProductRef, contractId: string): Promise<boolean> => {
  const endorsements = await getEndorsements({ productRef, contractId });
  const endorsementsData = endorsements?.Items?.map(({ data }) => data) ?? [];
  for (let i = 0; i < endorsementsData.length; i++) {
    const referralReasons = get(endorsementsData[i], "referralReasons", []);
    for (let j = 0; j < referralReasons.length; j++) {
      if (Object.values(ENDORSEMENT_MATERIAL_REASONS).includes(referralReasons[j])) {
        return true;
      }
    }
  }
  return false;
};
