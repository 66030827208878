import get from "lodash.get";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ROLES } from "../../constants";
import cargoPermissions from "../../projects/cargo-us/permissions.json";
import melPermissions from "../../projects/mel/permissions.json";
import mcPermissions from "../../projects/mc/permissions.json";

let permissions;
switch (process.env.DEFAULT_PRODUCT_REF) {
  case "cargo-us":
    permissions = cargoPermissions;
    break;
  case "mc":
    permissions = mcPermissions;
    break;
  default:
    permissions = melPermissions;
    break;
}

const useAuth = (providedContractData) => {
  const queryClient = useQueryClient();
  const { contractId, productRef } = useParams();
  const params = { productRef, contractId };
  const userValue = window.localStorage.getItem("user");
  const user = JSON.parse(userValue === "undefined" ? null : userValue);
  const { role, tenantId, subTenantIds } = user;
  const usersData = get(queryClient.getQueryData("users"), "data", []);
  const tenantsData = get(queryClient.getQueryData("tenants"), "data", []);
  const contractData = providedContractData || get(queryClient.getQueryData(["contract", params]), "data.data", {});
  const isAdmin = role === ROLES.ADMIN;
  const isBroker = role === ROLES.BROKER;
  const isUnderwriter = role === ROLES.UNDERWRITER;
  const tenant = tenantsData.find((item) => item.id === tenantId) || {};
  const isProducingBroker = tenant.type === "producing-broker";
  const isPlacingBroker = tenant.type === "placing-broker";
  const subTenants = tenantsData.filter((t) => subTenantIds.includes(t.id));
  const isInternalEnv = ["local", "development", "staging"].includes(process.env.ENVIRONMENT);
  const isUatEnv = ["uat"].includes(process.env.ENVIRONMENT);
  const isLocalEnv = ["local"].includes(process.env.ENVIRONMENT);
  const isDebuggerEnabled = process.env.DEBUGGER === "true";

  const checkPermission = (status) => (action) =>
    get(permissions, [role, action]) || get(permissions, [role, status, action]);

  const checkPermissions = (...args) => {
    if (args.length === 1) {
      const [actions] = args;
      const { status } = contractData;
      const checkAction = checkPermission(status);

      if (Array.isArray(actions)) {
        return actions.some(checkAction);
      }

      return checkAction(actions);
    }

    if (args.length === 2) {
      const [action, status] = args;

      return checkPermission(status)(action);
    }

    return false;
  };

  return {
    checkPermission,
    checkPermissions,
    isAdmin,
    isBroker,
    isInternalEnv,
    isLocalEnv,
    isProducingBroker,
    isPlacingBroker,
    isUatEnv,
    isDebuggerEnabled,
    isUnderwriter,
    role,
    subTenants,
    tenant,
    tenants: tenantsData,
    user,
    users: usersData,
  };
};

export default useAuth;
