import formatDateFns from "date-fns/format";
import parseISO from "date-fns/parseISO";
import countries from "./refData/countries";
import { Country, CountryIso, Region } from "./types";

function chunkArray<T>(array: T[], size: number): T[][] {
  if (array.length <= size) return [array];

  return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
}

export function chunkCountriesArray<T>(array: T[]): T[][] {
  const size = Math.ceil(array.length / 3);
  return chunkArray(array, size);
}

export const getFormattedAddress = (addressObj: {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  zipcode?: string;
  state?: string;
  country?: string;
}, join = `, `): string => {
  const order = ["address_line_1", "address_line_2", "city", "zipcode", "state", "country"] as const;

  return order
    .map((key) => addressObj[key])
    .filter(Boolean)
    ?.join(join);
};

export const sanitizeHtml = (text: string): string =>
  text.replace("<body>", "").replace("</body>", "").replace("<html>", "").replace("</html>", "");

export const onlyUnique = (value: string, index: number, arr: string[]): boolean => arr.indexOf(value) === index;

export const pluralize = (word: string, number?: number): string =>
  number !== undefined && number > 1 ? `${word}s` : word;

export const asFloat = (n: number): string =>
  Number(n).toLocaleString("en-US", { minimumFractionDigits: 4, maximumFractionDigits: 4 });

export const asPercent = (n: number): string =>
  Intl.NumberFormat("en-GB", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(n);

export const asDate = (date: string | Date): string =>
  formatDateFns(typeof date === "string" ? parseISO(date) : date, "dd/MMM/yyyy");

export const asCurrency = (n: number): string =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n);

export const countryToRegion = (country: Country | CountryIso, isIso?: boolean): Region => {
  const row = countries.find((row) => row[isIso ? 1 : 0] === country);
  const region = row?.[2];

  if (region !== undefined) return region;

  throw new Error(`Unable to find a region from a country. Received: ${JSON.stringify({ country })}`);
};

export const countryToWNRAD = (country: Country | CountryIso, isIso?: boolean): boolean | "uw_decision" | null => {
  const row = countries.find((row) => row[isIso ? 1 : 0] === country);
  const WNRAD = row?.[3];

  if (WNRAD === undefined) {
    throw new Error(`Unable to find a WNRAD from a country. Received: ${JSON.stringify({ country })}`);
  }

  return WNRAD;
};

export const countryIsWarAndStrikeIncluded = (country: Country | CountryIso, isIso?: boolean): boolean => {
  const row = countries.find((row) => row[isIso ? 1 : 0] === country);
  const isWarAndStrike = row?.[4];

  if (isWarAndStrike === undefined) {
    throw new Error(
      `Unable to find a Is War and Strikes included from a country. Received: ${JSON.stringify({ country })}`,
    );
  }

  return isWarAndStrike;
};

export const regionToCountries = (region: Region): Country[] => {
  return countries.reduce((previous: Country[], current) => {
    const currentCountry = current[0];
    const currentRegion = current[2];
    if (typeof currentCountry === "string" && currentRegion === region) {
      previous.push(currentCountry);
    }
    return previous;
  }, []);
};

export function assertIsDefined<T>(value: T, name: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) throw new Error(`Missing parameter: ${name}.`);
}
