import { DocumentClient } from "aws-sdk/clients/dynamodb";
import type { ScanCommandInput } from "@aws-sdk/client-dynamodb";
import type { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { paginateScan } from "@aws-sdk/lib-dynamodb";

export const maybeEndoSuffix = (endorsementId?: string): string =>
  endorsementId ? `#endorsement#${endorsementId}` : "";

export const ddbRecursiveQuery = async (
  docClient: DocumentClient,
  params: AWS.DynamoDB.DocumentClient.QueryInput,
): Promise<any[]> => {
  const result = await docClient.query(params).promise();
  let data: any[] = [];

  if (result.Items) {
    data = result.Items;
  }

  if (result.LastEvaluatedKey) {
    params.ExclusiveStartKey = result.LastEvaluatedKey;
    data = data.concat(await ddbRecursiveQuery(docClient, params));
  }

  return data;
};

export const ddbRecursiveScan = async (
  docClient: DocumentClient,
  params: AWS.DynamoDB.DocumentClient.ScanInput,
): Promise<any[]> => {
  const result = await docClient.scan(params).promise();
  let data: any[] = [];

  if (result.Items) {
    data = result.Items;
  }

  if (result.LastEvaluatedKey) {
    params.ExclusiveStartKey = result.LastEvaluatedKey;
    data = data.concat(await ddbRecursiveScan(docClient, params));
  }

  return data;
};

/**
 * AWS SDK v3
 * @param client
 * @param scan
 * @returns
 */
export const ddbRecursiveScan3 = async (client: DynamoDBDocumentClient, scan: ScanCommandInput): Promise<any[]> => {
  const paginator = paginateScan({ client }, scan);
  let results: any = [];

  for await (const result of paginator) {
    if (result?.Items) {
      results = results.concat(result.Items);
    }
  }

  return results;
};
