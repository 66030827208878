import React from "react";

const HiddenSection = ({
  ctx,
  formValues,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
  descriptionText,
  showQuestionId,
  showQuestionKey,
}) => {
  return <div></div>;
};

export default HiddenSection;
