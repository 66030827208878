import React, { FC } from "react";
import { asCurrency } from "../../helpers";
import { parseSubmission } from "../../parseSubmission";
import { PolicyDocumentProps } from "../../types";

export const Single_info_provided_to_underwriters: FC<PolicyDocumentProps> = ({ contract, quote, isQuoteDocument }) => {
  const { insured, ...parsedSubmission } = parseSubmission(quote.submission, "cargo-us-single");

  return (
    <section aria-labelledby="single_info_provided_to_underwriters">
      <h1>
        {isQuoteDocument ? "QUOTE " : ""}
        INFORMATION PROVIDED TO UNDERWRITERS{" "}
      </h1>

      <p>
        The following information has been provided to Underwriters for the purposes of providing a quotation for Marine
        Cargo Insurance.
      </p>

      <p>You have confirmed that …. </p>

      <p>All monetary amounts and premium payments will be in USD</p>

      <p>
        You are a {insured.typeOfCustomer} type of customer with{" "}
        {insured?.companyTurnover?.amount ? asCurrency(insured?.companyTurnover?.amount) : "-"} turnover.
      </p>

      <p>
        You have never had any insurance refused, declined or special terms imposed
        {insured.hadInsuranceRefused && (
          <>
            {" "}
            or if you have then the reason given below is acceptable to Underwriters and we are able to provide a quote.
            <br />
            <br />
            The reason provided as stated below:
            <br />
            {insured.insuranceRefusedReason}
          </>
        )}
      </p>
      <p>Your premium and Loss record is as follows:</p>

      <table className="styled-borders">
        <tr>
          <td>Year</td>
          <td>Premium</td>
          <td>Losses</td>
        </tr>
        {insured.lossHistory?.map((history, index) => (
          <tr key={`loss-history-${index}`}>
            <td>{history.year}</td>
            <td>{asCurrency(history.premium.amount)}</td>
            <td>{asCurrency(history.loss.amount)}</td>
          </tr>
        ))}
      </table>

      <p>
        You require cover for a one off shipment from {parsedSubmission.shipment.cityFrom},{" "}
        {parsedSubmission.shipment.countryFrom} to {parsedSubmission.shipment.cityTo},{" "}
        {parsedSubmission.shipment.countryTo}.
      </p>

      {!parsedSubmission.cargo.cargos?.[0]?.isNotSuitablyPacked && (
        <p>
          You have stated that the type of cargo that you wish to insure is {parsedSubmission.cargo.cargos?.[0]?.type}{" "}
          and is packed in twenty or forty foot ISO containers or if by Road only or Rail only or Airfreight or Post and
          that it is suitably packed or protected for transit.
        </p>
      )}

      <p>You have stated that the condition of your cargo is {parsedSubmission.cargo?.condition}</p>

      {parsedSubmission.cargo.cargos?.[0]?.tempControl === "Frozen below minus 15 Centigrade" && (
        <p>
          You have stated that the cargo will be temperature controlled and is shipped at below 15 degrees Centigrade
        </p>
      )}

      <p>
        You have stated that the value of your cargo is{" "}
        {parsedSubmission.conveyance?.conveyances?.[0]?.insuredValue
          ? asCurrency(parsedSubmission.conveyance.conveyances?.[0].insuredValue?.amount)
          : "-"}
      </p>
      <p>
        It is agreed that the basis of valuation for the purpose of this single shipment policy shall be the value
        declared for insurance, but in no case shall the valuation exceed Cost Insurance & Freight (CIF) plus 20% unless
        prior written consent of the Underwriters is given. In the event of declaration after loss or arrival, the basis
        of valuation will be CIF plus 10% only. Also to pay increased value by reason of Duty, Excise, Surcharge and/or
        Landing and similar charges, if incurred in anticipation of arrival and provided declared to and accepted by
        Underwriters. The Assured agrees to take all reasonable steps to obtain a refund of such charges and return the
        net amount to Underwriters.
      </p>
      {parsedSubmission.exhibition.hasExhibitions && (
        <p>
          In respect of goods in transit to, from or whilst at Exhibitions if insured the basis of valuation will be
          Market value at the time of loss less depreciation for wear & tear if applicable.
        </p>
      )}
      <p>
        In respect of non Import or non Export transits, Purchases and Sales including goods sold FOB, C&F or similar
        the basis of valuation will be: Invoice Cost price to buyer plus freight and packing costs if incurred
        separately.
        <br />
        Other Transits: Market value at the time of loss.
      </p>

      <p>
        You have stated that the Conveyance type is {parsedSubmission.conveyance?.conveyances?.[0]?.type}
        {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Sea" &&
          parsedSubmission.conveyance?.conveyances?.[0]?.containerType !== "Breakbulk" && (
            <> and the goods are packed in an {parsedSubmission.conveyance?.conveyances?.[0]?.containerType}</>
          )}
        {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" &&
          parsedSubmission.conveyance?.conveyances?.[0]?.aovTransits && <> in your own vehicle</>}
        {parsedSubmission.conveyance?.conveyances?.[0]?.type === "Road only" &&
          !parsedSubmission.conveyance?.conveyances?.[0]?.aovTransits && <> not in your own vehicle</>}
      </p>
      {!parsedSubmission.exhibition.hasExhibitions && (
        <p>You have stated you do not require cover for goods on exhibition</p>
      )}
      {parsedSubmission.exhibition.hasExhibitions && (
        <p>
          You have stated that you require cover for goods in transit to an exhibition in{" "}
          {parsedSubmission.shipment.cityTo}, {parsedSubmission.shipment.countryTo}, whilst there for up to
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "< 7 days" && ` 7 days`}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "8-14 days" && ` 14 days`}
          {parsedSubmission.exhibition.exhibitions?.[0]?.duration === "15-30 days" && ` 30 days`}
          {parsedSubmission.exhibition.exhibitions?.[0]?.returnBack && ` and return to your domicile after exhibition.`}
        </p>
      )}
      {parsedSubmission.cargo.iccClause && (
        <p>
          You have selected that you require a basic named perils level of coverage based upon the ICC (C). Full details
          of the coverage is shown in the Summary of Coverage
        </p>
      )}
      <p>
        You have selected a deductible of{" "}
        {parsedSubmission.cargo.cargos?.[0]?.deductible?.amount
          ? asCurrency(parsedSubmission.cargo.cargos?.[0]?.deductible?.amount)
          : "-"}{" "}
        and the premium and terms reflect this request.
      </p>
    </section>
  );
};
