export const ACTIONS = {
  ACCEPT_TECHNICAL_PRICE: "Accept technical price",
  ADD_CANCELLATION_CLAUSE: "Add cancellation clause",
  BIND_ENDORSEMENT_QUOTE: "Bind endorsement",
  BIND_QUOTE: "Bind quote",
  CANCEL_ENDORSEMENT_MANUAL: "Cancel endorsement manual",
  CANCEL_ENDORSEMENT: "Cancel endorsement",
  CANCEL_AND_REPLACE: "Cancel and replace",
  CHECK_DEDUCTIBLES: "Check deductibles",
  CHECK_RATES: "Check rates",
  CREATE_CLAUSE: "Create clause",
  CREATE_DRAFT_SUBMISSION: "Create draft submission",
  CREATE_ENDORSEMENT_BESPOKE: "Create bespoke endorsement",
  CREATE_ENDORSEMENT_FINAL_ADJUSTMENT_PREMIUM: "Submit final adjustment premium",
  CREATE_ENDORSEMENT_MTA: "Create MTA endorsement",
  CREATE_MESSAGE: "Create message",
  CREATE_PRODUCT: "Create product",
  CREATE_RENEWAL: "Create renewal",
  CREATE_SCHEMA: "Create schema",
  CREATE_SUBMISSION: "Quote submission",
  DECLINE_TO_QUOTE_CONTRACT: "Decline to quote contract",
  DOWNLOAD_BDX: "Download bdx",
  EDIT_BESPOKE: "Edit bespoke",
  EDIT_CLAIM_FLAG: "Edit active claim flag",
  EDIT_CLAUSE: "Edit clause",
  EDIT_PREMIUM_PAID_FLAG: "Edit premium paid flag",
  GENERATE_BROKER_INVOICE: "Generate broker invoice",
  GENERATE_BROKER_JACKET: "Generate broker jacket",
  GENERATE_MANUAL_QUOTE: "Generate manual quote",
  PROCESS_REFERRAL: "Process referral",
  PROVIDE_COMMERCIAL_PRICE: "Provide commercial price",
  PROVIDE_ENDO_COMMERCIAL_PRICE: "Provide endo commercial price",
  REFER_CONTRACT: "Refer contract",
  REJECT_CONTRACT_NOT_PROGRESSED: "Reject contract not progressed",
  REJECT_CONTRACT_NOT_TAKEN_UP: "Reject contract not taken up",
  REMOVE_CLAUSE: "Remove clause",
  UPDATE_CONTRACT_COMMISSION: "Update contract commission",
  UPDATE_DRAFT_SUBMISSION: "Update draft submission",
  UPDATE_QUOTE_COMMISSION: "Update quote commission",
  UPDATE_SUBMISSION: "Update submission",
  UPLOAD_CONTRACT_ATTACHMENT: "Upload contract attachment",
  UPLOAD_MESSAGE_ATTACHMENT: "Upload message attachment",
  VIEW_AUDIT_LOG: "View audit log",
  VIEW_CLAIM_FLAG: "View active claim flag",
  VIEW_CLAUSE: "View clause",
  VIEW_CONCURRENT_USERS: "View concurrent users",
  VIEW_CONTRACT_ATTACHMENTS: "View contract attachments",
  VIEW_MESSAGES: "View messages",
  VIEW_PREMIUM_PAID_FLAG: "View premium paid flag",
  VIEW_QUOTE_COMMISSION: "View quote commission",
  VIEW_QUOTE_NET_PREMIUM: "View quote net premium",
  VIEW_REFERRAL_REASONS: "View referral reasons",
  VIEW_REFERRALS_TAB: "View referrals tab",
  VIEW_REMUNERATION: "View remuneration",
};

export default ACTIONS;
