// cargo type
// cargo category
// refer
// annual excluded
// single excluded
// temp control
export default [
  ["Abrasives & Fillers", 1, false, false, false, false],
  ["Acoustic guitars", 2, false, false, false, false],
  ["Action figures and toys", 1, false, false, false, false],
  ["Activity centres", 1, false, false, false, false],
  ["Adhesives & Tapes", 1, false, false, false, false],
  ["Adults fancy dress costumes", 1, false, false, false, false],
  ["Advent Calendars", 1, false, false, false, false],
  ["Aftershave", 2, false, false, false, false],
  ["Air beds", 1, false, false, false, false],
  ["Air Conditioners", 2, false, false, false, false],
  ["Air hockey tables", 2, false, false, false, false],
  ["Air pumps", 1, false, false, false, false],
  ["Air Purifiers", 2, false, false, false, false],
  ["Aircraft", null, false, true, true, false],
  ["Aircraft parts", null, false, true, true, false],
  ["Alarms & CCTV equipment", 2, false, false, false, false],
  [
    "Alcoholic Beverages in barrels excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Alcoholic Beverages in bottles excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Alcoholic Beverages in cans excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Alcoholic Beverages in cartons excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Alcoholic Beverages in drums excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Alcoholic Beverages in kegs excl Wines, Spirits, Port, Champagne, Liqueurs and Liquor",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Alcoholic Beverages other than Beer, Lager and Cider", null, false, true, true, false],
  ["Ammunition", null, false, true, true, false],
  ["Angle grinders", 1, false, false, false, false],
  ["Animal playsets and collectables", 1, false, false, false, false],
  ["Animals Live", null, false, true, true, false],
  ["Antiques not part of a Household Goods and Personal Effects consignment", null, false, true, true, false],
  ["Architectural models", null, false, true, true, false],
  ["Armchairs & Footstools", 1, false, false, false, false],
  ["Armchairs and chairs", 1, false, false, false, false],
  ["Arms and ammunition", null, false, true, true, false],
  ["Art and craft sets and accessories", 1, false, false, false, false],
  ["Artificial Christmas trees", 1, false, false, false, false],
  ["Artificial Flowers", 1, false, false, false, false],
  ["Artificial Grass", 1, false, false, false, false],
  ["Artificial plants and trees", 1, false, false, false, false],
  ["Artwork", null, false, true, true, false],
  ["Athletic supports", 1, false, false, false, false],
  ["Audio accessories", 2, false, false, false, false],
  ["Audio Equipment excl Portable Music Players", 2, false, false, false, false],
  ["Awnings", 1, false, false, false, false],
  ["Baby Accessories", 1, false, false, false, false],
  ["Baby and Christening gifts", 1, false, false, false, false],
  ["Baby and nursery", 1, false, false, false, false],
  ["Baby and pre-school toys", 1, false, false, false, false],
  ["Baby bath accessories", 1, false, false, false, false],
  ["Baby bath towels and robes", 1, false, false, false, false],
  ["Baby bath toys", 1, false, false, false, false],
  ["Baby baths", 1, false, false, false, false],
  ["Baby baths and accessories", 1, false, false, false, false],
  ["Baby bottle accessories", 1, false, false, false, false],
  ["Baby bottle starter kits and sets", 1, false, false, false, false],
  ["Baby bouncers and swings", 1, false, false, false, false],
  ["Baby car toys", 1, false, false, false, false],
  ["Baby carriers", 1, false, false, false, false],
  ["Baby changing", 1, false, false, false, false],
  ["Baby changing mats", 1, false, false, false, false],
  ["Baby Clothes", 1, false, false, false, false],
  ["Baby Coats and Jackets", 1, false, false, false, false],
  ["Baby cups, bowls and plates non breakable", 1, false, false, false, false],
  ["Baby dolls", 2, false, false, false, false],
  ["Baby feeding and accessories", 1, false, false, false, false],
  ["Baby feeding and food accessories", 1, false, false, false, false],
  ["Baby Food dried", 1, false, false, false, false],
  ["Baby food preparation", 1, false, false, false, false],
  ["Baby Jumpers and Cardigans", 1, false, false, false, false],
  ["Baby monitors and listening systems", 2, false, false, false, false],
  ["Baby musical toys", 2, false, false, false, false],
  ["Baby nappies", 1, false, false, false, false],
  ["Baby Pyjamas", 1, false, false, false, false],
  ["Baby rocking horses and ride-ons", 1, false, false, false, false],
  ["Baby seat supports", 1, false, false, false, false],
  ["Baby Shoes", 1, false, false, false, false],
  ["Baby sleeping bags", 1, false, false, false, false],
  ["Baby Socks", 1, false, false, false, false],
  ["Baby Swimwear", 1, false, false, false, false],
  ["Baby tableware sets non breakable", 1, false, false, false, false],
  ["Baby toilet seats", 1, false, false, false, false],
  ["Baby Tops", 1, false, false, false, false],
  ["Baby toys", 1, false, false, false, false],
  ["Baby trikes", 2, false, false, false, false],
  ["Baby Trousers", 1, false, false, false, false],
  ["Baby walkers", 1, false, false, false, false],
  ["Baby walkers, ride-ons and trikes", 2, false, false, false, false],
  ["Backpacks", 1, false, false, false, false],
  ["Badminton rackets", 2, false, false, false, false],
  ["Badminton sets", 2, false, false, false, false],
  ["Bags and travel luggage", 2, false, false, false, false],
  ["Bakeware breakable/ fragile", 4, false, false, false, false],
  ["Bakeware non breakable", 1, false, false, false, false],
  ["Ball games", 1, false, false, false, false],
  ["Ball pits", 1, false, false, false, false],
  ["Banjos", 2, false, false, false, false],
  ["Bar stools", 1, false, false, false, false],
  ["Barbecue accessories", 1, false, false, false, false],
  ["Barbecue Accessories & Covers", 1, false, false, false, false],
  ["Barbecue covers", 1, false, false, false, false],
  ["Barbecue fuel", 1, false, false, false, false],
  ["Barbecue smokers", 2, false, false, false, false],
  ["Barbecue tools", 1, false, false, false, false],
  ["Barbecues", 2, false, false, false, false],
  ["Barbecues and garden heating", 2, false, false, false, false],
  ["Barbells", 1, false, false, false, false],
  ["Bars shipped in bulk", null, false, true, true, false],
  ["Barware and bar accessories", 1, false, false, false, false],
  ["Baseball and softball equipment", 1, false, false, false, false],
  ["Basketball equipment", 1, false, false, false, false],
  ["Basketball hoops", 1, false, false, false, false],
  ["Basketballs", 1, false, false, false, false],
  ["Baskets and panniers", 1, false, false, false, false],
  ["Bass guitars", 2, false, false, false, false],
  ["Bath boards", 1, false, false, false, false],
  ["Bath lifts and steps", 1, false, false, false, false],
  ["Bath mats", 1, false, false, false, false],
  ["Bath panels", 1, false, false, false, false],
  ["Bath racks and bridges", 1, false, false, false, false],
  ["Bathroom accessories breakable/ Fragile", 4, false, false, false, false],
  ["Bathroom accessories non breakable", 1, false, false, false, false],
  ["Bathroom bins", 1, false, false, false, false],
  ["Bathroom Bins & Toilet Brushes", 1, false, false, false, false],
  ["Bathroom cabinets", 1, false, false, false, false],
  ["Bathroom Furniture", 1, false, false, false, false],
  ["Bathroom lights", 4, false, false, false, false],
  ["Bathroom Scales", 2, false, false, false, false],
  ["Bathroom sets", 1, false, false, false, false],
  ["Bathroom shelves and storage units breakable/ Fragile", 4, false, false, false, false],
  ["Bathroom shelves and storage units non breakable", 1, false, false, false, false],
  ["Bathroom sinks and basins", 4, false, false, false, false],
  ["Bathroom suites", 4, false, false, false, false],
  ["Bathroom Taps", 1, false, false, false, false],
  ["Batteries below 16 inches or 40cm maximum longest dimension", 2, false, false, false, false],
  ["Batteries larger than 16 inches or 40cm maximum longest dimension", null, false, true, true, false],
  ["Battery and pedal vehicle accessories", 1, false, false, false, false],
  ["Battery chargers", 2, false, false, false, false],
  ["Battery chargers and engine starters", 1, false, false, false, false],
  ["Battery powered vehicles Full Size", null, true, true, true, false],
  ["Battery powered vehicles Miniature", 2, false, false, false, false],
  ["BBQ Accessories & Covers", 1, false, false, false, false],
  ["Beach shelters", 1, false, false, false, false],
  ["Beams shipped in bulk", null, false, true, true, false],
  ["Bean Bags", 1, false, false, false, false],
  ["Beard and stubble trimmers", 2, false, false, false, false],
  ["Beauty and skincare sets - non branded", 1, false, false, false, false],
  ["Beauty tools and ustensiles", 1, false, false, false, false],
  ["Bed frames", 1, false, false, false, false],
  ["Bed rails and guards", 1, false, false, false, false],
  ["Bed sheets", 1, false, false, false, false],
  ["Bedding", 1, false, false, false, false],
  ["Bedding Sets", 1, false, false, false, false],
  ["Bedding, pillows and duvets", 1, false, false, false, false],
  ["Bedroom furniture", 2, false, false, false, false],
  ["Bedroom furniture sets", 2, false, false, false, false],
  ["Beds - assembled", 2, false, false, false, false],
  ["Bedside Tables", 2, false, false, false, false],
  ["Bedspreads", 1, false, false, false, false],
  ["Beer in barrels", 2, false, false, false, false],
  ["Beer in bottles", 2, false, false, false, false],
  ["Beer in cans", 2, false, false, false, false],
  ["Beer in cartons", 2, false, false, false, false],
  ["Beer in drums", 2, false, false, false, false],
  ["Beer in kegs", 2, false, false, false, false],
  ["Bench grinders", 2, false, false, false, false],
  ["Benches", 2, false, false, false, false],
  [
    "Beverages in Bottles excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Beverages in Cans excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Beverages in Cartons excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Beverages in Jars excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Beverages in Tins excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Bibs", 1, false, false, false, false],
  ["Bicycles", 2, false, false, false, false],
  ["Bike bags", 1, false, false, false, false],
  ["Bike covers and storage", 1, false, false, false, false],
  ["Bike lights", 2, false, false, false, false],
  ["Bike locks", 1, false, false, false, false],
  ["Bike parts and accessories", 1, false, false, false, false],
  ["Bike pumps and maintenance", 1, false, false, false, false],
  ["Bike racks", 1, false, false, false, false],
  ["Bike trailers and seats", 2, false, false, false, false],
  ["Bikes & Ride Ons", 2, false, false, false, false],
  ["Bikes and accessories", 2, false, false, false, false],
  ["Billets shipped in bulk", null, false, true, true, false],
  ["Bin Liners and bags", 1, false, false, false, false],
  ["Binoculars", 2, false, false, false, false],
  ["Bins & Recycling containers", 1, false, false, false, false],
  ["Bird Care items", 1, false, false, false, false],
  ["Bird feeders, baths and houses", 1, false, false, false, false],
  ["Bird Feeding Stations", 1, false, false, false, false],
  ["Bird food dried", 1, false, false, false, false],
  ["Bird Tables & Baths", 2, false, false, false, false],
  ["Bistro & Patio Sets", 2, false, false, false, false],
  ["Blankets and throws", 1, false, false, false, false],
  ["Blenders", 2, false, false, false, false],
  ["Blenders and smoothie makers", 2, false, false, false, false],
  ["Blind and curtain accessories", 1, false, false, false, false],
  ["Blinds", 1, false, false, false, false],
  ["Blood glucose monitors", 2, false, false, false, false],
  ["Blood plasma and products", null, false, true, true, false],
  ["Blood pressure monitors", 2, false, false, false, false],
  ["Blu-ray players", 2, false, false, false, false],
  ["BMX bikes", 2, false, false, false, false],
  ["Board games", 1, false, false, false, false],
  ["Board games, jigsaws and puzzles", 1, false, false, false, false],
  ["Boats new and in fully enclosed containers", 2, false, false, false, false],
  ["Boats new and in fully enclosed trucks", 2, false, false, false, false],
  ["Boats not new and in fully enclosed in containers", 2, false, false, false, false],
  ["Boats not new and in fully enclosed trucks", 2, false, false, false, false],
  ["Body jewellery", null, false, true, true, false],
  ["Body toning belts", 1, false, false, false, false],
  ["Body trimmers", 2, false, false, false, false],
  ["Bodysuits", 1, false, false, false, false],
  ["Bonds", null, false, true, true, false],
  ["Bookcases -Assembled", 2, false, false, false, false],
  ["Bookends", 1, false, false, false, false],
  ["Books", 1, false, false, false, false],
  ["Booster seats", 2, false, false, false, false],
  ["Bouncy castles", 1, false, false, false, false],
  ["Bouncy castles and inflatable toys", 1, false, false, false, false],
  ["Bowls, pottery, ceramic or similar", 4, false, false, false, false],
  ["Boxing and martial arts equipment", 2, false, false, false, false],
  ["Boys Accessories", 1, false, false, false, false],
  ["Boys Coats and Jackets", 1, false, false, false, false],
  ["Boys Jeans and Trousers", 1, false, false, false, false],
  ["Boys Jumpers and Cardigans", 1, false, false, false, false],
  ["Boys Pyjamas and Nightwear", 1, false, false, false, false],
  ["Boys School Shoes", 1, false, false, false, false],
  ["Boys School Uniform", 1, false, false, false, false],
  ["Boys Shirts", 1, false, false, false, false],
  ["Boys Shoes and Boots", 1, false, false, false, false],
  ["Boys Shorts", 1, false, false, false, false],
  ["Boys Sportswear", 1, false, false, false, false],
  ["Boys Swimwear", 1, false, false, false, false],
  ["Boys T-shirts", 1, false, false, false, false],
  ["Boys Underwear and socks", 1, false, false, false, false],
  ["Brass instruments", 2, false, false, false, false],
  ["Bread Bins", 1, false, false, false, false],
  ["Bread Makers", 2, false, false, false, false],
  ["Breast feeding accessories", 2, false, false, false, false],
  ["Breast pumps", 2, false, false, false, false],
  ["Breast pumps and accessories", 2, false, false, false, false],
  ["Briefcases", 2, false, false, false, false],
  ["Brush heads", 1, false, false, false, false],
  ["Bubble machines", 2, false, false, false, false],
  ["Built In Ovens", 2, false, false, false, false],
  ["Built-in integrated appliances", 2, false, false, false, false],
  ["Built-in microwaves", 2, false, false, false, false],
  ["Built-in ovens", 2, false, false, false, false],
  ["Bulbs packed in cartons", 4, false, false, false, false],
  ["Bulbs packed in cases", 4, false, false, false, false],
  ["Bulbs packed in waterproof crates", 4, false, false, false, false],
  ["Bulk car contracts new vehicles", 6, false, true, false, false],
  ["Bulk car contracts used vehicles", 6, false, true, false, false],
  ["Bullion", null, false, true, true, false],
  ["Burglar alarms", 2, false, false, false, false],
  ["Cabin luggage", 2, false, false, false, false],
  ["Cabinets", 2, false, false, false, false],
  ["Cable detectors", 2, false, false, false, false],
  ["Cafetieres", 2, false, false, false, false],
  ["Cake stands", 1, false, false, false, false],
  ["Cake Tins & Containers", 1, false, false, false, false],
  ["Calculators", 2, false, false, false, false],
  ["Camcorder accessories", 1, false, false, false, false],
  ["Camcorder cases and bags", 1, false, false, false, false],
  ["Camcorders", 2, false, false, false, false],
  ["Camera bags and cases", 1, false, false, false, false],
  ["Camera batteries", 2, false, false, false, false],
  ["Camera batteries and chargers", 2, false, false, false, false],
  ["Camera battery chargers", 2, false, false, false, false],
  ["Camera filters and flashes", 2, false, false, false, false],
  ["Camera lenses", 4, false, false, false, false],
  ["Camera lenses and lens accessories", 4, false, false, false, false],
  ["Cameras", 2, false, false, false, false],
  ["Cameras and camcorders", 2, false, false, false, false],
  ["Camping Accessories", 1, false, false, false, false],
  ["Camping beds", 1, false, false, false, false],
  ["Camping chairs", 1, false, false, false, false],
  ["Camping Equipment", 1, false, false, false, false],
  ["Camping furniture", 1, false, false, false, false],
  ["Camping lights", 2, false, false, false, false],
  ["Camping mats", 1, false, false, false, false],
  ["Camping rucksacks", 1, false, false, false, false],
  ["Camping stoves and cookers", 2, false, false, false, false],
  ["Camping tables", 1, false, false, false, false],
  ["Candle ornaments and vases (non fragile)", 2, false, false, false, false],
  ["Candles", 1, false, false, false, false],
  ["Car - full size", 6, false, true, false, false],
  ["Car accessories", 2, false, false, false, false],
  ["Car accessories and care", 2, false, false, false, false],
  ["Car booster seats", 2, false, false, false, false],
  ["Car Care", 2, false, false, false, false],
  ["Car cleaning", 1, false, false, false, false],
  ["Car covers", 1, false, false, false, false],
  ["Car mats", 1, false, false, false, false],
  ["Car seat covers", 1, false, false, false, false],
  ["Car seat travel accessories", 1, false, false, false, false],
  ["Car seats", 2, false, false, false, false],
  ["Car seats, booster seats and travel accessories", 2, false, false, false, false],
  ["Car security devices", 1, false, false, false, false],
  ["Car tools", 1, false, false, false, false],
  ["Car tyre inflators and air compressors", 2, false, false, false, false],
  ["Caravans shipped in containers", 6, false, true, false, false],
  ["Caravans shipped underdeck", 6, false, true, false, false],
  ["Carbon monoxide detectors", 2, false, false, false, false],
  ["Cardboard boxes", 1, false, false, false, false],
  ["Cargo shipped in bulk not in containers", null, false, true, true, false],
  ["Carpet cleaner and steam cleaner accessories", 1, false, false, false, false],
  ["Carpet cleaners", 2, false, false, false, false],
  ["Carpet sweepers", 2, false, false, false, false],
  ["Carrycots", 1, false, false, false, false],
  ["Cars & Motorbikes - Commercial", 6, false, true, false, false],
  ["Cars & Motorbikes - Private", 6, false, true, false, false],
  ["Carts (Hand)", 1, false, false, false, false],
  ["Cases and bags", 1, false, false, false, false],
  ["Cash", null, false, true, true, false],
  ["Casseroles & Stockpots", 2, false, false, false, false],
  ["Cat beds", 1, false, false, false, false],
  ["Cat bowls and feeders", 1, false, false, false, false],
  ["Cat carriers and boxes", 1, false, false, false, false],
  ["Cat food", 1, false, false, false, false],
  ["Cat furniture and scratchers", 1, false, false, false, false],
  ["Cat health products", 1, false, false, false, false],
  ["Cat litter and litter trays", 1, false, false, false, false],
  ["Cat toys", 1, false, false, false, false],
  ["CCTV cameras", 2, false, false, false, false],
  ["CD and DVD rewriters", 3, false, false, false, false],
  ["CD and DVD storage", 1, false, false, false, false],
  ["CDs and Vinyl", 2, false, false, false, false],
  ["CDs Blank", 1, false, false, false, false],
  ["CDs, DVDs and blu-rays", 2, false, false, false, false],
  ["Ceiling fans", 2, false, false, false, false],
  ["Ceiling lights", 4, false, false, false, false],
  ["Cellphones", null, false, true, true, false],
  ["Chainsaws", 2, false, false, false, false],
  ["Chairs", 2, false, false, false, false],
  ["Chairs & Benches", 2, false, false, false, false],
  ["Champagne", null, false, true, true, false],
  ["Changing bags", 1, false, false, false, false],
  ["Changing units and changing tables", 2, false, false, false, false],
  ["Character gifts", 2, false, false, false, false],
  ["Charcoal Barbecues", 2, false, false, false, false],
  ["Chemicals in Bags", 1, false, false, false, false],
  ["Chemicals in Bottles", 1, false, false, false, false],
  ["Chemicals in Boxes", 1, false, false, false, false],
  ["Chemicals in Cans", 1, false, false, false, false],
  ["Chemicals in Cartons", 1, false, false, false, false],
  ["Chemicals in Drums", 1, false, false, false, false],
  ["Chemicals in Paper Sachets", 1, false, false, false, false],
  ["Chemicals in Sacks", 1, false, false, false, false],
  ["Chemicals in Tins", 1, false, false, false, false],
  ["Chest of Drawers", 2, false, false, false, false],
  ["Children's furniture", 2, false, false, false, false],
  ["Children's jewellery", null, false, true, true, false],
  ["Childrens toys", 2, false, false, false, false],
  ["Chimneys & Fire Pits", 2, false, false, false, false],
  ["Chimneys and wood burners", 2, false, false, false, false],
  ["China", 4, false, false, false, false],
  ["Chinaware packed in cartons", 4, false, false, false, false],
  ["Chinaware packed in cases", 4, false, false, false, false],
  ["Chinaware packed in waterproof crates", 4, false, false, false, false],
  ["Chinese Groundnuts", null, false, true, true, false],
  ["Choppers", 2, false, false, false, false],
  ["Chopping Boards", 1, false, false, false, false],
  ["Christening jewellery", null, false, true, true, false],
  ["Christmas lights", 2, false, false, false, false],
  ["Christmas table and room decorations", 2, false, false, false, false],
  ["Christmas tree decorations", 2, false, false, false, false],
  ["Christmas tree stands and skirts", 1, false, false, false, false],
  ["Christmas trees, lights and decorations", 2, false, false, false, false],
  ["Christmas wreaths and garlands", 2, false, false, false, false],
  ["Chromebooks", null, false, true, true, false],
  ["Cider in barrels", 2, false, false, false, false],
  ["Cider in Bottles", 2, false, false, false, false],
  ["Cider in cans", 2, false, false, false, false],
  ["Cider in cartons", 2, false, false, false, false],
  ["Cider in drums", 2, false, false, false, false],
  ["Cider in kegs", 2, false, false, false, false],
  ["Cigarettes", null, false, true, true, false],
  ["Cigars", null, false, true, true, false],
  ["Clamps", 1, false, false, false, false],
  ["Clarinets", 2, false, false, false, false],
  ["Classic Cars", 6, false, true, false, false],
  ["Cleaning products", 1, false, false, false, false],
  ["Climbing frames", 1, false, false, false, false],
  ["Climbing Frames & Sand Pits", 1, false, false, false, false],
  ["Clock radios", 2, false, false, false, false],
  ["Clocks excluding antiques", 2, false, false, false, false],
  ["Cloth", 1, false, false, false, false],
  ["Clothes airers", 1, false, false, false, false],
  ["Clothes hangers", 1, false, false, false, false],
  ["Clothing and Shoes Branded excl Furs, Fur Garments, Designerwear and Leatherwear", 2, false, false, false, false],
  [
    "Clothing and shoes excl Furs, Fur Garments, Branded Goods, Fashionwear, Designerwear, Sportswear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Clothing and shoes Furs, Fur Garments, Designerwear and Leatherwear", null, false, true, true, false],
  ["Co axial cables", 1, false, false, false, false],
  ["Coat hooks and stands", 1, false, false, false, false],
  ["Coats, Jackets and Blazers", 2, false, false, false, false],
  ["Coffee grinders", 2, false, false, false, false],
  ["Coffee machines", 2, false, false, false, false],
  ["Coffee Makers", 2, false, false, false, false],
  ["Coffee tables", 2, false, false, false, false],
  ["Coffee tables, sideboards and display units", 2, false, false, false, false],
  ["Coils shipped in bulk", null, false, true, true, false],
  ["Coins", null, false, true, true, false],
  ["Colanders", 1, false, false, false, false],
  ["Commodes", 1, false, false, false, false],
  ["Commodities", 2, false, false, false, false],
  ["Compact digital cameras", 2, false, false, false, false],
  ["Compost", 1, false, false, false, false],
  ["Composters", 1, false, false, false, false],
  ["Compressors and pumps", 1, false, false, false, false],
  ["Computer Accessories", 3, false, false, false, false],
  ["Computer cables", 1, false, false, false, false],
  ["Computer Chips, memory board and similar high value components", null, false, true, true, false],
  ["Computer Components (high value)", null, false, true, true, false],
  ["Computer Games", null, false, true, true, false],
  ["Computers, PC Hardware", 3, false, false, false, false],
  ["Confectionery excl temperature controlled", 2, false, false, false, false],
  ["Console tables", 2, false, false, false, false],
  ["Construction toys", 1, false, false, false, false],
  ["Containers", null, false, true, true, false],
  ["Cooker hoods", 2, false, false, false, false],
  ["Cookers", 2, false, false, false, false],
  ["Cookers packed in cartons", 2, false, false, false, false],
  ["Cookers packed in cases", 2, false, false, false, false],
  ["Cookers packed in waterproof crates", 2, false, false, false, false],
  ["Cooking, dining and kitchen equipment", 2, false, false, false, false],
  ["Cookware", 2, false, false, false, false],
  ["Cool Bags & Boxes", 2, false, false, false, false],
  ["Cool boxes", 2, false, false, false, false],
  ["Cordless vacuum cleaners", 2, false, false, false, false],
  ["Cosmetics", 2, false, false, false, false],
  ["Cot and bed bumpers", 1, false, false, false, false],
  ["Cot and cot bed mattresses", 1, false, false, false, false],
  ["Cot bed organiser", 1, false, false, false, false],
  ["Cot toys, baby mobiles and nightlights", 2, false, false, false, false],
  ["Cots, cribs and cot beds", 1, false, false, false, false],
  ["Craft sets and accessories", 1, false, false, false, false],
  ["Creative and science toys", 1, false, false, false, false],
  ["Credit Cards blanks", null, false, true, true, false],
  ["Cricket equipment", 2, false, false, false, false],
  ["Cross trainers", 2, false, false, false, false],
  ["Crystalware packed in cartons", 4, false, false, false, false],
  ["Crystalware packed in cases", 4, false, false, false, false],
  ["Crystalware packed in waterproof crates", 4, false, false, false, false],
  ["Cufflinks", null, false, true, true, false],
  ["Cups and mugs Breackable", 4, false, false, false, false],
  ["Curtain poles and tracks", 1, false, false, false, false],
  ["Curtains", 1, false, false, false, false],
  ["Cushions", 1, false, false, false, false],
  ["Cutlery (non precious metal)", 2, false, false, false, false],
  ["Cutlery (precious metal)", null, false, true, true, false],
  ["Cutlery trays", 1, false, false, false, false],
  ["Cutting & Pruning", 1, false, false, false, false],
  ["Cycle helmets", 2, false, false, false, false],
  ["Cycling gloves", 2, false, false, false, false],
  ["Cylinder", 2, false, false, false, false],
  ["Dartboards and dart cabinets", 2, false, false, false, false],
  ["Darts", 2, false, false, false, false],
  ["Darts accessories", 2, false, false, false, false],
  ["Dash cams", 2, false, false, false, false],
  ["Data Storage & USBs", 2, false, false, false, false],
  ["Day beds", 1, false, false, false, false],
  ["Decanters", 4, false, false, false, false],
  ["Decking", 1, false, false, false, false],
  ["Decorative arches", 1, false, false, false, false],
  ["Decorative boxes and baskets", 1, false, false, false, false],
  ["Decorative Stone & Gravel", 1, false, false, false, false],
  ["Deeds", null, false, true, true, false],
  ["Deep Fat Fryers", 2, false, false, false, false],
  ["Dehumidifiers", 2, false, false, false, false],
  ["Dehumidifiers & Humidifiers", 2, false, false, false, false],
  ["Dental care", 1, false, false, false, false],
  ["Dental flossers", 1, false, false, false, false],
  ["Descaling items", 1, false, false, false, false],
  ["Designated Explosives", null, false, true, true, false],
  ["Designer and prototype models", null, false, true, true, false],
  ["Designer clothing", null, false, true, true, false],
  ["Designerwear", null, false, true, true, false],
  ["Desk lamps", 2, false, false, false, false],
  ["Desks", 2, false, false, false, false],
  ["Desktop computer components excluding chips / motherboards", 3, false, false, false, false],
  ["Desktop computers and all in ones", 3, false, false, false, false],
  [
    "Desktop Computers excl Laptop, Notebook, Handheld Computers, Ipads, Tablet Computers, Video Game Consoles, Computer Chips, Memory boards, Cards and Similar High value components",
    3,
    false,
    false,
    false,
    false,
  ],
  [
    "Desktop Servers excl computer chips, memory boards, cards & similar and high value components",
    3,
    false,
    false,
    false,
    false,
  ],
  ["Detergents & Washing Powder", 1, false, false, false, false],
  ["Diaries and journals", 1, false, false, false, false],
  ["Dictation machines", 2, false, false, false, false],
  ["Digging & Planting tools", 1, false, false, false, false],
  ["Digital cameras", 2, false, false, false, false],
  ["Digital pain relief", 2, false, false, false, false],
  ["Digital photo frames", 2, false, false, false, false],
  ["Digital photo printer paper and ink", 2, false, false, false, false],
  ["Digital TV recorders", 2, false, false, false, false],
  ["Dining chairs", 2, false, false, false, false],
  ["Dining Furniture", 2, false, false, false, false],
  ["Dining Room Furniture", 2, false, false, false, false],
  ["Dining Sets", 1, false, false, false, false],
  ["Dining table sets", 2, false, false, false, false],
  ["Dining tables", 2, false, false, false, false],
  ["Dining tables and chairs", 2, false, false, false, false],
  ["Dinner Sets Breakable", 4, false, false, false, false],
  ["Dinnerware and dinner sets", 4, false, false, false, false],
  ["Discovery, science and STEM toys", 2, false, false, false, false],
  ["Dish racks", 1, false, false, false, false],
  ["Dishwashers", 2, false, false, false, false],
  ["Dishwashers packed in cartons", 2, false, false, false, false],
  ["Dishwashers packed in cases", 2, false, false, false, false],
  ["Dishwashers packed in waterproof crates", 2, false, false, false, false],
  ["Display cabinets", 2, false, false, false, false],
  ["Disposable cameras", 2, false, false, false, false],
  ["Divan beds", 2, false, false, false, false],
  ["DIY power tool accessories", 2, false, false, false, false],
  ["DIY power tools", 2, false, false, false, false],
  ["DIY tools and kits", 2, false, false, false, false],
  ["DIY tools and power tools", 2, false, false, false, false],
  ["DJ equipment", 2, false, false, false, false],
  ["Dog beds", 1, false, false, false, false],
  ["Dog bowls and feeders", 1, false, false, false, false],
  ["Dog clothing and accessories", 1, false, false, false, false],
  ["Dog collars and leads", 1, false, false, false, false],
  ["Dog food", 1, false, false, false, false],
  ["Dog gates", 1, false, false, false, false],
  ["Dog grooming", 1, false, false, false, false],
  ["Dog healthcare", 1, false, false, false, false],
  ["Dog kennels and crates", 1, false, false, false, false],
  ["Dog toys", 1, false, false, false, false],
  ["Dog training", 1, false, false, false, false],
  ["Dog travel and car products", 1, false, false, false, false],
  ["Doll accessories", 1, false, false, false, false],
  ["Doll cars and vehicles", 1, false, false, false, false],
  ["Doll clothes", 1, false, false, false, false],
  ["Doll playsets", 1, false, false, false, false],
  ["Doll prams and pushchairs", 1, false, false, false, false],
  ["Dolls", 1, false, false, false, false],
  ["Dolls and playsets", 1, false, false, false, false],
  ["Dolls furniture", 1, false, false, false, false],
  ["Dolls houses", 1, false, false, false, false],
  [
    "Domestic Electrical Appliances excl Plasma and LCD televisions/screens, Video Game Consoles, Ipads, Tablet Computers, Portable Music Players, Mobile Telephones and Sim Cards",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Domestic Glass packed in cartons", 4, false, false, false, false],
  ["Domestic Glass packed in cases", 4, false, false, false, false],
  ["Domestic Glass packed in waterproof crates", 4, false, false, false, false],
  ["Door & Window Security", 1, false, false, false, false],
  ["Door Chimes", 1, false, false, false, false],
  ["Door furniture", 1, false, false, false, false],
  ["Door Mats", 1, false, false, false, false],
  ["Doorbells", 1, false, false, false, false],
  ["Doors", 1, false, false, false, false],
  ["Doors and door furniture", 1, false, false, false, false],
  ["Dough and modelling toys", 1, false, false, false, false],
  ["Drain Unblockers", 1, false, false, false, false],
  ["Dremels", 2, false, false, false, false],
  ["Dress rings", null, false, true, true, false],
  ["Dressing Tables", 2, false, false, false, false],
  ["Dried Beans in Bags", 1, false, false, false, false],
  ["Dried Beans in Sacks", 1, false, false, false, false],
  ["Dried Foodstuffs in Bags", 1, false, false, false, false],
  ["Dried Foodstuffs in Sacks", 1, false, false, false, false],
  ["Dried Herbs and Spices in Bags", 1, false, false, false, false],
  ["Dried Herbs and Spices in Sacks", 1, false, false, false, false],
  ["Dried Pulses in Bags", 1, false, false, false, false],
  ["Dried Pulses in Sacks", 1, false, false, false, false],
  ["Dried Rice in Bags", 1, false, false, false, false],
  ["Dried Rice in Sacks", 1, false, false, false, false],
  ["Drilling and Digging Equipment", 1, false, false, false, false],
  ["Drills", 2, false, false, false, false],
  ["Drinking glasses and glassware", 4, false, false, false, false],
  ["Drinks dispensers", 1, false, false, false, false],
  [
    "Drinks in Bottles excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Drinks in Cans or Tins excl Alcoholic Beverages such as Beer, Lager, Ciders, Wines, Spirits, Ports, Champagne, Liqueurs and Liquor",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Drones", 2, false, false, false, false],
  ["Drum kits", 2, false, false, false, false],
  ["Drums and percussion", 2, false, false, false, false],
  ["DSLR cameras", 2, false, false, false, false],
  ["Dumbbells", 1, false, false, false, false],
  ["Dustpans and brushes", 1, false, false, false, false],
  ["Duvet cover sets", 1, false, false, false, false],
  ["Duvets", 1, false, false, false, false],
  ["DVD players and recorders", 2, false, false, false, false],
  ["DVD players, blu-ray players and home cinema", 2, false, false, false, false],
  ["DVD Recorders", 2, false, false, false, false],
  ["DVDs and Blu-ray", 2, false, false, false, false],
  ["DVDs Blank", 1, false, false, false, false],
  ["Early learning toys", 1, false, false, false, false],
  ["Earthenware packed in cartons", 4, false, false, false, false],
  ["Earthenware packed in cases", 4, false, false, false, false],
  ["Earthenware packed in waterproof crates", 4, false, false, false, false],
  ["E-cigarettes and vape kits", null, false, true, true, false],
  ["Educational electronic toys", 2, false, false, false, false],
  ["Electric blankets", 1, false, false, false, false],
  ["Electric diffusers", 1, false, false, false, false],
  ["Electric fires", 1, false, false, false, false],
  ["Electric guitars", 2, false, false, false, false],
  ["Electric hobs", 2, false, false, false, false],
  ["Electric knives and food slicers", 1, false, false, false, false],
  ["Electric Screwdrivers", 2, false, false, false, false],
  ["Electric steamers", 2, false, false, false, false],
  ["Electric toothbrushes", 2, false, false, false, false],
  ["Electrical Accessories", 2, false, false, false, false],
  ["Electrical supplies", 1, false, false, false, false],
  ["Electronic books", 2, false, false, false, false],
  ["Electronic books and accessories", 2, false, false, false, false],
  ["Electronic Cigarettes", null, false, true, true, false],
  ["Electronic dictionaries and calculators", 2, false, false, false, false],
  ["Electronic games such as video games etc.", null, false, true, true, false],
  ["Electronic learning toys", 2, false, false, false, false],
  ["Electronic music equipment", 2, false, false, false, false],
  ["Electronic reading systems", 2, false, false, false, false],
  ["Electronic reading systems books", null, false, true, true, false],
  ["Electronic toys and games excluding Games Consoles and similar", 2, false, false, false, false],
  ["Electronic Toys excluding Games Consoles and similar", 2, false, false, false, false],
  ["E-liquid, vape juice and pods", null, false, true, true, false],
  ["Enamelware packed in cartons", 4, false, false, false, false],
  ["Enamelware packed in cases", 4, false, false, false, false],
  ["Enamelware packed in waterproof crates", 4, false, false, false, false],
  ["Energy and pre-workout supplements", 1, false, false, false, false],
  ["Engagement rings", null, false, true, true, false],
  ["Entertainment units", 2, false, false, false, false],
  ["Epilators", 2, false, false, false, false],
  ["Eternity rings", null, false, true, true, false],
  ["Exercise and yoga mats", 1, false, false, false, false],
  ["Exercise bikes", 2, false, false, false, false],
  ["Extension Leads & Cables", 1, false, false, false, false],
  ["Extension leads and cable reels", 1, false, false, false, false],
  ["External hard drives", 3, false, false, false, false],
  ["Extractor fans", 2, false, false, false, false],
  ["Eyewear excl Fashion and Designer wear", 2, false, false, false, false],
  ["Fabric Dyes", 1, false, false, false, false],
  ["Fabrics", 1, false, false, false, false],
  ["Face Goggles (PPE)", null, false, true, true, false],
  ["Face Masks (PPE)", null, false, true, true, false],
  ["Face Sheilds (PPE)", null, false, true, true, false],
  ["Face Visors (PPE)", null, false, true, true, false],
  ["Fairy lights and string lights", 2, false, false, false, false],
  ["Fancy dress", 1, false, false, false, false],
  ["Fancy dress accessories", 1, false, false, false, false],
  ["Fans", 1, false, false, false, false],
  ["Fashion and cocktail rings", null, false, true, true, false],
  ["Fashionwear and Branded Clothes", 2, false, false, false, false],
  ["Fencing & Trellis", 1, false, false, false, false],
  ["Fencing, Decking & Landscaping", 1, false, false, false, false],
  ["Fertilizer", 1, false, false, false, false],
  ["Filing Cabinets", 1, false, false, false, false],
  ["Filing cabinets and office storage", 1, false, false, false, false],
  ["Fine Art", null, false, true, true, false],
  ["Finished Cotton", 1, false, false, false, false],
  ["Finished Wool", 1, false, false, false, false],
  ["Fire Extinguishers", 2, false, false, false, false],
  ["Fire extinguishers and blankets", 2, false, false, false, false],
  ["Fire pits", 1, false, false, false, false],
  ["Fireguards", 1, false, false, false, false],
  ["Fireplace Surrounds", 2, false, false, false, false],
  ["Fireplaces and fires", 2, false, false, false, false],
  ["Fish catch", null, false, true, true, false],
  ["Fish Live", null, false, true, true, false],
  ["Fish tanks and stands", 4, false, false, false, false],
  ["Fishing accessories", 1, false, false, false, false],
  ["Fishing Equipment excl arms and ammunition", 2, false, false, false, false],
  ["Fishing lines and sets", 1, false, false, false, false],
  ["Fishing reels, holders and rests", 1, false, false, false, false],
  ["Fishing rods and poles", 1, false, false, false, false],
  ["Fishmeal", null, false, true, true, false],
  ["Fitness accessories", 1, false, false, false, false],
  ["Fitness and activity trackers", 2, false, false, false, false],
  ["Fitness equipment", 1, false, false, false, false],
  ["Fitness technology", 2, false, false, false, false],
  ["Fitness technology accessories", 2, false, false, false, false],
  ["Fitted bedroom furniture", 2, false, false, false, false],
  ["Fitted kitchens", 2, false, false, false, false],
  ["Flash accessories", 2, false, false, false, false],
  ["Flash cards", null, false, true, true, false],
  ["Flasks", 4, false, false, false, false],
  ["Flasks & Travel Mugs", 4, false, false, false, false],
  ["Flat packed Furniture excluding Glass", 1, false, false, false, false],
  ["Flat packed Furniture with Glass", 2, false, false, false, false],
  ["Floor guards", 1, false, false, false, false],
  ["Floor Lamps", 4, false, false, false, false],
  ["Floor Tiles", 1, false, false, false, false],
  ["Floorcare", 1, false, false, false, false],
  ["Flowers", null, false, true, true, false],
  ["Flowers & Plants", null, false, true, true, false],
  ["Flutes", 2, false, false, false, false],
  ["Folding trolleys and hand trucks", 1, false, false, false, false],
  ["Food & Beverage Containers", 1, false, false, false, false],
  ["Food and drink", 2, false, false, false, false],
  ["Food Mixers", 1, false, false, false, false],
  ["Food Preparation", 1, false, false, false, false],
  ["Food Processors", 2, false, false, false, false],
  ["Food processors and choppers", 2, false, false, false, false],
  ["Food storage", 1, false, false, false, false],
  ["Foodstuffs in Bottles", 1, false, false, false, false],
  ["Foodstuffs in Cans", 1, false, false, false, false],
  ["Foodstuffs in Cartons", 1, false, false, false, false],
  ["Foodstuffs in Jars", 1, false, false, false, false],
  ["Foodstuffs in Tins", 1, false, false, false, false],
  ["Foot care", 1, false, false, false, false],
  ["Foot massagers", 2, false, false, false, false],
  ["Foot spas", 2, false, false, false, false],
  ["Football accessories", 2, false, false, false, false],
  ["Football goals", 2, false, false, false, false],
  ["Football jewellery", null, false, true, true, false],
  ["Football pumps", 2, false, false, false, false],
  ["Football shin pads", 2, false, false, false, false],
  ["Football tables", 2, false, false, false, false],
  ["Footballs", 2, false, false, false, false],
  ["Footmuffs, nests and liners", 1, false, false, false, false],
  ["Footstools", 1, false, false, false, false],
  ["Forklift Trucks", 1, false, false, false, false],
  ["Formal Wear", 2, false, false, false, false],
  ["Fragile goods packed in cartons", 4, false, false, false, false],
  ["Fragile goods packed in cases", 4, false, false, false, false],
  ["Fragile goods packed in waterproof crates", 4, false, false, false, false],
  ["Fragrance", 2, false, false, false, false],
  ["Freestanding cookers", 2, false, false, false, false],
  ["Freeview and Freesat", 2, false, false, false, false],
  ["Freezers", 2, false, false, false, false],
  ["Fresh foods (other than deep frozen)", null, false, true, true, false],
  ["Fresh fruit (other than deep frozen)", null, false, true, true, false],
  ["Fresh meat (other than deep frozen)", null, false, true, true, false],
  ["Fresh Vegetables (other than deep frozen)", null, false, true, true, false],
  ["Fridge Freezers", 2, false, false, false, false],
  ["Fridges", 2, false, false, false, false],
  [
    "Frozen Food transported in ISO Reefer Containers excl hanging meat refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Food transported in Refrigerated Trucks excl hanging meat refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Fruit transported in ISO Reefer Containers refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Fruit transported in Refrigerated Trucks refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Meat transported in ISO Reefer Containers excl hanging meat refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Meat transported in Refrigerated Trucks excl hanging meat refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Vegetables transported in ISO Reefer Containers refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Frozen Vegetables transported in Refrigerated Trucks refrigerated to -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit or below",
    7,
    false,
    false,
    false,
    true,
  ],
  ["Fryers", 2, false, false, false, false],
  ["Frying Pans", 1, false, false, false, false],
  ["Frying pans and skillets", 1, false, false, false, false],
  ["Furniture Covers", 1, false, false, false, false],
  ["Furniture pads", 1, false, false, false, false],
  ["Furs", null, false, true, true, false],
  ["Gadget and novelty gifts", 2, false, false, false, false],
  ["Games", 2, false, false, false, false],
  ["Games and puzzles", 1, false, false, false, false],
  ["Games Consoles", null, false, true, true, false],
  ["Games Tables", 1, false, false, false, false],
  ["Gaming chairs", 2, false, false, false, false],
  ["Gaming guides", 1, false, false, false, false],
  ["Gaming headsets", 2, false, false, false, false],
  ["Gaming laptops", null, false, true, true, false],
  ["Gaming PCs", 3, false, false, false, false],
  ["Garage storage and shelving", 1, false, false, false, false],
  ["Garden benches and arbours", 1, false, false, false, false],
  ["Garden chairs and sun loungers", 1, false, false, false, false],
  ["Garden clocks", 2, false, false, false, false],
  ["Garden Decor", 1, false, false, false, false],
  ["Garden Decoration", 1, false, false, false, false],
  ["Garden decoration and landscaping", 1, false, false, false, false],
  ["Garden design accessories", 1, false, false, false, false],
  ["Garden Edging & Borders", 1, false, false, false, false],
  ["Garden fencing and trellis", 1, false, false, false, false],
  ["Garden forks, spades and rakes", 1, false, false, false, false],
  ["Garden furniture", 2, false, false, false, false],
  ["Garden furniture covers and cushions", 1, false, false, false, false],
  ["Garden Games", 1, false, false, false, false],
  ["Garden Gloves", 1, false, false, false, false],
  ["Garden hand tools non electric", 1, false, false, false, false],
  ["Garden heating", 2, false, false, false, false],
  ["Garden heating accessories", 1, false, false, false, false],
  ["Garden lamp posts", 1, false, false, false, false],
  ["Garden Lighting", 4, false, false, false, false],
  ["Garden mirrors", 4, false, false, false, false],
  ["Garden ornaments and accessories", 4, false, false, false, false],
  ["Garden ornaments and lanterns", 4, false, false, false, false],
  ["Garden parasols and bases", 1, false, false, false, false],
  ["Garden pots and planters", 2, false, false, false, false],
  ["Garden power tool accessories", 2, false, false, false, false],
  ["Garden Protection", 1, false, false, false, false],
  ["Garden Shredders", 2, false, false, false, false],
  ["Garden Storage", 1, false, false, false, false],
  ["Garden storage boxes and cupboards", 1, false, false, false, false],
  ["Garden tables", 1, false, false, false, false],
  ["Garden tool sets", 1, false, false, false, false],
  ["Garden water features", 2, false, false, false, false],
  ["Gardening equipment", 1, false, false, false, false],
  ["Gardening tools", 1, false, false, false, false],
  ["Garments excl Fashion and Designer wear", 2, false, false, false, false],
  ["Gas Barbecues", 2, false, false, false, false],
  ["Gas hobs", 2, false, false, false, false],
  ["Gazebos", 1, false, false, false, false],
  ["Gazebos, marquees and awnings", 1, false, false, false, false],
  ["Generators", 1, false, false, false, false],
  ["Gift Cards", null, false, true, true, false],
  ["Giftware", 2, false, false, false, false],
  ["Girls Branded clothes - excluding Furs, Fur Garments, Designerwear and Leatherwear", 2, false, false, false, false],
  [
    "Girls Clothes - excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Coats and Jackets - excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Dresses and Outfits - excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Jeans and Trousers- excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Jumpers and Cardigans -excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Pyjamas and Nightwear - excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls School Shoes -excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Girls School Uniform", 1, false, false, false, false],
  [
    "Girls Shoes and Boots- excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  [
    "Girls Shorts and Skirts -excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Girls Swimwear", 1, false, false, false, false],
  [
    "Girls Tops and T-Shirts -excluding Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Girls Underwear, Socks and Tights", 1, false, false, false, false],
  ["Glasses and sun Glasses excluding designerwear", 4, false, false, false, false],
  ["Glassware packed in cartons", 4, false, false, false, false],
  ["Glassware packed in cases", 4, false, false, false, false],
  ["Glassware packed in waterproof crates", 4, false, false, false, false],
  ["Glue and fixing products", 1, false, false, false, false],
  ["Glue Guns", 2, false, false, false, false],
  ["Go karts", 2, false, false, false, false],
  ["Goalkeeper gloves", 1, false, false, false, false],
  ["Goggles (non PPE)", 1, false, false, false, false],
  ["Goggles (PPE)", null, false, true, true, false],
  ["Golf accessories", 1, false, false, false, false],
  ["Golf bags", 1, false, false, false, false],
  ["Golf balls", 1, false, false, false, false],
  ["Golf clubs", 1, false, false, false, false],
  ["Golf training aids", 1, false, false, false, false],
  ["Golf trolleys and carts", 1, false, false, false, false],
  ["Grab rails", 1, false, false, false, false],
  [
    "Granite Blocks individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Granite Slabs individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Granite Tiles individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Graphic tablets and accessories", null, false, true, true, false],
  ["Grass trimmers", 2, false, false, false, false],
  ["Graters, spiralizers and slicers", 1, false, false, false, false],
  ["Greenhouse accessories", 1, false, false, false, false],
  ["Greenhouses", 4, false, false, false, false],
  ["Griddle Pans", 1, false, false, false, false],
  ["Grinders", 2, false, false, false, false],
  ["Grooming kits", 1, false, false, false, false],
  ["Guest beds flat packed", 1, false, false, false, false],
  ["Guest beds not flat packed", 2, false, false, false, false],
  ["Guitar amps and accessories", 2, false, false, false, false],
  ["Guitars and accessories excluding antique and vintage", 2, false, false, false, false],
  ["Guns watering & Sprinklers", 1, false, false, false, false],
  ["Gym bags", 1, false, false, false, false],
  ["Gym balls", 1, false, false, false, false],
  ["Haberdashery", 1, false, false, false, false],
  ["Hair care", 1, false, false, false, false],
  ["Hair care accessories", 1, false, false, false, false],
  ["Hair care kits", 1, false, false, false, false],
  ["Hair clippers", 2, false, false, false, false],
  ["Hair crimpers and wavers", 2, false, false, false, false],
  ["Hair curlers and curling tongs", 2, false, false, false, false],
  ["Hair dryers", 2, false, false, false, false],
  ["Hair removal and waxing", 1, false, false, false, false],
  ["Hair removal devices electronic", 2, false, false, false, false],
  ["Hair rollers", 1, false, false, false, false],
  ["Hair straighteners", 1, false, false, false, false],
  ["Hall Tables", 2, false, false, false, false],
  ["Hallway furniture", 2, false, false, false, false],
  ["Hallway storage", 2, false, false, false, false],
  ["Hammers & Mallets", 1, false, false, false, false],
  ["Hammocks and swing seats", 1, false, false, false, false],
  ["Hand Blenders", 2, false, false, false, false],
  ["Hand forks and trowels", 1, false, false, false, false],
  ["Hand mixers", 2, false, false, false, false],
  ["Handbags - excluding Branded Goods, Fashionwear, Designerwear and leatherwear", 1, false, false, false, false],
  ["Handbags Branded - excluding Fashionwear and Designerwear", 2, false, false, false, false],
  ["Handcrafted fragile goods", null, false, true, true, false],
  ["Handheld computers", null, false, true, true, false],
  ["Handheld massagers", 2, false, false, false, false],
  ["Handheld vacuum cleaners", 2, false, false, false, false],
  ["Hanging rails and canvas wardrobes", 1, false, false, false, false],
  ["Hard disk drive", 2, false, false, false, false],
  ["Hard drives, USBs and data storage", 2, false, false, false, false],
  ["Hardware Books", 1, false, false, false, false],
  ["Hardware Garage Equipment", 1, false, false, false, false],
  ["Hardware Gardening Equipment", 1, false, false, false, false],
  ["Hardware Ironmongery", 1, false, false, false, false],
  ["Hardware Magazines", 1, false, false, false, false],
  ["Hardware Nails", 1, false, false, false, false],
  ["Hardware Office Sundries", 1, false, false, false, false],
  ["Hardware Paper Products", 1, false, false, false, false],
  ["Hardware Plastic Holloware", 1, false, false, false, false],
  ["Hardware Printed Matter", 1, false, false, false, false],
  ["Hardware Rubber Mats", 1, false, false, false, false],
  ["Hardware Screws", 1, false, false, false, false],
  ["Hardware Stationary", 1, false, false, false, false],
  ["Hardware Taps", 1, false, false, false, false],
  ["Hardware Tools", 1, false, false, false, false],
  ["Hardware Wire", 1, false, false, false, false],
  ["Harnesses and reins", 1, false, false, false, false],
  ["HDMI cables and optical cables", 1, false, false, false, false],
  ["HDMI, SCART cables and adapters", 1, false, false, false, false],
  ["Headboards", 1, false, false, false, false],
  ["Headphones", 2, false, false, false, false],
  ["Headphones and earphones", 2, false, false, false, false],
  ["Health accessories", 1, false, false, false, false],
  ["Health Grills", 2, false, false, false, false],
  ["Heart rate monitors", 2, false, false, false, false],
  ["Heat Guns", 2, false, false, false, false],
  ["Heaters and radiators", 2, false, false, false, false],
  ["Heavy duty vehicles", null, false, true, true, false],
  ["Heavy Manufacturing", 1, false, false, false, false],
  ["Heavy Rollers", 1, false, false, false, false],
  ["Heavylift Cargo", null, false, true, true, false],
  ["Hedge Trimmers", 2, false, false, false, false],
  ["Helicopters", null, false, true, true, false],
  ["Helium balloons", 1, false, false, false, false],
  ["Hides and skins", null, false, true, true, false],
  ["Hi-fi systems", 2, false, false, false, false],
  ["Highchair accessories", 1, false, false, false, false],
  ["Highchair toys", 1, false, false, false, false],
  ["Highchairs", 1, false, false, false, false],
  ["Highchairs and booster seats", 1, false, false, false, false],
  ["Hobbies and crafts", 1, false, false, false, false],
  ["Hobs", 2, false, false, false, false],
  ["Holdalls", 1, false, false, false, false],
  ["Home audio", 2, false, false, false, false],
  ["Home cinema accessories", 1, false, false, false, false],
  ["Home cinema systems", 2, false, false, false, false],
  ["Home cinema systems and sound bars", 2, false, false, false, false],
  ["Home furnishings", 1, false, false, false, false],
  ["Hoppers and pogo sticks", 1, false, false, false, false],
  ["Hose accessories", 1, false, false, false, false],
  ["Hose Fittings", 1, false, false, false, false],
  ["Hosepipes", 1, false, false, false, false],
  ["Hoses and garden watering", 1, false, false, false, false],
  ["Hoses and sets", 1, false, false, false, false],
  ["Hostess Trolleys", 2, false, false, false, false],
  ["Hot air stylers and brushes", 2, false, false, false, false],
  ["Hot trays and food warmers", 2, false, false, false, false],
  ["Hot tubs, Spas and Saunas", 2, false, false, false, false],
  ["Hot water bottles", 1, false, false, false, false],
  ["Hot water dispensers", 2, false, false, false, false],
  [
    "Household Goods - Owner & Professionally packed less than 15% fragiles and antiques",
    5,
    false,
    false,
    false,
    false,
  ],
  ["Household Goods & Personal Effects", null, false, true, true, false],
  ["Hoverboards", 2, false, false, false, false],
  ["Humidifiers", 2, false, false, false, false],
  ["Hunting Equipment excl arms and ammunition", 2, false, false, false, false],
  ["Ice cream makers", 2, false, false, false, false],
  ["iMacs", null, false, true, true, false],
  ["In car amps", 2, false, false, false, false],
  ["In car DAB and CD car radio accessories", 2, false, false, false, false],
  ["In car DAB and CD car radios", 2, false, false, false, false],
  ["In car DVD players", 2, false, false, false, false],
  ["In car multimedia systems", 2, false, false, false, false],
  ["In car speakers", 2, false, false, false, false],
  ["In-car accessories", 2, false, false, false, false],
  ["In-car chargers", 2, false, false, false, false],
  ["Indoor Airers", 1, false, false, false, false],
  ["Inflatable toys", 1, false, false, false, false],
  ["Insecticide in liquid or powder form", 1, false, false, false, false],
  ["Instant cameras", 2, false, false, false, false],
  ["Integrated dishwashers", 2, false, false, false, false],
  ["Integrated freezers", 2, false, false, false, false],
  ["Integrated fridge freezers", 2, false, false, false, false],
  ["Integrated fridges", 2, false, false, false, false],
  ["Integrated tumble dryers", 2, false, false, false, false],
  ["Integrated washer dryers", 2, false, false, false, false],
  ["Integrated washing machines", 2, false, false, false, false],
  ["iPad and tablet accessories", 2, false, false, false, false],
  ["iPad and tablet adapters", 2, false, false, false, false],
  ["iPad and tablet chargers", 2, false, false, false, false],
  ["iPad and tablet covers and cases", 2, false, false, false, false],
  ["iPad, tablets and E-readers", null, false, true, true, false],
  ["iPads", null, false, true, true, false],
  ["iPod", null, false, true, true, false],
  ["iPod accessories", 2, false, false, false, false],
  ["iPod and MP3 headphones", 2, false, false, false, false],
  ["iPod skins, cases and holders", 2, false, false, false, false],
  ["iPods and personal audio", null, false, true, true, false],
  ["Ironing boards", 1, false, false, false, false],
  ["Ironing Boards & Covers", 1, false, false, false, false],
  ["Ironmongery", 1, false, false, false, false],
  ["Irons", 2, false, false, false, false],
  ["Irons & Steam Generators", 2, false, false, false, false],
  ["Jars & Preserving items - Non breakable", 1, false, false, false, false],
  ["Jeans and Trousers - excluding Fashion and Designer wear", 1, false, false, false, false],
  ["Jeans, Trousers and Leggings - excluding Fashion and Designer wear", 1, false, false, false, false],
  ["Jewellery", null, false, true, true, false],
  ["Jewellery and fashion toys", null, false, true, true, false],
  ["Jewellery and watch care items", 1, false, false, false, false],
  ["Jewellery and watches", null, false, true, true, false],
  ["Jewellery boxes and hangers", 1, false, false, false, false],
  ["Jewellery care items", 1, false, false, false, false],
  ["Jewellery cleaners and polishing cloths", 1, false, false, false, false],
  ["Jewellery gift packaging", 1, false, false, false, false],
  ["Jigsaws & Power Saws", 2, false, false, false, false],
  ["Jigsaws Puzzles", 1, false, false, false, false],
  ["Joint supports", 1, false, false, false, false],
  ["Jugs - Breakable", 4, false, false, false, false],
  ["Jugs and gravy boats - Breakable", 4, false, false, false, false],
  ["Juicers", 2, false, false, false, false],
  ["Juicers and presses", 2, false, false, false, false],
  ["Juicers and smoothie makers", 2, false, false, false, false],
  ["Jumpers and sweatshirts - excluding Fashion and Designer wear", 1, false, false, false, false],
  ["Jumpers, Cardigans and Sweatshirts -excluding Fashion and Designer wear", 1, false, false, false, false],
  ["Karaoke discs", 2, false, false, false, false],
  ["Karaoke machines", 2, false, false, false, false],
  ["Karaoke machines and equipment", 2, false, false, false, false],
  ["Karaoke microphones", 2, false, false, false, false],
  ["Kayaks and dinghies", 2, false, false, false, false],
  ["Kettles", 2, false, false, false, false],
  ["Keyboards", 2, false, false, false, false],
  ["Kids ball games", 1, false, false, false, false],
  ["Kids basketball", 1, false, false, false, false],
  ["Kids bedroom furniture", 2, false, false, false, false],
  ["Kids bedroom furniture sets", 2, false, false, false, false],
  ["Kids beds flat packed", 1, false, false, false, false],
  ["Kids beds non flat packed", 2, false, false, false, false],
  ["Kids bedside tables", 1, false, false, false, false],
  ["Kids bedside tables non flat packed", 2, false, false, false, false],
  ["Kids bikes", 2, false, false, false, false],
  ["Kids books", 1, false, false, false, false],
  ["Kids bracelets and bangles", null, false, true, true, false],
  ["Kids camcorders", 2, false, false, false, false],
  ["Kids cameras", 2, false, false, false, false],
  ["Kids charm bracelets and beads", null, false, true, true, false],
  ["Kids chest of drawers", 2, false, false, false, false],
  ["Kids croquet", 1, false, false, false, false],
  ["Kids desks", 2, false, false, false, false],
  ["Kids dressing tables", 2, false, false, false, false],
  ["Kids duvet sets", 1, false, false, false, false],
  ["Kids earrings", null, false, true, true, false],
  ["Kids face paints", 1, false, false, false, false],
  ["Kids fancy dress", 1, false, false, false, false],
  ["Kids fancy dress costumes", 1, false, false, false, false],
  ["Kids fancy dress shoes and accessories", 1, false, false, false, false],
  ["Kids football", 1, false, false, false, false],
  ["Kids furniture", 2, false, false, false, false],
  ["Kids game consoles", null, false, true, true, false],
  ["Kids jewellery", null, false, true, true, false],
  ["Kids jewellery sets", null, false, true, true, false],
  ["Kids laptops", null, false, true, true, false],
  ["Kids laptops, tablets and consoles", null, false, true, true, false],
  ["Kids lighting", 4, false, false, false, false],
  ["Kids luggage", 1, false, false, false, false],
  ["Kids masks, wigs and helmets", 1, false, false, false, false],
  ["Kids mattresses", 1, false, false, false, false],
  ["Kids necklaces", null, false, true, true, false],
  ["Kids outdoor furniture", 1, false, false, false, false],
  ["Kids racket sports", 1, false, false, false, false],
  ["Kids rings", null, false, true, true, false],
  ["Kids tables and chairs", 2, false, false, false, false],
  ["Kids tablets", null, false, true, true, false],
  ["Kids tableware and cutlery- breakable and non precious metal cuttlery", 4, false, false, false, false],
  ["Kids tableware and cutlery- Non breakable and non precious metal cuttlery", 1, false, false, false, false],
  ["Kids telephones toys and walkie talkies toys", 1, false, false, false, false],
  ["Kids toy boxes and storage", 1, false, false, false, false],
  ["Kids wardrobes", 2, false, false, false, false],
  ["Kids watches", null, false, true, true, false],
  ["Kindle and E-reader cases and covers", 1, false, false, false, false],
  ["Kindle and E-readers", null, false, true, true, false],
  ["Kitchen bins", 1, false, false, false, false],
  ["Kitchen Cleaning", 1, false, false, false, false],
  ["Kitchen electricals", 2, false, false, false, false],
  ["Kitchen roll holders", 1, false, false, false, false],
  ["Kitchen Scales", 2, false, false, false, false],
  ["Kitchen shelves and stands", 1, false, false, false, false],
  ["Kitchen storage", 1, false, false, false, false],
  ["Kitchen trolleys", 1, false, false, false, false],
  ["Kitchen Utensils", 1, false, false, false, false],
  ["Kitchenware- breakable", 4, false, false, false, false],
  ["Kitchenware non breakable", 1, false, false, false, false],
  ["Kites", 1, false, false, false, false],
  ["Knife Blocks", 1, false, false, false, false],
  ["Knitting Machinery", 2, false, false, false, false],
  ["Knives", 1, false, false, false, false],
  ["Knives & Box Cutters", 1, false, false, false, false],
  ["Knives and knife blocks", 1, false, false, false, false],
  ["Ladders & Steps", 1, false, false, false, false],
  ["Ladders and step stools", 1, false, false, false, false],
  ["Lady shavers", 2, false, false, false, false],
  ["Lager in barrels", 2, false, false, false, false],
  ["Lager in bottles", 2, false, false, false, false],
  ["Lager in cans", 2, false, false, false, false],
  ["Lager in cartons", 2, false, false, false, false],
  ["Lager in drums", 2, false, false, false, false],
  ["Lager in kegs", 2, false, false, false, false],
  ["Laminator accessories", 1, false, false, false, false],
  ["Laminators", 2, false, false, false, false],
  ["Laminators and accessories", 2, false, false, false, false],
  ["Lampshades breakable", 4, false, false, false, false],
  ["Lampshades non breakable", 1, false, false, false, false],
  ["Laptop", null, false, true, true, false],
  ["Laptop and Macbook chargers", 2, false, false, false, false],
  ["Laptop and PC accessories", 2, false, false, false, false],
  ["Laptop and PC headsets", 2, false, false, false, false],
  ["Laptop and PC mice", 2, false, false, false, false],
  ["Laptop and PC software", 2, false, false, false, false],
  ["Laptop and PC speakers", 2, false, false, false, false],
  ["Laptop bags, cases and skins", 2, false, false, false, false],
  ["Laptop Computers", null, false, true, true, false],
  ["Laptop security cables", 2, false, false, false, false],
  ["Laptop trays and stands", 2, false, false, false, false],
  ["Laptops", null, false, true, true, false],
  ["Large kitchen appliances", 2, false, false, false, false],
  ["Laser hair removal", 2, false, false, false, false],
  ["Lasers & Measures", 2, false, false, false, false],
  ["Laundry accessories", 1, false, false, false, false],
  ["Laundry and cleaning", 1, false, false, false, false],
  ["Lawncare", 1, false, false, false, false],
  ["Lawnmowers", 2, false, false, false, false],
  ["LCD screens", null, false, true, true, false],
  ["Leaf Blowers", 2, false, false, false, false],
  ["Leaf blowers and garden vacuums", 2, false, false, false, false],
  ["Leather Goods excluding Fashion and Designer wear", 2, false, false, false, false],
  ["Leatherwear", null, false, true, true, false],
  ["Leatherwear excluding Fashion and Designer wear", 2, false, false, false, false],
  ["LEGO and construction toys", 1, false, false, false, false],
  ["Leisure Articles", 2, false, false, false, false],
  ["Leisure wear excluding Fashion and Designer wear", 2, false, false, false, false],
  ["Lens accessories", 1, false, false, false, false],
  ["Lifestyle and cook books", 1, false, false, false, false],
  ["Lift chairs", 2, false, false, false, false],
  ["Lift Top Bins", 1, false, false, false, false],
  ["Light Bulbs", 4, false, false, false, false],
  ["Light Bulbs packed in cartons", 4, false, false, false, false],
  ["Light Bulbs packed in cases", 4, false, false, false, false],
  ["Light Bulbs packed in waterproof crates", 4, false, false, false, false],
  ["Light switches", 1, false, false, false, false],
  ["Light therapy", 1, false, false, false, false],
  ["Lighting", 4, false, false, false, false],
  ["Linen baskets and laundry bins", 1, false, false, false, false],
  ["Lingerie", 1, false, false, false, false],
  ["Liqueurs", null, false, true, true, false],
  ["Liquid Chemicals in Tins", 1, false, false, false, false],
  ["Liquid Pharmaceutical Products in Tins", 1, false, false, false, false],
  ["Liquor", null, false, true, true, false],
  ["Live Animals", null, false, true, true, false],
  ["Live Plants", null, false, true, true, false],
  ["Livestock", null, false, true, true, false],
  ["Living room furniture", 2, false, false, false, false],
  ["Living room furniture sets", 2, false, false, false, false],
  ["Log Cabins & Chalets", 2, false, false, false, false],
  ["Luggage - excl Fashion and Designer wear", 1, false, false, false, false],
  ["Luggage and bag accessories -excl Fashion and Designer wear", 1, false, false, false, false],
  ["Luggage Sets -excl Fashion and Designer wear", 1, false, false, false, false],
  ["Lumber", null, false, true, true, false],
  ["Lunch boxes", 1, false, false, false, false],
  ["Lunch Containers", 1, false, false, false, false],
  ["Macbooks", null, false, true, true, false],
  [
    "Machinery excl Precision Instruments, Scientific and Computerised Control Panels or Machinery which is prone to breakage",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Machinery Parts which are not prone to breakage", 1, false, false, false, false],
  ["Machinery Spares which are not prone to breakage", 1, false, false, false, false],
  ["Magazine racks", 1, false, false, false, false],
  ["Make up, skincare and nails", 1, false, false, false, false],
  ["Make-up and beauty toys", 1, false, false, false, false],
  ["Make-up and skin care -excl Designer products", 1, false, false, false, false],
  ["Makeup bags", 1, false, false, false, false],
  ["Makeup brushes and tools", 1, false, false, false, false],
  ["Makeup mirrors", 4, false, false, false, false],
  ["Makeup sets", 1, false, false, false, false],
  ["Male jewellery", null, false, true, true, false],
  [
    "Marble Blocks individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Marble Slabs individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Marble Tiles individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Masks (PPE)", null, false, true, true, false],
  ["Massage chairs, mats and cushions", 1, false, false, false, false],
  ["Maternity accessories", 1, false, false, false, false],
  ["Maternity Clothes", 1, false, false, false, false],
  ["Mattress protectors", 1, false, false, false, false],
  ["Mattress toppers", 1, false, false, false, false],
  ["Mattresses", 1, false, false, false, false],
  ["Mealworm not in bulk", 1, false, false, false, false],
  ["Measuring and levels", 1, false, false, false, false],
  ["Measuring cups", 1, false, false, false, false],
  ["Medals", null, false, true, true, false],
  ["Memory Boards", null, false, true, true, false],
  ["Memory card readers", 2, false, false, false, false],
  ["Memory cards", null, false, true, true, false],
  ["Mens Accessories -excl Fashion and Designer wear", 1, false, false, false, false],
  ["Men's and womens bikes", 2, false, false, false, false],
  ["Mens Blazers and Jackets - Branded excluding Designer wear", 2, false, false, false, false],
  ["Mens Blazers and Jackets -excl Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Boots - Branded excluding Designer wear", 2, false, false, false, false],
  ["Mens Boots -excl Fashion and Designer wear", 1, false, false, false, false],
  ["Men's bracelets", null, false, true, true, false],
  ["Mens Casual Shirts - Branded excluding Designer wear", 2, false, false, false, false],
  ["Mens Casual Shirts -excluding branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Casual Shoes - Branded excluding Designer wear", 2, false, false, false, false],
  ["Mens Casual Shoes -excluding branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Men's clothing - Branded excluding Designer wear", 2, false, false, false, false],
  ["Men's clothing -excluding branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Coats and Jackets - Branded excluding Designer wear", 2, false, false, false, false],
  ["Mens Coats and Jackets -excluding branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Men's earrings", null, false, true, true, false],
  ["Men's fashion rings", null, false, true, true, false],
  ["Mens Formal Shirts - excluding designer wear", 2, false, false, false, false],
  ["Mens Formal Shoes - excluding designer wear", 2, false, false, false, false],
  ["Mens Formal Trousers -excluding designer wear", 2, false, false, false, false],
  ["Mens Jeans - excluding Branded, fashionwear and designer wear", 1, false, false, false, false],
  ["Mens Jeans -Branded excluding designer wear", 2, false, false, false, false],
  ["Men's jewellery and cufflinks", null, false, true, true, false],
  ["Mens Jumpers Branded - Excluding Designer wear", 2, false, false, false, false],
  ["Mens Jumpers -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Loungewear Branded - Excluding Designer wear", 2, false, false, false, false],
  ["Mens Loungewear -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Men's necklaces, chains and dog tags", null, false, true, true, false],
  ["Mens Nightwear - excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Nightwear -Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Polos- Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Polos -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Men's rings", null, false, true, true, false],
  ["Men's shavers", 2, false, false, false, false],
  ["Men's shavers and accessories", 1, false, false, false, false],
  ["Men's shaving and grooming", 1, false, false, false, false],
  ["Mens Shorts- Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Shorts -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Sportswear", 2, false, false, false, false],
  ["Mens Sweatshirts - Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Sweatshirts -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Swimwear - Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Swimwear -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Trousers and Chinos -Branded excluding designer wear", 2, false, false, false, false],
  ["Mens Trousers and Chinos -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens T-shirts - Branded excluding designer wear", 2, false, false, false, false],
  ["Mens T-shirts -excl Branded goods, Fashion and Designer wear", 1, false, false, false, false],
  ["Mens Underwear and Socks", 1, false, false, false, false],
  ["Men's watches", null, false, true, true, false],
  ["Men's wedding rings and bands", null, false, true, true, false],
  ["Messenger bags", 1, false, false, false, false],
  ["Metal scrap", null, false, true, true, false],
  ["Microphones", 2, false, false, false, false],
  ["Microscopes", 2, false, false, false, false],
  ["MicroSD memory cards", null, false, true, true, false],
  ["Microwave cookware", 1, false, false, false, false],
  ["Microwaves", 2, false, false, false, false],
  ["Mini fridges", 2, false, false, false, false],
  ["Mini ovens", 2, false, false, false, false],
  ["Mirrorless cameras", 2, false, false, false, false],
  ["Mirrors", 4, false, false, false, false],
  ["Mirrors packed in cartons", 4, false, false, false, false],
  ["Mirrors packed in cases", 4, false, false, false, false],
  ["Mirrors packed in waterproof crates", 4, false, false, false, false],
  ["Mixing bowls - breakable", 4, false, false, false, false],
  ["Mixing bowls - Non breakable", 1, false, false, false, false],
  ["Mobile phone accessories", 2, false, false, false, false],
  ["Mobile phone accessories and attachments", 2, false, false, false, false],
  ["Mobile phone cases", 2, false, false, false, false],
  ["Mobile phone chargers and adaptors", 2, false, false, false, false],
  ["Mobile phone headsets, headphones and microphones", 2, false, false, false, false],
  ["Mobile phone screen protectors", 2, false, false, false, false],
  ["Mobile Phone SIM Cards", null, false, true, true, false],
  ["Mobile phones", null, false, true, true, false],
  ["Mobile USB Storage", 2, false, false, false, false],
  ["Mobility aids", 1, false, false, false, false],
  ["Mobility scooters", 2, false, false, false, false],
  ["Model cars, vehicles and figures", 2, false, false, false, false],
  ["Money", null, false, true, true, false],
  ["Money boxes", 1, false, false, false, false],
  ["Mops & Brushes", 1, false, false, false, false],
  ["Moses baskets, stands and mattresses", 1, false, false, false, false],
  ["Motor Cars & Motorbikes - Commercial", 6, false, true, false, false],
  ["Motor Cars & Motorbikes - Private", 6, false, true, false, false],
  ["Motor Homes shipped in containers - Commercial", 6, false, true, false, false],
  ["Motor Homes shipped in containers - Private", 6, false, true, false, false],
  ["Motor Homes shipped underdeck - Commercial", 6, false, true, false, false],
  ["Motor Homes shipped underdeck - Private", 6, false, true, false, false],
  ["Motor Vehicles - Commercial", 6, false, true, false, false],
  ["Motor Vehicles - Private", 6, false, true, false, false],
  ["Motor vehicles over 12 years of age", 6, false, true, false, false],
  ["Motorbikes shipped in containers - Commercial", 6, false, true, false, false],
  ["Motorbikes shipped in containers - Private", 6, false, true, false, false],
  ["Motorbikes shipped underdeck - Commercial", 6, false, true, false, false],
  ["Motorbikes shipped underdeck - Private", 6, false, true, false, false],
  ["Motorcycle - Commercial", 6, false, true, false, false],
  ["Motorcycle - Private", 6, false, true, false, false],
  ["Caravan(s) shipped in containers - Commercial", 6, false, true, false, false],
  ["Caravan(s) shipped in containers - Private", 6, false, true, false, false],
  ["RV’s shipped in containers - Commercial", 6, false, true, false, false],
  ["RV’s shipped in containers - Private", 6, false, true, false, false],
  ["Recreational Vehicles shipped in containers - Commercial", 6, false, true, false, false],
  ["Recreational Vehicles shipped in containers - Private", 6, false, true, false, false],
  ["Quad Bike(s) shipped in containers - Commercial", 6, false, true, false, false],
  ["Quad Bike(s) shipped in containers - Private", 6, false, true, false, false],
  ["Mobile Home(s) shipped in containers - Commercial", 6, false, true, false, false],
  ["Mobile Home(s) shipped in containers - Private", 6, false, true, false, false],
  ["Motorcycle accessories", 1, false, false, false, false],
  ["Mouse mats", 1, false, false, false, false],
  ["Moving house packs and bubble wrap", 1, false, false, false, false],
  ["MP3 accessories", 2, false, false, false, false],
  ["MP3 players", 2, false, false, false, false],
  ["Mudguards and tyres", 1, false, false, false, false],
  ["Mug trees", 1, false, false, false, false],
  ["Mugs & Cups - Breakable", 4, false, false, false, false],
  ["Mugs & Cups - non breakable", 1, false, false, false, false],
  ["Multi cookers, rice cookers and slow cookers", 2, false, false, false, false],
  ["Multi games tables", 2, false, false, false, false],
  ["Multi stylers", 2, false, false, false, false],
  ["Multi Tools", 2, false, false, false, false],
  ["Multi-gyms", 2, false, false, false, false],
  ["Multitools", 2, false, false, false, false],
  ["Murals artwork", null, false, true, true, false],
  ["Music books and song books", 1, false, false, false, false],
  ["Music Keyboards", 2, false, false, false, false],
  ["Music stands", 1, false, false, false, false],
  ["Music stools and benches", 1, false, false, false, false],
  ["Musical cases and bags", 1, false, false, false, false],
  ["Musical Instruments excluding antiques and vintage", 2, false, false, false, false],
  ["Musical toys and instruments", 2, false, false, false, false],
  ["Nail guns", 2, false, false, false, false],
  ["Nails and screws hardware", 1, false, false, false, false],
  ["Napkins", 1, false, false, false, false],
  ["Napkins & Tablecoths", 1, false, false, false, false],
  ["Nappy disposal accessories", 1, false, false, false, false],
  ["Neck massagers", 2, false, false, false, false],
  ["Neon Tubes in cartons", 4, false, false, false, false],
  ["Neon Tubes in cases", 4, false, false, false, false],
  ["Neon tubes in waterproof crates", 4, false, false, false, false],
  ["Nerf guns", 1, false, false, false, false],
  ["Nest of tables", 2, false, false, false, false],
  ["Netball hoops", 1, false, false, false, false],
  ["Netballs", 1, false, false, false, false],
  ["Network switches", 1, false, false, false, false],
  ["New Furniture packed in cartons", 2, false, false, false, false],
  ["New Furniture packed in cases", 2, false, false, false, false],
  ["New Furniture packed in waterproof crates", 2, false, false, false, false],
  ["Nightlights electronic, projectors and clocks", 2, false, false, false, false],
  ["Nintendo accessories excluding consoles and games", 2, false, false, false, false],
  ["Nintendo consoles", null, false, true, true, false],
  ["Nintendo Games", null, false, true, true, false],
  ["Non Fragile Dental Equipment", 1, false, false, false, false],
  ["Non Fragile Material Equipment", 1, false, false, false, false],
  ["Non Fragile Medical Equipment", 1, false, false, false, false],
  ["Non-fragile Motor Vehicle Accessories", 1, false, false, false, false],
  ["Non-fragile Motor Vehicle Spares", 1, false, false, false, false],
  ["Non-fragile Motor Vehicle Tyres", 1, false, false, false, false],
  ["Nose and ear trimmers", 2, false, false, false, false],
  ["Notebook computers", null, false, true, true, false],
  ["Novelty gifts", 2, false, false, false, false],
  ["Novelty lighting", 4, false, false, false, false],
  ["Nursery bedding sets", 1, false, false, false, false],
  ["Nursery blankets and quilts", 1, false, false, false, false],
  ["Nursery chest of drawers non flat packed", 2, false, false, false, false],
  ["Nursery chest of drawers flat packed", 1, false, false, false, false],
  ["Nursery curtains", 1, false, false, false, false],
  ["Nursery cushions", 1, false, false, false, false],
  ["Nursery duvets and pillows", 1, false, false, false, false],
  ["Nursery fitted and flat sheets", 1, false, false, false, false],
  ["Nursery furniture", 2, false, false, false, false],
  ["Nursery furniture sets", 2, false, false, false, false],
  ["Nursery soft furnishings", 1, false, false, false, false],
  ["Nursery storage", 2, false, false, false, false],
  ["Nursery wardrobes", 2, false, false, false, false],
  ["Nursing chairs and footstools flat packed", 1, false, false, false, false],
  ["Nursing chairs and footstools non flat packed", 2, false, false, false, false],
  ["Nut Feeders", 1, false, false, false, false],
  ["Nutritional aids and supplements", 1, false, false, false, false],
  ["Ocean Towage Risks", null, false, true, true, false],
  ["Office chairs flat packed", 1, false, false, false, false],
  ["Office chairs non flat packed", 2, false, false, false, false],
  ["Office furniture flat packed", 1, false, false, false, false],
  ["Office furniture non flat packed", 2, false, false, false, false],
  ["Office supplies", 1, false, false, false, false],
  ["Ornaments non antique", 4, false, false, false, false],
  ["Ottomans", 2, false, false, false, false],
  ["Out of Gauge Cargo", null, false, true, true, false],
  ["Outdoor Airers", 1, false, false, false, false],
  ["Outdoor and patio heaters", 2, false, false, false, false],
  ["Outdoor Christmas decorations", 1, false, false, false, false],
  ["Outdoor cushions and rugs", 1, false, false, false, false],
  ["Outdoor dinnerware and cutlery", 4, false, false, false, false],
  ["Outdoor dinnerware and cutlery - non Breakable", 1, false, false, false, false],
  ["Outdoor Footwear", 1, false, false, false, false],
  ["Outdoor games", 1, false, false, false, false],
  ["Outdoor Lighting", 4, false, false, false, false],
  ["Outdoor sports games", 1, false, false, false, false],
  ["Outdoor toys", 1, false, false, false, false],
  ["Outdoor wall lights and lanterns", 4, false, false, false, false],
  ["Oven and casserole dishes", 4, false, false, false, false],
  ["Oven and hob accessories", 2, false, false, false, false],
  ["Oven and hob packages", 2, false, false, false, false],
  ["Oven Gloves & Aprons", 1, false, false, false, false],
  ["Oven Trays & Dishes", 4, false, false, false, false],
  ["Ovens packed in cartons", 2, false, false, false, false],
  ["Ovens packed in cases", 2, false, false, false, false],
  ["Ovens packed in waterproof crates", 2, false, false, false, false],
  ["Over sized cargo", null, false, true, true, false],
  ["Over weight Cargo", null, false, true, true, false],
  ["Overbed tables", 1, false, false, false, false],
  ["Overdoor storage and hanging storage", 1, false, false, false, false],
  ["Oversized cargo", null, false, true, true, false],
  ["Paddling pools and water games", 1, false, false, false, false],
  ["Padlocks", 1, false, false, false, false],
  ["Pagodas and pergolas", 1, false, false, false, false],
  ["Paint in tins or drums", 1, false, false, false, false],
  ["Paint sprayers", 2, false, false, false, false],
  ["Paintbrushes and rollers", 1, false, false, false, false],
  ["Painting, drawing and colouring toys", 1, false, false, false, false],
  ["Pan sets", 2, false, false, false, false],
  ["Parasols & Bases", 1, false, false, false, false],
  ["Parasols and sunshades", 1, false, false, false, false],
  ["Party decorations", 1, false, false, false, false],
  ["Party Food Makers", 2, false, false, false, false],
  ["Party supplies and accessories", 1, false, false, false, false],
  ["Pasta machines and makers", 2, false, false, false, false],
  ["Patio Heating", 2, false, false, false, false],
  ["Patio sets", 1, false, false, false, false],
  ["Paving Packs", 1, false, false, false, false],
  ["PC games", null, false, true, true, false],
  ["PC gaming accessories", 2, false, false, false, false],
  ["PC keyboards", 2, false, false, false, false],
  ["PC monitor stands and brackets", 2, false, false, false, false],
  ["PC monitors", 2, false, false, false, false],
  ["PC monitors and stands", 2, false, false, false, false],
  ["Pedal Bins", 1, false, false, false, false],
  ["Pedal powered vehicles", 2, false, false, false, false],
  ["Pedometers", 2, false, false, false, false],
  ["Pen sets and stationery", 1, false, false, false, false],
  ["Perfume excluding Fashion and Designer wear", 2, false, false, false, false],
  ["Perfumes excluding Fashion and Designer wear", 2, false, false, false, false],
  ["Pergolas & Arbours", 1, false, false, false, false],
  ["Perishables", null, false, true, true, false],
  ["Personal CD players and cassette players", 2, false, false, false, false],
  [
    "Personal Effects- Owner & Professionally packed less than 15% fragiles and antiques",
    5,
    false,
    false,
    false,
    false,
  ],
  ["Personal Motor Vehicles shipped in containers", 6, false, true, false, false],
  ["Personal Motor Vehicles shipped underdeck", 6, false, true, false, false],
  ["Personal ornaments", null, false, true, true, false],
  ['Personal Protection Equipment (or "PPE")', null, false, true, true, false],
  ["Personal radios", 2, false, false, false, false],
  ["Pet Care", 1, false, false, false, false],
  ["Pet flaps and doors", 1, false, false, false, false],
  ["Pet habitats and cages", 1, false, false, false, false],
  ["Pet hutches and run", 1, false, false, false, false],
  ["Pet supplies", 1, false, false, false, false],
  ["Pharmaceutical Products in Bottles non temperature controlled", 1, false, false, false, false],
  ["Pharmaceutical Products in Cans non temperature controlled", 1, false, false, false, false],
  ["Pharmaceutical Products in Paper Sachets non temperature controlled", 1, false, false, false, false],
  ["Pharmaceutical Products in Tins non temperature controlled", 1, false, false, false, false],
  ["Phone & Tablet Accessories", 2, false, false, false, false],
  ["Photo albums and frames breakable", 4, false, false, false, false],
  ["Photo albums and frames non breakable", 1, false, false, false, false],
  ["Photo editing software", 1, false, false, false, false],
  ["Photo frames breakable", 4, false, false, false, false],
  ["Photo frames non breakable", 1, false, false, false, false],
  ["Photocopiers", 2, false, false, false, false],
  ["Photographic accessories and editing software", 1, false, false, false, false],
  ["Picnic blankets and baskets", 1, false, false, false, false],
  ["Picnicware", 2, false, false, false, false],
  ["Pictures and wall art", null, false, true, true, false],
  ["Pillow Cases", 1, false, false, false, false],
  ["Pillow protectors", 1, false, false, false, false],
  ["Pillows", 1, false, false, false, false],
  ["Pizza Ovens", 2, false, false, false, false],
  ["Pizza Ovens and Outdoor Ovens", 2, false, false, false, false],
  ["Placemats and coasters", 1, false, false, false, false],
  ["Planers", 2, false, false, false, false],
  ["Plant food, weed killer and compost", 1, false, false, false, false],
  ["Plant Protection & Support", 1, false, false, false, false],
  ["Plants live", null, false, true, true, false],
  ["Plasma screens", null, false, true, true, false],
  ["Plastic Storage", 1, false, false, false, false],
  ["Plastic storage boxes", 1, false, false, false, false],
  ["Plastic tableware", 1, false, false, false, false],
  ["Plates", 4, false, false, false, false],
  ["Play Houses", 1, false, false, false, false],
  ["Play tents", 1, false, false, false, false],
  ["Play tunnels", 1, false, false, false, false],
  ["Playhouses", 1, false, false, false, false],
  ["Playhouses and activity centres", 1, false, false, false, false],
  ["Playmats and gyms", 1, false, false, false, false],
  ["Playpens", 1, false, false, false, false],
  ["Playsets and figures", 1, false, false, false, false],
  ["Pliers & Cutters", 1, false, false, false, false],
  ["Plug Timers", 1, false, false, false, false],
  ["Plumbing & Heating", 1, false, false, false, false],
  ["Poker sets and games", 1, false, false, false, false],
  ["Pole saws", 1, false, false, false, false],
  ["Polish", 1, false, false, false, false],
  ["Pool balls", 1, false, false, false, false],
  ["Pool tables", 2, false, false, false, false],
  ["Pools and paddling pools", 1, false, false, false, false],
  ["Port", null, false, true, true, false],
  ["Portable DVD players and accessories", 2, false, false, false, false],
  ["Portable music players", null, false, true, true, false],
  ["Portable power banks", 2, false, false, false, false],
  ["Pots & Planters breakable", 4, false, false, false, false],
  ["Pots & Planters non breakable", 2, false, false, false, false],
  ["Pottery packed in cartons", 4, false, false, false, false],
  ["Pottery packed in cases", 4, false, false, false, false],
  ["Pottery packed in waterproof crates", 4, false, false, false, false],
  ["Potties", 1, false, false, false, false],
  ["Power adapters", 2, false, false, false, false],
  ["Power Banks", 2, false, false, false, false],
  ["Power Tool Accessories", 1, false, false, false, false],
  ["Pram and Pushchair", 2, false, false, false, false],
  ["Pram and Pushchair accessories", 1, false, false, false, false],
  ["Pram and Pushchair accessory sets", 1, false, false, false, false],
  ["Pram and Pushchair parts and accessories", 1, false, false, false, false],
  ["Pram toys", 1, false, false, false, false],
  ["Precious metals", null, false, true, true, false],
  ["Precious stones", null, false, true, true, false],
  ["Precision Instruments which are prone to Breakage", 2, false, false, false, false],
  ["Precision Machinery which is prone to Breakage", 2, false, false, false, false],
  ["Pressure Cookers", 2, false, false, false, false],
  ["Pressure Washers", 2, false, false, false, false],
  ["Printed Circuit Boards (PCB's)", null, false, true, true, false],
  ["Printer ink", 1, false, false, false, false],
  ["Printer ink, paper and accessories", 1, false, false, false, false],
  ["Printer paper", 1, false, false, false, false],
  ["Printers", 2, false, false, false, false],
  ["Printers & Scanners", 2, false, false, false, false],
  ["Printing Machinery", 2, false, false, false, false],
  ["Private Motor Vehicles shipped in containers", 6, false, true, false, false],
  ["Private Motor Vehicles shipped underdeck", 6, false, true, false, false],
  ["Project Cargoes", null, false, true, true, false],
  ["Projector accessories", 1, false, false, false, false],
  ["Projectors", 2, false, false, false, false],
  ["Projectors and accessories", 2, false, false, false, false],
  ["Protective Clothing", null, false, true, true, false],
  ["Protein bars and snacks", 1, false, false, false, false],
  ["Protein powder and protein shakes", 1, false, false, false, false],
  ["Protein shakers", 1, false, false, false, false],
  ["Protypes models", null, false, true, true, false],
  ["Puppet theatre", 2, false, false, false, false],
  ["Puzzles and jigsaws", 2, false, false, false, false],
  ["Radiator covers", 1, false, false, false, false],
  ["Radiators", 1, false, false, false, false],
  ["Radio controlled boats and other toys", 2, false, false, false, false],
  ["Radio controlled cars", 2, false, false, false, false],
  ["Radio controlled cars and toys", 2, false, false, false, false],
  ["Radio controlled helicopters, aircraft and mini-drones", 2, false, false, false, false],
  ["Radio controlled toy accessories", 2, false, false, false, false],
  ["Radiological Equipment", 2, false, false, false, false],
  ["Radios", 2, false, false, false, false],
  ["Radios excl Portable Music Players", 2, false, false, false, false],
  ["Raincovers", 1, false, false, false, false],
  ["Raised toilet seats", 1, false, false, false, false],
  ["Rakes & Brooms", 1, false, false, false, false],
  ["Range cookers", 2, false, false, false, false],
  ["Rattan", 2, false, false, false, false],
  ["Razor blades", null, false, true, true, false],
  ["Razors electric or battery", 2, false, false, false, false],
  ["Razors wet shave", 1, false, false, false, false],
  ["Record players and turntables", 2, false, false, false, false],
  ["Recorders", 2, false, false, false, false],
  ["Recycling Bins", 1, false, false, false, false],
  ["Reed diffusers", 2, false, false, false, false],
  ["Refined sugar", null, false, true, true, false],
  ["Refrigerators and Freezers packed in cartons", 2, false, false, false, false],
  ["Refrigerators and Freezers packed in cases", 2, false, false, false, false],
  ["Refrigerators and Freezers packed in waterproof crates", 2, false, false, false, false],
  ["Remote controls", 2, false, false, false, false],
  ["Resin", 1, false, false, false, false],
  ["Respiratory devices", 2, false, false, false, false],
  ["Retro gaming consoles", null, false, true, true, false],
  ["Rice cookers", 2, false, false, false, false],
  ["Rice, grains dried and packed", 2, false, false, false, false],
  ["Ride-ons", 2, false, false, false, false],
  ["Road Works Equipment", 1, false, false, false, false],
  ["Roasting Tins", 1, false, false, false, false],
  ["Robot Cleaners", 2, false, false, false, false],
  ["Robot toys", 2, false, false, false, false],
  ["Rockers", 1, false, false, false, false],
  ["Role play toys", 2, false, false, false, false],
  ["Rollators", 2, false, false, false, false],
  ["Roof racks and roof boxes", 2, false, false, false, false],
  ["Routers and jointers", 2, false, false, false, false],
  ["Rowing machines", 2, false, false, false, false],
  ["Rugby balls", 2, false, false, false, false],
  ["Rugby equipment", 2, false, false, false, false],
  ["Rugs", 2, false, false, false, false],
  ["Saddles and pedals", 2, false, false, false, false],
  ["Safes", 2, false, false, false, false],
  ["Safety gates", 1, false, false, false, false],
  ["Safety helmets and pads", 2, false, false, false, false],
  ["Salt & Pepper Mills", 2, false, false, false, false],
  ["Sand", 1, false, false, false, false],
  ["Sand toy sets", 2, false, false, false, false],
  ["Sanders", 2, false, false, false, false],
  ["Sandpits and play tables", 2, false, false, false, false],
  ["Sandpits, sand and play tables", 2, false, false, false, false],
  ["Sandwich toasters", 2, false, false, false, false],
  ["Sanitaryware packed in cartons", 4, false, false, false, false],
  ["Sanitaryware packed in cases", 4, false, false, false, false],
  ["Sanitaryware packed in waterproof crates", 4, false, false, false, false],
  ["Sat nav accessories", 2, false, false, false, false],
  ["Sat nav accessory kits", 2, false, false, false, false],
  ["Sat nav and in-car entertainment", 2, false, false, false, false],
  ["Sat nav cases", 1, false, false, false, false],
  ["Sat nav chargers", 2, false, false, false, false],
  ["Sat nav devices for cars", 2, false, false, false, false],
  ["Sat nav mounts", 1, false, false, false, false],
  ["Sat Navigation equipment for cars", 2, false, false, false, false],
  ["Satellite boxes", 2, false, false, false, false],
  ["Satellite Cargo business up to launch", null, false, true, true, false],
  ["Saucepan Sets", 2, false, false, false, false],
  ["Saucepan steamers", 2, false, false, false, false],
  ["Saucepans", 2, false, false, false, false],
  ["Saws", 1, false, false, false, false],
  ["Scanners", 2, false, false, false, false],
  ["Scarifiers and rotavators", 2, false, false, false, false],
  ["School Shoes", 1, false, false, false, false],
  ["School Sportswear", 1, false, false, false, false],
  ["School Uniform", 1, false, false, false, false],
  ["Schoolwear", 1, false, false, false, false],
  ["Scissors & Sharpeners", 1, false, false, false, false],
  ["Scooter accessories", 1, false, false, false, false],
  ["Scooters", 2, false, false, false, false],
  ["Scrap metal, fabric, plastic and other materials", null, false, true, true, false],
  ["Screwdriver Sets", 1, false, false, false, false],
  ["Screwdrivers & Hex Keys", 1, false, false, false, false],
  ["Screws and hardware", 1, false, false, false, false],
  ["Sculptures", null, false, true, true, false],
  ["Seat pads", 1, false, false, false, false],
  ["Secateurs", 1, false, false, false, false],
  ["Security Lighting", 2, false, false, false, false],
  ["Security Lights", 2, false, false, false, false],
  ["Seed Feeders", 1, false, false, false, false],
  ["Seeds & Nuts dried and packed", 1, false, false, false, false],
  ["Seeds dried and packed", 1, false, false, false, false],
  ["Seesaws", 2, false, false, false, false],
  ["Seesaws and rockers", 2, false, false, false, false],
  ["Sensor Bins", 1, false, false, false, false],
  ["Sensory toys", 2, false, false, false, false],
  ["Serving bowls and platters breakable", 4, false, false, false, false],
  ["Serving trays", 1, false, false, false, false],
  ["Set top boxes, recorders and satellite", 2, false, false, false, false],
  ["Sewing machine accessories", 1, false, false, false, false],
  ["Sewing machines", 2, false, false, false, false],
  ["Sewing machines and accessories", 2, false, false, false, false],
  ["Shaving accessories", 2, false, false, false, false],
  ["Shears and loppers", 1, false, false, false, false],
  ["Shed and fence paint", 1, false, false, false, false],
  ["Shed bases", 1, false, false, false, false],
  ["Sheds", 1, false, false, false, false],
  ["Sheets", 1, false, false, false, false],
  ["Shelf Units", 1, false, false, false, false],
  ["Shields (PPE)", null, false, true, true, false],
  ["Shipping Containers", null, false, true, true, false],
  ["Shirts, T-shirts and Polos", 1, false, false, false, false],
  ["Shirts, Tunics and Blouses", 1, false, false, false, false],
  ["Shoe storage", 1, false, false, false, false],
  ["Shoes and Boots", 1, false, false, false, false],
  [
    "Shoes excl Furs, Furs Garments, Branded Goods, Fashionwear, Designerwear, Sportwear and Leatherwear",
    1,
    false,
    false,
    false,
    false,
  ],
  ["Shopping trolleys", 1, false, false, false, false],
  ["Shower chairs", 1, false, false, false, false],
  ["Shower Curtains", 1, false, false, false, false],
  ["Shower heads", 1, false, false, false, false],
  ["Shower hoses", 1, false, false, false, false],
  ["Shower poles", 1, false, false, false, false],
  ["Shower screens, enclosures and trays", 1, false, false, false, false],
  ["Shower storage", 1, false, false, false, false],
  ["Showers", 2, false, false, false, false],
  ["Showers Accessories", 1, false, false, false, false],
  ["Shredders", 2, false, false, false, false],
  ["Shuttlecocks", 1, false, false, false, false],
  ["Side tables", 2, false, false, false, false],
  ["Sideboards", 2, false, false, false, false],
  ["Sim cards", null, false, true, true, false],
  ["SIM free phones", null, false, true, true, false],
  ["Skateboard and skate accessories", 2, false, false, false, false],
  ["Skateboards", 2, false, false, false, false],
  ["Skates and inline skates", 2, false, false, false, false],
  ["Skincare treatments", 1, false, false, false, false],
  ["Skins", null, false, true, true, false],
  ["Skipping ropes", 1, false, false, false, false],
  [
    "Slate Blocks individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Slate Slabs individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Slate Tiles individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Sledges", 1, false, false, false, false],
  ["Sleeping bags", 1, false, false, false, false],
  ["Slides", 1, false, false, false, false],
  ["Sliding wardrobes breakable /Fragile", 4, false, false, false, false],
  ["Sliding wardrobes fittings", 1, false, false, false, false],
  ["Slow Cookers", 2, false, false, false, false],
  ["Smart doorbells", 2, false, false, false, false],
  ["Smart heating", 2, false, false, false, false],
  ["Smart home monitoring", 2, false, false, false, false],
  ["Smart Home Security", 2, false, false, false, false],
  ["Smart hubs", 2, false, false, false, false],
  ["Smart light bulbs", 2, false, false, false, false],
  ["Smart lighting", 2, false, false, false, false],
  ["Smart lighting starter kits", 2, false, false, false, false],
  ["Smart plugs", 2, false, false, false, false],
  ["Smart security and CCTV", 2, false, false, false, false],
  ["Smart speakers", 2, false, false, false, false],
  ["Smart Technology products", 2, false, false, false, false],
  ["Smart TV accessories", 2, false, false, false, false],
  ["Smart TV boxes", 2, false, false, false, false],
  ["Smart watches", null, false, true, true, false],
  ["Smoke & CO2 Alarms", 2, false, false, false, false],
  ["Smoke alarms", 2, false, false, false, false],
  ["Snooker cues, balls and accessories", 2, false, false, false, false],
  ["Snooker tables", 2, false, false, false, false],
  ["Soap dispensers", 1, false, false, false, false],
  ["Sockets", 1, false, false, false, false],
  ["Sodastream", 2, false, false, false, false],
  ["Sofa beds", 2, false, false, false, false],
  ["Sofa Sets", 2, false, false, false, false],
  ["Sofas", 2, false, false, false, false],
  ["Sofas & Sofa Beds", 2, false, false, false, false],
  ["Sofas, armchairs and chairs", 2, false, false, false, false],
  ["Software and cartridges", 2, false, false, false, false],
  ["Solar garden lights", 4, false, false, false, false],
  ["Solar lighting", 4, false, false, false, false],
  ["Solar Lights", 4, false, false, false, false],
  ["Solar Panels", 4, false, false, false, false],
  ["Soldering", 1, false, false, false, false],
  ["Sound bars", 2, false, false, false, false],
  ["Soup Makers", 2, false, false, false, false],
  ["Spanner & Socket Sets", 1, false, false, false, false],
  ["Spice racks", 1, false, false, false, false],
  ["Spices dried and packed", 2, false, false, false, false],
  ["Spirits", null, false, true, true, false],
  ["Splashbacks", 1, false, false, false, false],
  ["Sport Equipment", 2, false, false, false, false],
  ["Sports Goods", 2, false, false, false, false],
  ["Sports nutrition items", 1, false, false, false, false],
  ["Sportswear excl Fashion and Designer wear", 2, false, false, false, false],
  ["Spray Guns", 2, false, false, false, false],
  ["Sprinklers", 1, false, false, false, false],
  ["Squash balls", 1, false, false, false, false],
  ["Squash rackets", 2, false, false, false, false],
  ["Stain Removal products", 1, false, false, false, false],
  ["Stain Removers", 1, false, false, false, false],
  ["Stand and hand mixers", 2, false, false, false, false],
  ["Stand mixer attachments", 1, false, false, false, false],
  ["Stand mixers", 2, false, false, false, false],
  ["Staplers & Nailers", 2, false, false, false, false],
  ["Staplers & Riveters", 2, false, false, false, false],
  ["Stationery", 1, false, false, false, false],
  ["Steam Cleaners", 2, false, false, false, false],
  ["Steamers", 2, false, false, false, false],
  ["Steamers and pressers", 2, false, false, false, false],
  ["Steel shipped in bulk", null, false, true, true, false],
  ["Step stools", 1, false, false, false, false],
  ["Sterilisers and cleaning accessories", 2, false, false, false, false],
  [
    "Stone Blocks individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Stone Slabs individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  [
    "Stone Tiles individually separated and suitably packed or protected for transit in crates or cases excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or dropping whilst loading or unloading.",
    2,
    false,
    false,
    false,
    false,
  ],
  ["Stools", 1, false, false, false, false],
  ["Storage", 2, false, false, false, false],
  ["Storage bags", 1, false, false, false, false],
  ["Storage chests", 2, false, false, false, false],
  ["Storage jars and sets", 4, false, false, false, false],
  ["Storage units and drawers", 4, false, false, false, false],
  ["Storage, desks and filing", 4, false, false, false, false],
  ["Strimmers", 2, false, false, false, false],
  ["String instruments", 2, false, false, false, false],
  ["Suet Bird Food", 1, false, false, false, false],
  ["Suet Feeders", 1, false, false, false, false],
  ["Suit carriers", 1, false, false, false, false],
  ["Suitcases", 2, false, false, false, false],
  ["Summer & Play Houses", 2, false, false, false, false],
  ["Summerhouses and log cabins", 2, false, false, false, false],
  ["Sun Loungers & Day Beds", 2, false, false, false, false],
  ["Super soakers and water guns", 1, false, false, false, false],
  ["Superzoom cameras", 2, false, false, false, false],
  ["Support cushions and pads", 1, false, false, false, false],
  ["Swimming equipment", 1, false, false, false, false],
  ["Swimwear", 2, false, false, false, false],
  ["Swing Seats & Hammocks", 1, false, false, false, false],
  ["Swings", 1, false, false, false, false],
  ["Swings, slides and climbing frames", 1, false, false, false, false],
  ["Table cloths and table runners", 1, false, false, false, false],
  ["Table Lamps", 4, false, false, false, false],
  ["Table tennis balls", 1, false, false, false, false],
  ["Table tennis bats", 1, false, false, false, false],
  ["Table tennis tables", 2, false, false, false, false],
  ["Table Top Cooking appliances", 2, false, false, false, false],
  ["Tables", 2, false, false, false, false],
  ["Tablet computers", null, false, true, true, false],
  ["Tableware", 1, false, false, false, false],
  ["Tea & Coffee Making", 1, false, false, false, false],
  ["Tea towels, aprons and oven gloves", 1, false, false, false, false],
  ["Tea, Coffee & Sugar Containers", 1, false, false, false, false],
  ["Teapots breakable", 4, false, false, false, false],
  ["Teapots non breakable", 1, false, false, false, false],
  ["Teddy bears and interactive soft toys", 2, false, false, false, false],
  ["Teddy bears and soft toys", 2, false, false, false, false],
  ["Teeth whitening pastes", 1, false, false, false, false],
  ["Telephone accessories", 1, false, false, false, false],
  ["Telephones and accessories home use", 2, false, false, false, false],
  ["Telephones home use", 2, false, false, false, false],
  ["Telescopes", 2, false, false, false, false],
  ["Televisions excluding Plasma and LCD", 2, false, false, false, false],
  [
    "Temp controlled – Chemicals Deep Frozen -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Temp controlled – Food Deep Frozen -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Temp controlled – Fruit & Vegetables Deep Frozen -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Temp controlled – Pharmaceuticals Deep Frozen -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  ["Temp controlled – Vaccines", null, false, true, true, true],
  ["Temperature controlled Chemicals Products (other than Frozen as above)", null, false, true, true, true],
  [
    "Temperature controlled Frozen Chemical Products -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  [
    "Temperature controlled Frozen Pharmaceutical Products -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit",
    7,
    false,
    false,
    false,
    true,
  ],
  ["Temperature controlled goods (other than frozen foods)", null, false, true, true, true],
  ["Temperature controlled goods (other than frozen)", null, false, true, true, true],
  ["Temperature controlled Pharmaceutical Products (other than Frozen as above)", null, false, true, true, true],
  ["Tent carpets", 1, false, false, false, false],
  ["Tents", 1, false, false, false, false],
  ["Textile Machinery", 2, false, false, false, false],
  ["Textile Piece Goods", 1, false, false, false, false],
  ["Textiles", 1, false, false, false, false],
  ["Thermometers", 2, false, false, false, false],
  ["Thermometers and timers", 2, false, false, false, false],
  ["Timers", 2, false, false, false, false],
  ["Timers & Thermometers", 2, false, false, false, false],
  ["Tin openers", 1, false, false, false, false],
  ["Toasters", 2, false, false, false, false],
  ["Tobacco", null, false, true, true, false],
  ["Toilet brushes", 1, false, false, false, false],
  ["Toilet Cleaners & Bleach", 1, false, false, false, false],
  ["Toilet roll holders", 1, false, false, false, false],
  ["Toilet Seats", 1, false, false, false, false],
  ["Toilets", 4, false, false, false, false],
  ["Toner cartridges", 2, false, false, false, false],
  ["Tool accessories", 1, false, false, false, false],
  ["Tool boxes and tool chests", 2, false, false, false, false],
  ["Tool Storage", 2, false, false, false, false],
  ["Torches", 2, false, false, false, false],
  ["Torches & Lanterns", 2, false, false, false, false],
  ["Tote bags", 1, false, false, false, false],
  ["Touch Bins", 1, false, false, false, false],
  ["Towel Rails & Radiators", 2, false, false, false, false],
  ["Towel rails and rings", 2, false, false, false, false],
  ["Towels", 1, false, false, false, false],
  ["Towels & Bath Mats", 1, false, false, false, false],
  ["Toy boats", 2, false, false, false, false],
  ["Toy cars, trains, boats and planes", 2, false, false, false, false],
  ["Toy cars, vehicles and sets", 2, false, false, false, false],
  ["Toy craft kits", 2, false, false, false, false],
  ["Toy garden tools and accessories", 2, false, false, false, false],
  ["Toy planes and helicopters", 2, false, false, false, false],
  ["Toy trains", 2, false, false, false, false],
  ["Toys", 2, false, false, false, false],
  ["Toys & Games", 2, false, false, false, false],
  ["Trading cards", 1, false, false, false, false],
  ["Training gloves", 1, false, false, false, false],
  ["Trampolines and accessories", 1, false, false, false, false],
  ["Trampolines and enclosures", 1, false, false, false, false],
  ["Travel Accessories", 2, false, false, false, false],
  ["Travel adaptors", 1, false, false, false, false],
  ["Travel cots", 1, false, false, false, false],
  ["Travel locks", 1, false, false, false, false],
  ["Travel Mugs & Flasks breackable", 4, false, false, false, false],
  ["Travel Mugs & Flasks non breackable", 1, false, false, false, false],
  ["Travel pillows", 1, false, false, false, false],
  ["Trays", 1, false, false, false, false],
  ["Treadmills", 2, false, false, false, false],
  ["Trikes", 2, false, false, false, false],
  ["Trinkets", null, false, true, true, false],
  ["Tripods, monopods and cases", 2, false, false, false, false],
  ["Trolleys & Servers", 2, false, false, false, false],
  ["T-shirts and shirts", 1, false, false, false, false],
  ["Tumble Dryers", 2, false, false, false, false],
  ["Tuners and repair kits", 1, false, false, false, false],
  ["TV & Audio Accessories", 1, false, false, false, false],
  ["TV aerial boosters", 1, false, false, false, false],
  ["TV aerials cables and accessories", 1, false, false, false, false],
  ["TV aerials, boosters and accessories", 1, false, false, false, false],
  ["TV Frames", 1, false, false, false, false],
  ["TV remote controls", 1, false, false, false, false],
  ["TV screens excluding Plasma and LCD", 2, false, false, false, false],
  ["TV stands and wall brackets", 1, false, false, false, false],
  ["TV wall bracket accessories", 1, false, false, false, false],
  ["TV wall brackets", 1, false, false, false, false],
  ["TV's excluding Plasma and LCD", 2, false, false, false, false],
  ["Two way radios", 2, false, false, false, false],
  ["Typewriters", 2, false, false, false, false],
  ["Ukuleles excluding antiques", 2, false, false, false, false],
  ["Underwear, Socks and Nightwear", 1, false, false, false, false],
  ["USB hubs", 2, false, false, false, false],
  ["USB Microphones", 2, false, false, false, false],
  ["USB storage", 1, false, false, false, false],
  ["USB Wi-Fi adapters", 2, false, false, false, false],
  ["Vacuum cleaner accessories", 1, false, false, false, false],
  ["Vacuum Cleaner Bags", 1, false, false, false, false],
  ["Vacuum Cleaners", 2, false, false, false, false],
  ["Valances", 1, false, false, false, false],
  ["Vanity units", 2, false, false, false, false],
  ["Vape accessories", null, false, true, true, false],
  ["Vape mods", null, false, true, true, false],
  ["Vaping and stopping smoking", null, false, true, true, false],
  ["Vases", 4, false, false, false, false],
  ["Video game consoles", null, false, true, true, false],
  ["Video games", null, false, true, true, false],
  ["Video Games and Associated items.", null, false, true, true, false],
  ["Video Recorders", 2, false, false, false, false],
  ["Violins excluding antiques", 2, false, false, false, false],
  ["Virtual Reality Headsets", 2, false, false, false, false],
  ["Vitamins and supplements", 1, false, false, false, false],
  ["Walking frames", 1, false, false, false, false],
  ["Walking sticks", 1, false, false, false, false],
  ["Wall Art", null, false, true, true, false],
  ["Wall art and canvases", null, false, true, true, false],
  ["Wall art and pictures", null, false, true, true, false],
  ["Wall Lights", 4, false, false, false, false],
  ["Wall mounted and floating shelves", 1, false, false, false, false],
  ["Wallpaper", 1, false, false, false, false],
  ["Wallpaper strippers", 1, false, false, false, false],
  ["Wardrobes", 2, false, false, false, false],
  ["Washer Dryers", 2, false, false, false, false],
  ["Washing lines", 1, false, false, false, false],
  ["Washing Machines", 2, false, false, false, false],
  ["Washing Machines packed in cartons", 2, false, false, false, false],
  ["Washing Machines packed in cases", 2, false, false, false, false],
  ["Washing Machines packed in waterproof crates", 2, false, false, false, false],
  ["Watches", null, false, true, true, false],
  ["Water bottles breakable", 4, false, false, false, false],
  ["Water bottles non breakable", 1, false, false, false, false],
  ["Water butts", 1, false, false, false, false],
  ["Water carriers", 1, false, false, false, false],
  ["Water Features", 2, false, false, false, false],
  ["Water Filters", 1, false, false, false, false],
  ["Water filters and cartridges", 1, false, false, false, false],
  ["Water jugs", 2, false, false, false, false],
  ["Water slides", 2, false, false, false, false],
  ["Water toys and games", 2, false, false, false, false],
  ["Watering cans", 1, false, false, false, false],
  ["Watering Cans & Sprayers", 1, false, false, false, false],
  ["Waterproof cameras", 2, false, false, false, false],
  ["Waterproof jackets and coats", 2, false, false, false, false],
  ["Wax in Drums, Sacks, Cartons, Boxes or Bags", 1, false, false, false, false],
  ["Wax in ISO Containers (Tank Containers) or Containerized Super Sacks", 2, true, false, false, false],
  ["Waxing kits and hair removal accessories", 1, false, false, false, false],
  ["Webcams", 2, false, false, false, false],
  ["Weedkillers", 1, false, false, false, false],
  ["Weightlifting bars", 1, false, false, false, false],
  ["Weightlifting belts", 1, false, false, false, false],
  ["Wheel trims", 1, false, false, false, false],
  ["Wheelbarrows", 1, false, false, false, false],
  ["Wheelchair accessories", 1, false, false, false, false],
  ["Wheelchairs", 1, false, false, false, false],
  ["Wheeled toys", 2, false, false, false, false],
  ["White Goods packed in cartons", 2, false, false, false, false],
  ["White Goods packed in cases", 2, false, false, false, false],
  ["White Goods packed in waterproof crates", 2, false, false, false, false],
  ["Wi-Fi boosters", 2, false, false, false, false],
  ["Wi-Fi routers", 2, false, false, false, false],
  ["Wildlife and bird supplies", 1, false, false, false, false],
  ["Windbreaks", 1, false, false, false, false],
  ["Window Cleaning products and accessories", 1, false, false, false, false],
  ["Window frames", 2, false, false, false, false],
  ["Window Glass", 4, false, false, false, false],
  ["Window Vacs", 2, false, false, false, false],
  ["Wine", null, false, true, true, false],
  ["Wine & Drink Coolers", 2, false, false, false, false],
  ["Wine Racks", 1, false, false, false, false],
  ["Wireless and Bluetooth speakers", 2, false, false, false, false],
  ["Woks", 2, false, false, false, false],
  ["Women Loungewear", 1, false, false, false, false],
  ["Womens Accessories", 1, false, false, false, false],
  ["Womens Blazers", 1, false, false, false, false],
  ["Womens bracelets and bangles", null, false, true, true, false],
  ["Womens Bras", 1, false, false, false, false],
  ["Womens Cardigans", 1, false, false, false, false],
  ["Womens charm bracelets and beads", null, false, true, true, false],
  ["Womens Clothes", 1, false, false, false, false],
  ["Womens Coats", 1, false, false, false, false],
  ["Womens Dresses", 1, false, false, false, false],
  ["Womens earrings", null, false, true, true, false],
  ["Womens hair removal and waxing", 1, false, false, false, false],
  ["Womens Jackets", 1, false, false, false, false],
  ["Womens Jeans", 1, false, false, false, false],
  ["Womens Jewellery", null, false, true, true, false],
  ["Womens jewellery precious metals and precious or semi precious stones", null, false, true, true, false],
  ["Womens jewellery sets precious metals and precious or semi precious stones", null, false, true, true, false],
  ["Womens Jogging Trousers and Leggings", 1, false, false, false, false],
  ["Womens Jumpers", 1, false, false, false, false],
  ["Womens Jumpsuits", 1, false, false, false, false],
  ["Womens Knickers", 1, false, false, false, false],
  ["Womens Leggings", 1, false, false, false, false],
  ["Womens Lingerie Sets", 1, false, false, false, false],
  ["Womens necklaces", null, false, true, true, false],
  ["Womens Nightwear", 1, false, false, false, false],
  ["Womens rings", null, false, true, true, false],
  ["Womens shapewear", 1, false, false, false, false],
  ["Womens Shirts and Blouses", 1, false, false, false, false],
  ["Womens Shoes and Boots", 1, false, false, false, false],
  ["Womens Shorts", 1, false, false, false, false],
  ["Womens Skirts", 1, false, false, false, false],
  ["Womens Socks and Tights", 1, false, false, false, false],
  ["Womens Sports Tops", 1, false, false, false, false],
  ["Womens Sweatshirts", 1, false, false, false, false],
  ["Womens Swimwear", 1, false, false, false, false],
  ["Womens Tops and Tunics", 1, false, false, false, false],
  ["Womens trimmers", 1, false, false, false, false],
  ["Womens Trousers", 1, false, false, false, false],
  ["Womens T-shirts", 1, false, false, false, false],
  ["Womens watches", null, false, true, true, false],
  ["Womens wedding rings and bands", null, false, true, true, false],
  ["Wood and Timber packed", 2, false, false, false, false],
  ["Wood and Timber unpacked", null, false, true, true, false],
  ["Wooden toys", 2, false, false, false, false],
  ["Woodwind instruments", 2, false, false, false, false],
  ["Workbenches", 1, false, false, false, false],
  ["Works of art", null, false, true, true, false],
  ["Workwear & Safety", 1, false, false, false, false],
  ["Wrenches", 1, false, false, false, false],
  ["Xbox accessories excluding consoles and games", 2, false, false, false, false],
  ["Xbox consoles", null, false, true, true, false],
  ["Xbox Games", null, false, true, true, false],
  ["Yachts provided new and in fully enclosed containers", 2, false, false, false, false],
  ["Yachts provided new and in fully enclosed trucks", 2, false, false, false, false],
  ["Yachts used and in fully enclosed in containers", 2, false, false, false, false],
  ["Yachts used and in fully enclosed in trucks", 2, false, false, false, false],
  ["Yarn", 1, false, false, false, false],
] as const;
