import React, { FC } from "react";
import { parseSubmission } from "../../parseSubmission";
import { PolicyDocumentProps } from "../../types";
import { Annual_own_vehicle_copy } from "./4_0_Annual_own_vehicle_copy";
import { Annual_exhibition } from "./5_1_Annual_exhibition";

export const Annual_Summary_of_coverage: FC<PolicyDocumentProps> = ({ quote }) => {
  const { storage, samples, cargo, conveyance, shipment, exhibition } = parseSubmission(
    quote.submission,
    "cargo-us-annual",
  );

  return (
    <section aria-labelledby="summaryOfCoverage">
      <h1 id="summaryOfCoverage">SUMMARY OF COVERAGE</h1>

      <h2>Summary of Important Insurance Terms</h2>

      <p>
        Please note that this summary is merely intended to provide an explanation of some of the important insurance
        terms applicable and does not alter the terms & conditions of insurance whatsoever. For full details of the
        cover, please refer to the actual clauses as stated and all conditions.
      </p>

      <p>
        You have stated that the Interest to be insured is: {cargo.condition} and{" "}
        {cargo.cargos?.map((cargo) => cargo.type).join(" or ")} Transported by or as{" "}
        {conveyance.conveyances
          ?.map(({ type, containerType, aovTransits }) => {
            if (type === "Sea") return `${type} ${containerType}`;
            if (type === "Road only") return `Road ${aovTransits ? "including" : "excluding"} Any Own Vehicles`;
            if (type === "Air only") return `Air`;
            if (type === "Rail only") return `Rail`;

            return type;
          })
          .join(" &/or ")}
      </p>

      <p>From USA {shipment.inlandTransit ? "including" : "excluding"} transits within USA to/from:</p>

      {shipment.shipments?.some((shipment) => shipment.country) && (
        <table className="styled nested" data-testid="shipments-country">
          <colgroup>
            <col width="20%" />
            <col width="auto" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>Country</th>
              <th>With Own Cargo?</th>
              <th>With Contingent Cargo?</th>
            </tr>
          </thead>
          <tbody>
            {shipment.shipments
              ?.filter((shipment) => shipment.country)
              ?.map((shipment, index) => (
                <tr key={index?.toString()}>
                  <td>{shipment?.country}</td>
                  <td>{shipment?.ownTurnover?.amount > 0 ? "Yes" : "No"}</td>
                  <td>{shipment?.contingentTurnover?.amount > 0 ? "Yes" : "No"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {shipment.shipments?.some((shipment) => !shipment.country) && (
        <table className="styled nested" data-testid="shipments-region">
          <colgroup>
            <col width="20%" />
            <col width="auto" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>Region</th>
              <th>With Own Cargo?</th>
              <th>With Contingent Cargo?</th>
            </tr>
          </thead>
          <tbody>
            {shipment.shipments
              ?.filter((shipment) => !shipment.country)
              ?.map((shipment, index) => (
                <tr key={index?.toString()}>
                  <td>{shipment?.region}</td>
                  <td>{shipment?.ownTurnover?.amount > 0 ? "Yes" : "No"}</td>
                  <td>{shipment?.contingentTurnover?.amount > 0 ? "Yes" : "No"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {shipment.shipments?.some((shipment) => shipment?.contingentTurnover?.amount > 0) && (
        <p>Including contingent interest within the above voyages.</p>
      )}

      {cargo.condition === "Used" || cargo.iccClause ? (
        <>
          <p>
            The Institute Cargo Clauses (C) offer a restricted form of cover against perils that by their nature, might
            likely result in a total loss of the cargo. The major perils covered are:
          </p>

          <ul>
            <li>Fire or explosion</li>
            <li>Vessel or craft being stranded grounded sunk or capsized</li>
            <li>Overturning or derailment of land conveyance</li>
            <li>Collision or contact of vessel craft or conveyance with any external object other than water</li>
            <li>Discharge of cargo at a port of distress,</li>
            <li>Loss of or damage to the subject-matter insured caused by</li>
            <li>General average sacrifice</li>
            <li>Jettison</li>
          </ul>
          <br />
        </>
      ) : (
        <>
          <p>
            The Institute Cargo Clauses (A) or (Air) are normally the widest form of cover available and afford cover
            against “All Risks” of physical loss or damage to the goods by accidental causes during the ordinary course
            of transit as well as cover for General Average.
          </p>

          <p>
            The clauses contain a standard list of exclusions of which the principal exceptions are loss due to: delay,
            the inherent nature of the goods, insufficient or inadequate packing, ordinary wear and tear, consequential
            loss or loss of market.
          </p>
        </>
      )}
      <p>
        <b>War & Strikes</b>
        <br />
        Where this policy is extended to include the Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo)
        or the applicable Air clauses; which cover the goods against loss of or damage caused by warlike activities
        whilst at sea or on the aircraft and/or caused by rioters during civil commotions and strikes or terrorist or
        politically motivated acts. The basic exclusions apply and it should be noted that the strikes element does not
        include expense incurred where the goods are diverted from a strike bound port or from a strike bound airport.
      </p>
      {cargo.cargos?.some((c) => c?.group === 6) && (
        <p>MOTOR VEHICLES – subject to the Motor Vehicle Conditions as attached Warranted shipped underdeck.</p>
      )}
      {cargo.cargos?.some((c) => c?.group === 5) && (
        <p>
          HOUSEHOLD GOOD AND PERSONAL EFFECTS – Subject to the Household Goods and Personal Effects Conditions as
          attached
        </p>
      )}
      <p>
        <b>Duration</b>
        <br />
        The cover attaches from the time the goods are first moved in the warehouse or place of storage named in the
        contract of insurance for the immediate loading into the carrying conveyance for the commencement of transit,
        continues during the ordinary sea voyage or air transit and terminates either on completion of unloading from
        the carrying vehicle at the destination (or elected storage location) or on the expiry of 60 days after
        completion of discharge overside from the overseas vessel or on the expiry of 30 days after completion of
        discharge from the aircraft, whichever occurs first.{" "}
        {storage.hasStorages &&
          "In respect of goods in your storage locations agreed, cover commences at the time of commencement of this policy and shall terminate at the date of cancellation of the policy in respect of all goods in store at that time."}
      </p>

      {!cargo.iccClause && cargo.condition === "New" && (
        <p>It is a condition of this insurance that the interest insured is in a brand new condition.</p>
      )}

      {!cargo.iccClause && cargo.condition === "Fully reconditioned to a new standard" && (
        <p>It is a condition of this insurance that the interest insured is in a fully Reconditioned condition.</p>
      )}

      <p>
        Deductible: Where a deductible is stated, this is the amount deducted by underwriters from any recoverable claim
        that is submitted.
      </p>

      <p>
        Imports and Exports Basis of Valuation {cargo.basisOfValuation}{" "}
        {cargo.basisOfValuationPercentage !== undefined &&
          "plus increased value by reason of Duty, Excise, Surcharge and/or Landing and similar charges, if incurred provided declared"}
      </p>

      {shipment.inlandTransit && (
        <p>
          In respect of Inland Transits the following Basis of Valuations apply:
          <br />
          Goods with an agreed sales contract – Sales Invoice Price
          <br />
          Goods with no sales contract agreed – Replacement Cost to the Assured
          <br />
          Secondhand and/or used goods with no sales contract agreed – 2nd Hand Market Value
        </p>
      )}

      {samples.hasSamples && (
        <p>
          In respect of Samples or goods to / from or on Exhibition the following Basis of Valuation applies:
          <br />
          Replacement Cost to the Assured less an allowance deprecation for fair wear & tear if applicable
        </p>
      )}

      {storage.hasStorages && (
        <p>
          In respect of goods in agreed storage locations the following Basis of Valuations apply:
          <br />
          Goods with an agreed sales contract – Sales Invoice Price
          <br />
          Goods with no sales contract agreed – Replacement Cost to the Assured
          <br />
          Secondhand and/or used goods with no sales contract agreed – 2nd Hand Market Value
        </p>
      )}

      <p>
        <b>Debris Removal Clause</b>
        <br />
        This insurance is extended to cover, in addition to any other amount recoverable under this insurance, extra
        expenses reasonably incurred by the Assured for the removal and disposal of debris of the subject matter
        insured, or part thereof, by reason of damage thereto caused by an insured risk, but excluding absolutely:
        <br />
        - any expenses incurred in consequence of or to prevent or mitigate pollution or contamination, or any threat or
        liability thereof
        <br />
        - the cost of removal of cargo from any vessel or craft
        <br />
        In no case shall the insurers be liable under this clause for more than 10% in all of the proportionate insured
        value under this policy of the damaged subject-matter removed.
      </p>

      <p>
        <b>Cuttings Clause</b>
        <br />
        Applicable to items where dimensions are not imperative.
        <br />
        In the event of damage or breakage caused by an insured peril it is agreed that the damaged or broken length or
        portion shall be cut off, the remaining length or portion to be considered as sound and the Underwriters only to
        be liable for the insured value of the length or portion which has been lost by being broken off or cut off and
        for the cost of cutting.
      </p>

      <p>
        Electrical & Mechanical Derangement: Excluded unless caused by a peril insured against under the terms of this
        policy and there is evidence of an external damage.
      </p>

      <p>
        ITEMS IN NON-WATERPROOF CRATES OR PACKAGING Excluding Rust, Oxidation, Discoloration, Wetting, Staining and the
        Cost of Repainting as applicable.
      </p>

      <p>
        Law and Practice: UK risks - This insurance is subject to English law and practice and to the exclusive
        jurisdiction of the English Courts sitting in London. Non UK risks - This insurance is subject to English law
        and practice.
      </p>

      <p>
        Loss of Data: In respect of electronic or computerised items: Including loss or damage for installed or other
        included software such as operating systems or office suites but excluding claims for loss of data or any other
        proprietary information.
      </p>

      <p>
        Onus of Proof: The onus of proof rests with the Assured to show that loss of or damage to the subject matter
        insured occurred within the certificate of marine cargo insurance period of cover and occurred within the scope
        and ambit of the Policy.
      </p>

      <p>
        Open Top Containers: In respect of goods in open top containers: Excluding claims for loss or damage to any part
        or parts of the subject matter that protrude outside of the boundaries of the top of an open top container
        unless following a peril insured under the terms of the Institute Cargo Clauses (B) CL 383 dated 1.1.2009
      </p>

      <p>Communicable Diseases: Excluding losses attributable to communicable diseases and other organisms.</p>

      <p>
        Dried foodstuffs in bags & sacks: Excluding Heating and Sweating Excluding Infestation arising from weevil, grub
        or web. Excluding Rejection Risks. Excluding any Natural loss in Weight. Warranted shipped in ventilated
        container(s)
      </p>

      <p>
        Stone, marble, slate, granite and similar slabs: Warranted slabs are individually separated and suitably packed
        or protected for transit in crates or cases. Excluding breakage, chipping, splitting or cracking unless caused
        by an accident to the carrying conveyance or dropping whilst loading or unloading.
      </p>

      <p>
        Temperature Variation - does not apply to goods shipped at -15 (minus fifteen) degrees Celsius or 5 (five)
        degrees Fahrenheit:
        <br />
        Excluding claims for loss, damage or change in the nature of the subject matter insured caused by variation in
        temperature unless caused by a peril insured under the terms of the Institute Cargo Clauses (B) CL383 dated
        1.1.2009.
      </p>

      <p>
        Used goods: Subject to the Secondhand Replacement Clause as follows: In the event of a claim for loss of or
        damage to any part or parts of the subject matter insured, in consequence of a peril covered by the policy, the
        amount recoverable hereunder shall not exceed such proportion of the cost of replacement of the part or parts
        lost or damaged as the insured value bears to the value of a new machine plus additional charges for forwarding
        and refitting the new part or parts if incurred. Provided always that in no case shall the liability of
        Underwriters exceed the insured value of the complete article.
      </p>

      <p>
        Reconditioned Goods and / or Refurbished Used Goods: Warranted that the Interest insured is fully refurbished /
        reconditioned to a new standard. Excluding Rust, Oxidation, Discoloration, Wetting, Staining, Scratching,
        Bruising Chipping, Denting, Marring and the Cost of Repainting as applicable unless caused by an Insured peril.
        Subject to the Secondhand Replacement Clause as above.
      </p>

      <p>
        Yachts and other boats: Warranted new and transported in fully enclosed containers or trucks. Excluding loss or
        damage to accessories or portable items unless declared prior to shipment and they are suitably packed or
        protected for transit. Excluding theft or pilferage of audio, electronic communication or display equipment,
        entertainment or computer or navigational equipment and similar items unless stolen with the craft. Warranted
        that unpacked craft are suitably secured to a trailer or cradle.
      </p>

      {conveyance.conveyances?.some((conveyance) => conveyance.aovTransits) && <Annual_own_vehicle_copy />}
      {exhibition.hasExhibitions && <Annual_exhibition />}

      <p>
        Unpacked or Unprotected items: Excluding Rust, Oxidation, Discoloration, Wetting, Staining, Scratching,
        Bruising, Chipping, Denting, Marring and the Cost of Repainting as applicable
      </p>

      {conveyance.conveyances?.some((conveyance) => conveyance.aovTransits) && (
        <p>
          All carryings by vehicles owned, operated or under the control of the Assured subject to security conditions
          and theft restrictions as per Own Vehicle Security Clause.
        </p>
      )}

      {samples.hasSamples && (
        <p>
          Samples: Including tradesman’s tools and / or samples in vehicles owned, leased or hired by the Assured and
          subject to conditions as per the Tools and Samples Clause.
        </p>
      )}

      {exhibition.hasExhibitions && (
        <p>
          Exhibitions: Including cover at exhibitions in Geographical areas agreed and return transit subject to
          conditions as per Exhibitions Clause.
        </p>
      )}

      {storage.hasStorages && (
        <p>Storage: Including storage at locations agreed and subject to conditions as per Storage Clause.</p>
      )}

      <p>
        <b>Cancellation</b>
        <br />
        The insurance as evidenced by the marine cargo insurance policy may be cancelled at any time prior to
        commencement of the risk but is always subject to 30 days&apos; notice of cancellation from either party at any
        time for Marine risks, 7 days&apos; notice (48 hours&apos; notice in respect of sendings to or from USA) of
        cancellation for War, Strikes, Riots and Civil Commotions risks. Cancellation shall become effective on the
        expiry of the appropriate number of days from midnight on the day on which notice of cancellation is issued by
        Assured or by Underwriters but shall not apply to any transit insurance which shall have been declared or
        attached in accordance with contract terms and conditions before the cancellation becomes effective.
      </p>

      <p>
        <b>Claims</b>
        <br />
        In the event of a claim there are clear instructions stated on the marine cargo insurance policy for the
        procedure to be undertaken, including documentation required. Information regarding a surveyor if necessary is
        also shown. The claims matter may be referred directly to Underwriters at any time.
      </p>

      <p>
        Complaints: We are committed to treating our customers fairly at all times. Please see the policy document for
        our full Complaints notices and procedures.
      </p>
    </section>
  );
};
