import React, { FC } from "react";

export const Single_exhibition: FC = () => {
  return (
    <section aria-labelledby="exhibition">
      <p>
        <strong>EXHIBITION CLAUSE</strong>
        <br />
        When specifically agreed this insurance is extended to cover the insured property whilst in transit to and from
        the exhibition site, whilst the insured property remains on such premises during unpacking, assembly, awaiting
        and on exhibition, dismantling and repacking.
      </p>
      <p>Notwithstanding anything contained herein to the contrary, this insurance excludes:</p>

      <ol>
        <li>
          <p style={{ marginLeft: "2em" }}>
            Wear, tear and\or any gradual deterioration of the subject matter insured.
          </p>
        </li>
        <li>
          <p style={{ marginLeft: "2em" }}>
            Loss or damage arising from latent defect or arising from faulty assembly or construction.
          </p>
        </li>
        <li>
          <p style={{ marginLeft: "2em" }}>
            Loss or damage resulting directly from mechanical, electrical or manual operation of the insured property
            for demonstration or other purposes.
          </p>
        </li>
        <li>
          <p style={{ marginLeft: "2em" }}>Theft and/or pilferage from unattended exhibition stand.</p>
        </li>
        <li>
          <p style={{ marginLeft: "2em" }}>
            Theft and/or pilferage from unattended exhibition site unless following visible signs of forcible and
            violent entry in to and/or exit from the venue.
          </p>
        </li>
        <li>
          <p style={{ marginLeft: "2em" }}>Loss of life and/or personal injury.</p>
        </li>
      </ol>
      <p>
        Warranted all items repacked to not less than the same standard as for the outward journey and the repacking is
        supervised by a responsible official of the Assured.
      </p>
    </section>
  );
};
