import { RaterStep } from "../../../@types/types";
import { inRange } from "../../../helpers/inRange";
import { assertIsDefined } from "../helpers";
import { parseSubmission } from "../parseSubmission";
import table2b from "../refData/single/table2b";
import table3 from "../refData/single/table3";
import table5a from "../refData/single/table5a";
import table6 from "../refData/single/table6";
import {
  CargoDeductible,
  CargoDeductibleAmount,
  CargoGroup,
  CargoRaterOutput,
  ClientTrade,
  ContainerType,
  Conveyance,
  ConveyanceType,
  Exhibition,
  ExhibitionDuration,
  Percentage,
  Region,
  RegionRank,
  VoyageCode,
} from "../types";
import { premiumCommissionCalculation } from "./helpers";

/**
 * Table 1a: Own Domicile
 */
const getDomicileFactor = (domicile: Region): number => {
  if (domicile === "Western Europe") return 1;
  if (domicile === "USA & Canada") return 1;
  if (domicile === "Asia & Pacific including Australasia") return 1.05;
  if (domicile === "Eastern Europe & Russia West of Urals") return 1.15;
  if (domicile === "Middle East") return 1.1;
  if (domicile === "Caribbean") return 1.2;
  if (domicile === "South & Central America") return 1.2;
  if (domicile === "Former USSR and Russia East of Urals") return 1.2;
  if (domicile === "India and sub continent") return 1.5;
  if (domicile === "Africa") return 1.5;

  throw new Error(`Unable to find domicile factor. Received: ${JSON.stringify({ domicile })}`);
};

/**
 * Table 1b: Type of Client
 */
const getTypeOfClientFactor = (clientTrade: ClientTrade): number => {
  if (clientTrade === "Importer") return 1;
  if (clientTrade === "Exporter") return 1;
  if (clientTrade === "Manufacturer") return 1;
  if (clientTrade === "Trader") return 1.25;
  if (clientTrade === "Private Individual") return 1.5;

  throw new Error(`Unable to find type of client factor. Received: ${JSON.stringify({ clientTrade })}`);
};

/**
 * Table 2a: Voyage Country Region
 */
const getRegionRank = (region: Region): RegionRank => {
  if (region === "Western Europe") return 1;
  if (region === "USA & Canada") return 1;
  if (region === "Asia & Pacific including Australasia") return 2;
  if (region === "Eastern Europe & Russia West of Urals") return 3;
  if (region === "Middle East") return 2;
  if (region === "Caribbean") return 4;
  if (region === "South & Central America") return 5;
  if (region === "Former USSR and Russia East of Urals") return 5;
  if (region === "India and sub continent") return 6;
  if (region === "Africa") return 6;

  throw new Error(`Unable to find region rank. Received: ${JSON.stringify({ region })}`);
};

/**
 * Table 2b: Voyage Code
 */
const getVoyageCode = (regionFrom: RegionRank, regionTo: RegionRank): VoyageCode => {
  const result = table2b?.[regionTo - 1]?.[regionFrom - 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find voyage code. Received: ${JSON.stringify({ regionFrom, regionTo })}`);
};

/**
 * Table 3: Voyage Rate
 */
const getVoyageRate = (group: CargoGroup, code: VoyageCode): number => {
  assertIsDefined(group, "group");

  const codes = ["A", "B", "C", "D", "E", "F", "G"];
  const result = table3?.[group - 1]?.[codes.indexOf(code)];

  if (result !== undefined) return result;

  throw new Error(`Unable to find voyage rate. Received: ${JSON.stringify({ group, code })}`);
};

/**
 * Table 4a: Table 4a: Conveyance Discount/Load
 */
const getDiscountLoad = (
  conveyanceType: ConveyanceType,
  aovTransits?: boolean,
  containerType?: ContainerType,
): Percentage => {
  if (conveyanceType === "Sea" && containerType == "Breakbulk") return 1;
  if (conveyanceType === "Sea" && containerType === "FCL") return -0.25;
  if (conveyanceType === "Air only") return -0.40;
  if (conveyanceType === "Road only" && aovTransits) return 0.25;
  if (conveyanceType === "Road only" && !aovTransits) return -0.50;
  if (conveyanceType === "Rail only") return -0.50;
  if (conveyanceType === "Sea" && containerType === "LCL") return -0.10;

  throw new Error(
    `Unable to find discount/load. Received: ${JSON.stringify({
      conveyanceType,
      containerType,
      aovTransits,
    })}`,
  );
};

/**
 * Table 4b ICC additional discount
 */
const getAdditionalDiscount = (
  conveyanceType: ConveyanceType,
  aovTransits?: boolean,
  containerType?: ContainerType,
): Percentage => {
  if (conveyanceType === "Sea" && containerType == "Breakbulk") return 1;
  if (conveyanceType === "Sea" && containerType === "FCL") return -0.40;
  if (conveyanceType === "Air only") return -0.50;
  if (conveyanceType === "Road only" && aovTransits) return -0.30;
  if (conveyanceType === "Road only" && !aovTransits) return -0.30;
  if (conveyanceType === "Rail only") return -0.20;
  if (conveyanceType === "Sea" && containerType === "LCL") return -0.40;

  throw new Error(
    `Unable to find additional discount. Received: ${JSON.stringify({
      conveyanceType,
      containerType,
      aovTransits,
    })}`,
  );
};

/**
 * Table 6
 */
const getExhibitionRate = (code: RegionRank, group: CargoGroup): number => {
  assertIsDefined(group, "group");

  const result = table6?.[code - 1]?.[group - 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find exhibition rate. Received: ${JSON.stringify({ group, code })}`);
};

/**
 * Table 6a
 */
const getExhibitionsRank = (exhibitionDuration: ExhibitionDuration): Percentage => {
  if (exhibitionDuration === "< 7 days") return 1;
  if (exhibitionDuration === "8-14 days") return 1.35;
  if (exhibitionDuration === "15-30 days") return 2;

  throw new Error(`Unable to find exhibitions rank. Received: ${JSON.stringify({ exhibitionDuration })}`);
};

/**
 * Table 8: Claims Adjustment Factor
 */
const getClaimsAdjustmentFactor = (losses: number): number => {
  if (inRange(losses, 0, 0.1)) return 1.1;
  if (inRange(losses, 0.1001, 0.2)) return 1.25;
  if (inRange(losses, 0.2001, 0.5)) return 1.5;
  if (inRange(losses, 0.5001, 0.75)) return 1.8;
  if (inRange(losses, 0.7501, 1)) return 0;

  throw new Error(`Unable to find claims adjustment factor. Received: ${JSON.stringify({ losses })}`);
};

/**
 * Table 9: Cargo Type / Deductible Factor
 */
const getCargoDeductibleFactor = (group: CargoGroup, deductible: CargoDeductible): number => {
  const index = [250, 500, 750, 1_000, 1_500, 2_000, 2_500, 5_000].findIndex((item) => item === deductible?.amount);
  const result = table5a.find((item) => item[0] === group)?.[index + 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find cargo deductible factor. Received: ${JSON.stringify({ group, deductible })}`);
};

/**
 * Table 9: Cargo Type / Deductible Factor
 */
export const getCargoDefaultDeductible = (group: CargoGroup): CargoDeductibleAmount => {
  if (group === 1) return 250;
  if (group === 2) return 500;
  if (group === 3) return 750;
  if (group === 4) return 750;
  if (group === 5) return 500;
  if (group === 6) return 500;
  if (group === 7) return 500;

  throw new Error(`Unable to find cargo default deductible. Received: ${JSON.stringify({ group })}`);
};

/**
 * Table 10: Min Premium
 */
const getMinPremium = (group: CargoGroup): number => {
  if (group === 1) return 75;
  if (group === 2) return 100;
  if (group === 3) return 150;
  if (group === 4) return 200;
  if (group === 5) return 175;
  if (group === 6) return 175;
  if (group === 7) return 100;

  throw new Error(`Unable to find min premium. Received: ${JSON.stringify({ group })}`);
};

const convertToFloat = (x: number, digits: 2 | 3 | 4): number => {
  if (!Number.isFinite(x)) {
    return 0;
  }

  if (digits === 4) {
    // return parseInt((x * 10000).toString()) / 10000;
    return Number(x.toFixed(digits));
  }
  if (digits === 3) {
    // return parseInt((x * 1000).toString()) / 1000;
    return Number(x.toFixed(digits));
  }
  return Number(x.toFixed(digits));
  // return parseInt((x * 100).toString()) / 100;
};

export const getRates = (
  submission: unknown,
  commission?: number,
  transitPremiumTax?: number,
  storagePremiumTax?: number,
  exhibitionsPremiumTax?: number,
  samplesPremiumTax?: number,
): CargoRaterOutput => {
  const parsedSubmission = parseSubmission(submission, "cargo-us-single");
  const { shipment, cargo, conveyance, exhibition, insured } = parsedSubmission;

  const breakdown: RaterStep[] = [];

  /*
  Step 1
  Determine Client Trade Factor
  */
  breakdown.push(["Step 1", "-"]);

  assertIsDefined(insured.region, "insured.region");
  const domicileFactor = getDomicileFactor(insured.region);

  assertIsDefined(insured.clientTrade, "insured.clientTrade");
  const typeOfClient = getTypeOfClientFactor(insured.clientTrade);

  const clientTradeFactor = typeOfClient * domicileFactor;

  breakdown.push(["domicileFactor", domicileFactor]);
  breakdown.push(["typeOfClient", typeOfClient]);
  breakdown.push(["clientTradeFactor", clientTradeFactor]);

  /*
  Step 2
  Determine the Voyage Code
  */
  breakdown.push(["Step 2", "-"]);

  assertIsDefined(shipment.regionFrom, "shipment.regionFrom");
  assertIsDefined(shipment.regionTo, "shipment.regionTo");

  const regionFromRank = getRegionRank(shipment.regionFrom);
  const regionToRank = getRegionRank(shipment.regionTo);
  const voyageCode = getVoyageCode(regionFromRank, regionToRank);

  breakdown.push(["regionFrom", shipment.regionFrom]);
  breakdown.push(["regionTo", shipment.regionTo]);
  breakdown.push(["regionFromRank", regionFromRank]);
  breakdown.push(["regionToRank", regionToRank]);
  breakdown.push(["voyageCode", voyageCode]);

  /*
  Step 3
  Determine Voyage Rate
  */
  breakdown.push(["Step 3", "-"]);

  assertIsDefined(cargo?.cargos?.[0]?.group, "cargo.cargos.[0].group");
  const cargoCategory = cargo?.cargos?.[0]?.group;
  const voyageRate = getVoyageRate(cargoCategory, voyageCode);

  breakdown.push(["cargoCategory", cargoCategory]);
  breakdown.push(["voyageRate", voyageRate]);

  /*
  Step 4
  Apply Discounts and Loadings
  */
  breakdown.push(["Step 4", "-"]);

  assertIsDefined(conveyance?.conveyances?.[0], "conveyance.conveyances.[0]");

  const { iccClause } = cargo;
  const { type: conveyanceType, aovTransits, containerType, insuredValue } = conveyance?.conveyances?.[0] as Conveyance;

  assertIsDefined(conveyanceType, "conveyanceType");
  assertIsDefined(voyageRate, "voyageRate");

  const discount = getDiscountLoad(conveyanceType, aovTransits, containerType);
  let additionalDiscount = 0;

  if (iccClause) {
    additionalDiscount = getAdditionalDiscount(conveyanceType, aovTransits, containerType);
  }

  let withDiscount = convertToFloat(voyageRate * (discount !== 1 ? 1 + discount : 1), 4); // voyageRate + voyageRate * discount

  breakdown.push(["conveyanceType", conveyanceType]);
  breakdown.push(["containerType", containerType ?? ""]);
  breakdown.push(["conveyanceDiscountLoad", discount]);
  breakdown.push(["conveyanceDiscountRate", withDiscount]);

  withDiscount *= additionalDiscount !== 1 ? 1 + additionalDiscount : 1;
  withDiscount = convertToFloat(withDiscount, 4);

  const conveyanceICCAdjustedRate = convertToFloat(clientTradeFactor * withDiscount, 4);

  breakdown.push(["ICCAdditionalRate", additionalDiscount]);
  breakdown.push(["ICCDiscountRate", withDiscount]);
  breakdown.push(["conveyanceICCAdjustedRate", conveyanceICCAdjustedRate]);

  /*
  Step 5
  Exhibition Cover
  */
  breakdown.push(["Step 5", "-"]);

  const { hasExhibitions } = exhibition;

  breakdown.push(["exhibitionCover", hasExhibitions ? "Yes" : "No"]);

  let finalExhibitionRate = 0;
  let finalTransitRate = conveyanceICCAdjustedRate;

  if (hasExhibitions) {
    // a
    const exhibitionsRateFactor = getExhibitionRate(cargoCategory as RegionRank, regionToRank);

    breakdown.push(["transitOut", exhibitionsRateFactor]);

    assertIsDefined(exhibition?.exhibitions?.[0], "exhibition.exhibitions.[0] ");
    const { duration, returnBack } = exhibition?.exhibitions?.[0] as Exhibition;

    if (duration) {
      // b
      const exhibitionDurationRank = getExhibitionsRank(duration); // table 6a

      breakdown.push(["duration", duration]);
      breakdown.push(["durationRate", exhibitionDurationRank]);

      // c
      finalExhibitionRate = clientTradeFactor * exhibitionsRateFactor * exhibitionDurationRank;

      breakdown.push(["returnJourney", returnBack ? "Yes" : "No"]);

      // d
      if (returnBack) {
        finalTransitRate = convertToFloat(finalTransitRate * 2, 4);
      }
      breakdown.push(["finalExhibitionRate", finalExhibitionRate]);
    }
  }
  breakdown.push(["finalTransitRate", finalTransitRate]);

  /*
  Step 6
  Calculate Premium:
  */
  breakdown.push(["Step 6", "-"]);

  assertIsDefined(insuredValue?.amount, "insuredValue.amount");

  breakdown.push(["insuredValue", insuredValue?.amount]);

  const transitPremium = convertToFloat((finalTransitRate / 100) * insuredValue?.amount, 2);
  const exhibitionPremium = convertToFloat((finalExhibitionRate / 100) * insuredValue?.amount, 2);
  const totalPremium = transitPremium + exhibitionPremium;

  breakdown.push(["transitPremium", transitPremium]);
  breakdown.push(["exhibitionPremium", exhibitionPremium]);
  breakdown.push(["totalPremium", totalPremium]);

  /*
  Step 7
  Calculate Claims Adjustment Factor
  */
  breakdown.push(["Step 7", "-"]);

  let adjustmentFactor = undefined;

  const totalClaims = insured?.lossHistoryTotal?.losses ?? 0;
  const totalPremiums = insured?.lossHistoryTotal?.premiums ?? 0;
  const lossRatio = Number.isFinite(totalClaims / totalPremiums) ? totalClaims / totalPremiums : 0;

  breakdown.push(["totalClaims", totalClaims]);
  breakdown.push(["totalPremiums", totalPremiums]);
  breakdown.push(["lossRatio", lossRatio * 100]);

  adjustmentFactor = getClaimsAdjustmentFactor(lossRatio);
  breakdown.push(["adjustmentFactor", adjustmentFactor]);

  /*
  Step 8
  Determine Deductible Factor
  */
  breakdown.push(["Step 8", "-"]);

  let deductibleFactor = undefined;
  const deductible = cargo.cargos?.[0].deductible;
  if (deductible) {
    deductibleFactor = getCargoDeductibleFactor(cargo.cargos?.[0].group, deductible);

    breakdown.push(["deductible", deductible.amount]);
    breakdown.push(["deductibleFactor", deductibleFactor]);
  }

  /*
  Step 9
  Calculate Adjusted Premiums
  */
  breakdown.push(["Step 9", "-"]);

  let adjustedTransitPremium = transitPremium * (adjustmentFactor ?? 1) * (deductibleFactor ?? 1);
  adjustedTransitPremium = convertToFloat(adjustedTransitPremium, 2);
  breakdown.push(["adjustedTransitPremium", adjustedTransitPremium]);

  let adjustedExhibitionPremium = 0;
  if (hasExhibitions) {
    adjustedExhibitionPremium = exhibitionPremium * (adjustmentFactor ?? 1) * (deductibleFactor ?? 1);
    adjustedExhibitionPremium = convertToFloat(adjustedExhibitionPremium, 2);
    breakdown.push(["adjustedExhibitionPremium", adjustedExhibitionPremium]);
  }

  let adjustedTotalPremium = totalPremium * (adjustmentFactor ?? 1) * (deductibleFactor ?? 1);
  adjustedTotalPremium = convertToFloat(adjustedTotalPremium, 2);
  breakdown.push(["adjustedTotalPremium", adjustedTotalPremium]);

  /*
  Step 10
  Check for minimum premium
  */
  breakdown.push(["Step 10", "-"]);

  let finalPremium = 0;
  let finalTransitPremium = 0;
  let finalExhibitionPremium = 0;

  const minimumPremiumAmount = getMinPremium(cargo.cargos?.[0].group);
  if (adjustedTotalPremium > minimumPremiumAmount) {
    breakdown.push(["adjustedTotalPremium > ", minimumPremiumAmount]);
    finalPremium = adjustedTotalPremium;
    finalTransitPremium = adjustedTransitPremium;
    if (hasExhibitions) {
      finalExhibitionPremium = adjustedExhibitionPremium;
    }
  } else {
    breakdown.push(["adjustedTotalPremium < ", minimumPremiumAmount]);

    finalPremium = minimumPremiumAmount;

    const transitPremiumRatio = Number.isFinite(adjustedTransitPremium / adjustedTotalPremium)
      ? adjustedTransitPremium / adjustedTotalPremium
      : 1;
    finalTransitPremium = convertToFloat(finalPremium * transitPremiumRatio, 2);

    if (hasExhibitions) {
      const exhibitionPremiumRatio = Number.isFinite(adjustedExhibitionPremium / adjustedTotalPremium)
        ? adjustedExhibitionPremium / adjustedTotalPremium
        : 1;
      finalExhibitionPremium = convertToFloat(finalPremium * exhibitionPremiumRatio, 2);
    }
  }

  breakdown.push(["finalPremium", finalPremium]);
  breakdown.push(["finalTransitPremium", finalTransitPremium]);
  if (hasExhibitions) {
    breakdown.push(["finalExhibitionPremium", finalExhibitionPremium]);
  }

  if (process.env.VITEST) {
    console.log(breakdown);
  }

  return {
    finalPremium,
    grossPremium: finalPremium, // deprecated
    totalPremium: finalPremium,

    basePremium: premiumCommissionCalculation("Transit premium", finalTransitPremium, commission, transitPremiumTax),
    exhibitionsPremium: premiumCommissionCalculation("Exhibitions premium", finalExhibitionPremium, commission, exhibitionsPremiumTax),
    storagePremium: premiumCommissionCalculation("Storage premium", 0, commission, storagePremiumTax),
    samplesPremium: premiumCommissionCalculation("Samples premium", 0, commission, samplesPremiumTax),

    breakdown,
  };
};
