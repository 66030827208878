import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import React from "react";
import { render } from "react-dom";
import App from "./modules/App";
import "./styles/index.css";

const {
  SENTRY_DSN,
  ENVIRONMENT,
  CLIENT_REF,
  NPM_PACKAGE_VERSION,
  COGNITO_DOMAIN,
  COGNITO_REDIRECT,
  COGNITO_USER_POOL_ID,
  COGNITO_WEB_CLIENT_ID,
} = process.env;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: `${CLIENT_REF}@${NPM_PACKAGE_VERSION}`,
});

Auth.configure({
  region: "eu-west-1",
  userPoolId: COGNITO_USER_POOL_ID,
  userPoolWebClientId: COGNITO_WEB_CLIENT_ID,
  oauth: {
    domain: COGNITO_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: COGNITO_REDIRECT,
    redirectSignOut: COGNITO_REDIRECT,
    responseType: "code",
  },
});

render(<App />, document.getElementById("root"));
