import React, { FC } from "react";

// thats copy of Single own vehicle / https://www.bugherd.com/projects/235305/tasks/188

export const Annual_own_vehicle_copy: FC = () => {
  return (
    <>
      <p>
        <strong>OWN VEHICLE SECURITY CLAUSE</strong>
        <br />
        <i>Applicable to all carryings by vehicles owned, operated or under the control of the Assured</i>
      </p>
      <p>
        This insurance does not cover theft of subject matter insured from vehicles owned, operated or under the control
        of the Assured when left unattended
      </p>
      <p>
        <ol>
          <li>
            <p>during the normal working day, unless</p>
            <ol>
              <li>
                <p>
                  1.1 all doors, windows and other openings are closed, properly fastened and securely locked (where
                  possible), and
                </p>
              </li>
              <li>
                <p>
                  1.2 the steering column lock, immobiliser and alarm (where fitted) are in efficient working order and
                  put into operation, and
                </p>
              </li>
              <li>
                <p>1.3 all contents are removed from view (where possible)</p>
              </li>
            </ol>
          </li>
          <li>
            <p>outside the normal working day, unless the vehicle</p>
            <ol>
              <li>
                <p>2.1 is garaged in a securely locked building of substantial construction, or</p>
              </li>
              <li>
                <p>2.2 is parked in a manned, secure compound</p>
              </li>
            </ol>
          </li>
        </ol>
      </p>
      <p>
        Failure of the Assured to bring these provisions to the attention of the drivers of such vehicles shall not
        affect the application of this clause.{" "}
      </p>
      <p>
        For the purposes of this clause:
        <br />
        <strong>“unattended”</strong> shall mean all times when the driver does not have the vehicle under direct
        observation and is not close enough and fit and able to have a reasonable prospect of deterring or attempting to
        prevent any interference with or theft of or from the vehicle. <strong>“normal working day”</strong> shall mean
        all times when the vehicle is specifically being used for the purpose of or in connection with the Assured’s
        trade, excluding overnight stops and all use by the driver solely for social, domestic or pleasure purposes
      </p>
    </>
  );
};
