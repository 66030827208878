import {
  Broker,
  Clause,
  SantionsStatus,
  Money,
  ProductRef,
  RaterOutput,
  SchemaRef,
  SystemUser,
  User,
  XContract,
  XQuote,
} from "../../@types/types";
import cargoTypes from "./refData/cargoTypes";
import countries from "./refData/countries";
import table10b from "./refData/table10b";

export interface PolicyDocumentProps {
  contract: CargoContract;
  quote: CargoQuote;
  clauses?: Clause[];
  customClauses?: Clause[];
  parsedSubmission?: ParsedSubmission;
  regions?: Region[];
  schemaRef?: string;
  isQuoteDocument?: boolean;
}

export interface PolicyCancellationDocumentProps {
  contract: CargoContract;
  quote: CargoQuote;
  parsedSubmission?: ParsedSubmission;
  endorsementId?: string;
  schemaRef: string;
}

export interface BrokerInvoiceDocumentProps {
  contract: CargoContract;
  isCancellation: boolean;
  parsedSubmission: ParsedSubmission;
  cancellationDate?: string;
  schemaRef: string;
}

export enum BrokerSecurity {
  security1 = "security_1",
  security2 = "security_2",
}

export interface CargoBroker extends Broker {
  security: BrokerSecurity;
  broker_email: string;
  claims_info: string;
  annual: {
    binding_authority: string;
    broker_domicile: string;
    broker_license: string;
    surveyor: {
      address: string;
      city: string;
      country: string;
      name: string;
      postCode: string;
    };
  };
  single: {
    binding_authority: string;
    surveyor: {
      address: string;
      air: string;
      city: string;
      country: string;
      fcl: string;
      icc_c: string;
      land_only: string;
      name: string;
      own_vehicle: string;
      postCode: string;
    };
  };
}

export type Country = NonNullable<typeof countries[number][0]>;

export type CountryIso = NonNullable<typeof countries[number][1]>;

export type Region = typeof countries[number][2];

export type CargoType = typeof cargoTypes[number][0];

export type USState = typeof table10b[number][0];

export type TemperatureUnit = "°C" | "°F";

export type Temperature = { unit: TemperatureUnit; value: number };

export type PerilType = "Quake" | "Windstorm" | "Inland Wind";

export type PerilLevel = "High" | "Medium" | "None";

export type Peril = { type: PerilType; level: PerilLevel };

export type CargoCondition = "Fully reconditioned to a new standard" | "New" | "Used";

export type RiskType = "No Risk" | "Low Risk" | "Medium Risk" | "High Risk" | "Decline";

export type ICCClause = boolean;

export type NumVehicles = number;

export type ConveyanceType = "Sea" | "Air only" | "Road only" | "Rail only" | "Post";

export type ContainerType = ContainerTypeAnnual | ContainerTypeSingle;
export type ContainerTypeAnnual = "FCL" | "LCL";
export type ContainerTypeSingle = "FCL" | "LCL" | "Breakbulk";

export type Conveyance = {
  aovTransits?: boolean;
  containerType?: ContainerType;
  insuredValue?: Money;
  limit?: Money;
  ownVehicleLimit?: Money;
  seqNumber?: number;
  turnoverPercentage?: Percentage;
  ownVehicleTurnoverPercentage?: Percentage;
  type?: ConveyanceType;
  vesselName?: string;
  imoNumber?: number;
  flightAirlineNumber?: string;
  carOnDeck?: boolean;
};

export type Shipment = {
  seqNumber: number;
  contingentTurnover: Money;
  country?: Country;
  isDeclined?: boolean;
  isReferred?: boolean;
  ownTurnover: Money;
  region: Region;
};

export type StorageAlarmsSecurity =
  | "Both Fire and Security Alarms"
  | "Fire alarm only"
  | "Security alarm only"
  | "Neither";

export type StorageLocationType = "Retail" | "Non-retail";

export type PhysicalSecurityType = "Iron bars" | "Door locks" | "Guards" | "CCTV";

export type StorageCover = {
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    zipcode?: string;
    state?: USState;
    country?: Country;
  };
  alarmsNoMaintenance?: boolean;
  alarmsNotMonitored?: boolean;
  alarmsSecurity?: StorageAlarmsSecurity;
  averageInStorePercentage?: Percentage;
  buildingAge?: number;
  compositePanels?: boolean;
  floorConstruction?: string;
  formattedAddress?: string;
  hasFloodClaim?: boolean;
  hasNoRoof?: boolean;
  isHotworkCarriedOut?: boolean;
  isOnFloodPlain?: boolean;
  isOutsideWesternEuropeUsAndCanada?: boolean;
  isProcessingAtLocation?: boolean;
  limit?: Money;
  locationType?: StorageLocationType;
  multiTenure?: boolean;
  noPhysicalSecurity?: boolean;
  noSprinklers?: boolean;
  notStandardsCompliant?: boolean;
  perils?: Peril[];
  physicalSecurityTypes?: PhysicalSecurityType[];
  region?: Region;
  roofConstruction?: string;
  seqNumber: number;
  wallConstruction?: string;
};

export type CargoGroup = CargoGroupAnnual | CargoGroupSingle;
export type CargoGroupAnnual = 1 | 2 | 3 | 4 | 5 | 1234 | 7 | null;
export type CargoGroupSingle = 1 | 2 | 3 | 4 | 5 | 6 | 7 | null;

export type Percentage = number;

export type CargoDeductibleAmount = 250 | 500 | 750 | 1_000 | 1_500 | 2_000 | 2_500 | 5_000;

export type CargoDeductible = Money & {
  amount: CargoDeductibleAmount;
};

export type CargoTempControl = "No" | "Frozen below minus 15 Centigrade" | "Other";

export type Cargo = {
  deductible: CargoDeductible;
  group: CargoGroup;
  isExcluded: boolean;
  isNotSuitablyPacked?: boolean;
  isReferred?: boolean;
  isTempControlOther?: boolean;
  percentage: Percentage;
  seqNumber: number;
  tempControl?: CargoTempControl;
  type?: CargoType;
  descriptionOfInterestForCert?: string;
  marksNumbers?: string;
};

export type ExhibitionNumber = 1 | 2 | 3 | 4 | 5;

export type ExhibitionsCover = boolean;

export type ExhibitionLimitAmount = 5_000 | 10_000 | 20_000 | 25_000 | 30_000;

export type ExhibitionLimit = Money & {
  amount: ExhibitionLimitAmount;
};

export type ExhibitionDuration = "< 7 days" | "8-14 days" | "15-30 days";

export type Exhibition = {
  duration: ExhibitionDuration;
  limit?: ExhibitionLimit;
  number?: ExhibitionNumber;
  region: Region;
  returnBack?: boolean;
  seqNumber?: number;
};

export type SamplesCover = boolean;

export type LossHistory = {
  premium: Money;
  loss: Money;
  year: string;
};

export type LossHistoryTotal = {
  premiums: number;
  losses: number;
};

export type SubmissionType = "annual" | "contract" | "single";

export type TradingCurrencies = "EUR" | "GBP" | "USD" | "CAD";

export type ClientTrade = "Importer" | "Exporter" | "Manufacturer" | "Trader" | "Private Individual";

export type VoyageCode = "A" | "B" | "C" | "D" | "E" | "F" | "G";

export type RegionRank = 1 | 2 | 3 | 4 | 5 | 6;

export type TypeOfCustomer = "Micro" | "SME" | "Commercial";

export type ParsedSubmission = {
  /** @deprecated use insured.name */
  insuredName?: string;

  /** @deprecated use contract.inceptionDateIso or contract.inceptionDateFormatted */
  inceptionDate?: string;

  insured: {
    address?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      country?: "United States";
      state?: USState;
      zipcode?: string;
    };
    associatedCompaniesFormatted?: string[];
    clientTrade?: ClientTrade;
    companyTurnover?: Money;
    country?: CountryIso;
    formattedAddress?: string;
    hadInsuranceRefused?: boolean;
    insuranceRefusedReason?: string;
    lossHistoryTotal?: LossHistoryTotal;
    lossHistory?: LossHistory[];
    name?: string;
    region?: Region;
    subsidiaryCompaniesFormatted?: string[];
    tradingCurrency?: TradingCurrencies;
    typeOfCustomer?: TypeOfCustomer;
  };
  contract: {
    expiryDateIso?: string;
    expiryDateFormatted?: string;
    inceptionDateIso?: string;
    inceptionDateFormatted?: string;
    policyPeriodInDays?: number;
    policyPeriodInMonths?: number;
    isInceptionBeforeToday?: boolean;
    type?: SubmissionType;
  };
  cargo: {
    addDuty?: boolean;
    basisOfValuation?: string;
    basisOfValuationPercentage?: Percentage;
    cargos?: Cargo[];
    condition?: CargoCondition;
    iccClause?: ICCClause;
  };
  conveyance: {
    conveyances?: Conveyance[];
    uniqueTypes?: string;
  };
  shipment: {
    /**
     * Vayage from country
     */
    countryFrom?: Country;
    /**
     * Vayage to country
     */
    countryTo?: Country;
    /**
     * Vayage from city
     */
    cityFrom?: string;
    /**
     * Vayage to country
     */
    cityTo?: string;
    inlandTransit?: boolean;
    regionFrom?: Region;
    regionTo?: Region;
    shipments?: Shipment[];
    dateOfTransit?: string;
    dateOfTransitFormatted?: string;
    isDateOfTransitBeforeToday?: boolean;
    WNRADFrom?: boolean | "uw_decision" | null;
    WNRADTo?: boolean | "uw_decision" | null;
    countryFromIsWarAndStrikesIncluded?: boolean;
    countryToIsWarAndStrikesIncluded?: boolean;
  };
  additionalInformation?: {
    isLossPayeeDifferentToInsured?: boolean;
    lossPayeeDetailsName?: string;
    lossPayeeDetailsAddress?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      country?: string;
      state?: USState;
      zipcode?: string;
    };
    lossPayeeDetailsFormattedAddress?: string;
    surveyorName?: string;
    surveyorAddress?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      country?: string;
      state?: string;
      zipcode?: string;
    };
    surveyorFormattedAddress?: string;
    assuredOwnReference?: string;
    letterOfCreditWording?: string;
  };
  exhibition: {
    exhibitions?: Exhibition[];
    hasExhibitions?: boolean;
  };
  storage: {
    hasStorages?: boolean;
    storages?: StorageCover[];
  };
  samples: {
    hasSamples?: boolean;
    limit?: Money;
    numVehicles?: NumVehicles;
  };
};

export type CargoPremium = {
  name: "Transit premium" | "Storage premium" | "Exhibitions premium" | "Samples premium";
  grossPremium?: number;
  commissionAmount?: number;
  netPremium: number;
  tax?: number;
  grossPremiumPlusTax?: number;
};

export type CargoRaterOutput = RaterOutput & {
  basePremium: CargoPremium;
  storagePremium: CargoPremium;
  exhibitionsPremium: CargoPremium;
  samplesPremium: CargoPremium;

  /** totalPremium after minimum premium check */
  finalPremium: number;

  /** sum of basePremium + exhibitionsPremium + samplesPremium + storagePremium */
  totalPremium: number;

  /** @deprecated use finalPremium */
  grossPremium: number;

  /** @deprecated use storagePremium */
  storagesPremium?: CargoPremium;
};

export type Rates = CargoRaterOutput & {
  adjustableRate?: number;
  aprp?: number;
  dayRate?: number;
  premiumTotal?: number;
  proRataFactor?: number;
  refundTax?: number;
  refundTransitPremiumTax?: number;
  refundStoragePremiumTax?: number;
  refundExhibitionsPremiumTax?: number;
  refundSamplesPremiumTax?: number;
  tally?: number;
  totalPremiumAtVersion?: number;
};

export type CargoForms = "SubmissionForm" | "NotProgressedForm" | "NotTakenUpForm" | "DeclinedForm";

export type CargoSubmission = any;

export type CargoQuote = XQuote<Rates, CargoSubmission>;

export type CargoContract = XContract<CargoQuote, CargoSubmission, CargoBroker> & {
  flags?: {
    activeClaim?: boolean;
    premiumPaid?: boolean;
    noKnownLosses?: boolean;
  };
  associatedCompaniesSanctionsCheck: SantionsStatus[];
  subsidiaryCompaniesSanctionsCheck: SantionsStatus[];
  vesselSanctionsCheck: SantionsStatus; // cargo single
  lossPayeeDetailsNameSanctionsCheck: SantionsStatus; // cargo single
  sequentialNumber?: number; // thats used in broker invoice
};

export enum CargoErrorMessage {
  invalidSubmission = "Invalid submission.",

  invalidStateLicensing = "You're not authorised to conduct business in the chosen state.",

  sanctionsCheckInsuredFail = "The insured has failed the sanctions check, you cannot continue with this submission.",
  sanctionsCheckInsuredError = "The sanctions check returned no results for your insured details. Please carry out a manual check outside of this system.",

  sanctionsCheckAssociatedCompanyFail = "The {msg} ({company}) has failed the sanctions check, you cannot continue with this submission.",
  sanctionsCheckAssociatedCompanyError = "The sanctions check returned no results for your associated company. Please carry out a manual check outside of this system.",

  sanctionsCheckSubsidiaryCompanyFail = "The {msg} ({company}) has failed the sanctions check, you cannot continue with this submission.",
  sanctionsCheckSubsidiaryCompanyError = "The sanctions check returned no results for your subsidiary company. Please carry out a manual check outside of this system.",

  sanctionsCheckVesselFail = "The vessel ({vessel}) has failed the sanctions check, you cannot continue with this submission.",
  sanctionsCheckVesselError = "The sanctions check returned no results for your vessel. Please carry out a manual check outside of this system.",

  sanctionsCheckLossPayeeDetailsNameFail = "The loss payee has failed the sanctions check, you cannot continue with this submission.",
  sanctionsCheckLossPayeeDetailsNameError = "The sanctions check returned no results for your loss payee. Please carry out a manual check outside of this system.",
}

export enum SingleCargoErrorMessage {
  quoteCannotBeBoundTransitDate = "Quote cannot be bound - The bind date is greater than transit date plus 5 days",
}

export type CargoErrorType =
  | "invalidSubmission"
  | "invalidStateLicensing"
  | "sanctionsCheckInsuredFail"
  | "sanctionsInsuredError"
  | "sanctionsCheckAssociatedCompanyFail"
  | "sanctionsCheckAssociatedCompanyError"
  | "sanctionsCheckSubsidiaryCompanyFail"
  | "sanctionsCheckSubsidiaryCompanyError"
  | "sanctionsCheckVesselFail"
  | "sanctionsCheckVesselError"
  | "sanctionsCheckLossPayeeDetailsNameFail"
  | "sanctionsCheckLossPayeeDetailsNameError";

export type CargoContext = {
  user: User | SystemUser;
  broker?: Broker;
  errorMessage: CargoErrorMessage | string;
  errorType: CargoErrorType;
  quoteId: string;
  endorsementId: string;
  contractId: string;
  productRef: ProductRef;
  currentContractData: CargoContract;
  contractData: CargoContract;
  nextContractData?: CargoContract;
  schema: {
    $id: SchemaRef;
    properties: {
      SubmissionForm: any;
      NotProgressedForm: any;
      NotTakenUpForm: any;
      DeclinedForm: any;
      BindQuoteForm: any;
    };
    definitions: {
      regions: {
        enum: Region[];
      };
    };
  };
};

export type CargoMachineSchema = {
  states: {
    Idle: any;
    Draft: any;
    Quoted: any;
    "In progress": any;
    Referred: any;
    "Referred Quoted": any;
    Declined: any;
    "Not Taken Up": any;
    "Not Progressed": any;
    Bound: {
      states: {
        Idle: any;
        Applied: any;
        Error: any;
      };
    };
    Inforce: {
      states: {
        Idle: any;
        Applied: any;
        Error: any;
      };
    };
    Cancelled: any;
    Warning: any;
    Error: any;
  };
};

export type CargoEvent =
  | { type: "Accept technical price"; payload: never }
  | { type: "Create draft submission"; payload: { submission: CargoSubmission }; renewedFrom?: string }
  | { type: "Quote submission"; payload: { submission: CargoSubmission }; renewedFrom?: string }
  | { type: "Update draft submission"; payload: { submission: CargoSubmission } }
  | { type: "Update submission"; payload: { submission: CargoSubmission } }
  | { type: "Decline to quote contract"; payload: { reason: string; note?: string } }
  | {
      type: "Provide commercial price";
      payload: {
        transitPremium: Money;
        storagePremium?: Money;
        exhibitionsPremium?: Money;
        samplesPremium?: Money;

        commission?: number;

        transitPremiumTax?: number;
        storagePremiumTax?: number;
        exhibitionsPremiumTax?: number;
        samplesPremiumTax?: number;
      };
    }
  | { type: "Generate manual quote"; payload: { submission: CargoSubmission } }
  | { type: "Edit premium paid flag"; payload: never }
  | { type: "Edit active claim flag"; payload: never }
  | { type: "Reject contract not taken up"; payload: { reason: string; reason_other?: string } }
  | { type: "Reject contract not progressed"; payload: { reason: string; reason_other?: string } }
  | { type: "Refer contract"; payload: never }
  | {
      type: "Bind quote";
      payload: {
        dateOfTransit?: string;
        lossPayeeDifferentToInsured?: string;
        lossPayeeDetailsName?: string;
        lossPayeeDetailsAddress?: string;

        noKnownLosses?: boolean;
        transitPremiumTax: number;
        storagePremiumTax?: number;
        exhibitionsPremiumTax?: number;
        samplesPremiumTax?: number;
      };
    }
  | { type: "Update quote commission"; payload: { commission: number } }
  | { type: "Process referral"; payload: never }
  | {
      type: "Cancel endorsement";
      payload: {
        cancelAndReplaceTo?: string; // single
        cancellation: {
          cancellation_date: string;
          reason: string;
          reason_other?: string;
        };
        transit: {
          refund_transit_premium_tax: Money;
        };
        storage: {
          refund_storage_premium_tax?: Money;
        };
        exhibitions: {
          refund_exhibitions_premium_tax?: Money;
        };
        samples: {
          refund_samples_premium_tax?: Money;
        };
      };
    }
  | {
      type: "Cancel and replace";
      payload: { newContractId: string };
    }
  | { type: "Update contract commission"; payload: { commissionBreakdown: Broker[] } };
