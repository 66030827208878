import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CargoErrorMessage, SingleCargoErrorMessage, CargoEvent } from "../../projects/cargo-us/types";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import { useContractQuery, useQuoteQuery } from "../hooks";
import { resolveSchema } from "../../helpers/resolveSchema";
import ajv from "../../helpers/ajv";
import mapChildren from "../helpers/mapChildren";

const BindQuoteModalForm = ({ callback, handleClose, quoteData, schemaData, schemaRef, contractData }) => {
  const isSingle = schemaRef === "cargo-us-single";

  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();
  const [isBindConfirmed, setBindConfirmed] = useState(false);
  const [isValidInsuredAddressState, setIsValidInsuredAddressState] = useState(true);
  const [isValidLossPayeeDetailsName, setIsValidLossPayeeDetailsName] = useState(true);
  const [isNoKnownClaimsConfirmed, setNoKnownClaimsConfirmed] = useState<boolean | undefined>(undefined);
  const isInceptionBeforeToday = contractData?.submission?.contract?.inception_date
    ? moment.utc(contractData.submission.contract.inception_date).isBefore(moment.utc(), "day")
    : false;

  const [formValues, setFormValues] = useState(contractData.submission);

  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const [formErrors, setFormErrors] = useState(validate.errors);

  const isReplacementPolicy = isSingle && contractData?.cancelAndReplaceFrom !== undefined;
  const singleTransitDate = formValues?.shipment_details_single?.date_of_transit;

  const isBindDateGreaterThanTransitDatePlus5 =
    isReplacementPolicy &&
    singleTransitDate &&
    moment.utc().startOf("day") > moment(singleTransitDate).utc().add(5, "days").startOf("day");

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
    setIsValidLossPayeeDetailsName(true);
  };

  const handleSubmit = async () => {
    let payload;
    if (isSingle) {
      payload = {
        noKnownLosses: isNoKnownClaimsConfirmed,

        transitPremiumTax: formValues.taxes.transit_premium_tax.amount,
        storagePremiumTax: formValues?.storage?.storage_premium_tax?.amount,
        exhibitionsPremiumTax: formValues.exhibitions?.exhibitions_premium_tax?.amount,
        samplesPremiumTax: formValues.samples?.samples_premium_tax?.amount,

        dateOfTransit: formValues?.shipment_details_single?.date_of_transit,
        lossPayeeDifferentToInsured: formValues?.additional_information?.loss_payee_different_to_insured,
        lossPayeeDetailsName: formValues?.additional_information?.loss_payee_details_name,
        lossPayeeDetailsAddress: formValues?.additional_information?.loss_payee_details_address,
      };
    } else {
      payload = {
        noKnownLosses: isNoKnownClaimsConfirmed,

        transitPremiumTax: formValues.taxes.transit_premium_tax.amount,
        storagePremiumTax: formValues?.storage?.storage_premium_tax?.amount,
        exhibitionsPremiumTax: formValues.exhibitions?.exhibitions_premium_tax?.amount,
        samplesPremiumTax: formValues.samples?.samples_premium_tax?.amount,
      };
    }

    const action: Extract<CargoEvent, { type: "Bind quote" }> = {
      type: "Bind quote",
      payload,
    };

    try {
      const res = await updateQuote({ contractId, data: action, endorsementId, productRef, quoteId });

      handleClose();
      callback(get(res, "data.data"));
    } catch (error: any) {
      if (error?.response?.data?.errorType === "invalidStateLicensing") {
        setIsValidInsuredAddressState(false);
      }
      if (error?.response?.data?.errorType === "sanctionsCheckLossPayeeDetailsNameFail") {
        setIsValidLossPayeeDetailsName(false);
      }
    }
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {!isValidInsuredAddressState && (
          <Banner className="mb-8" color="red" headingText="">
            <p className="mb-4">{CargoErrorMessage.invalidStateLicensing}</p>
          </Banner>
        )}
        {!isValidLossPayeeDetailsName && (
          <Banner className="mb-8" color="red" headingText="">
            <p className="mb-4">{CargoErrorMessage.sanctionsCheckLossPayeeDetailsNameFail}</p>
          </Banner>
        )}

        {isBindDateGreaterThanTransitDatePlus5 && (
          <Banner className="mb-8" color="red" headingText="">
            <p className="mb-4">{SingleCargoErrorMessage.quoteCannotBeBoundTransitDate}</p>
          </Banner>
        )}

        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: formErrors,
        })}

        {isInceptionBeforeToday && (
          <Banner
            className="mb-8 shadow-none  bg-gray-50"
            color="orange"
            headingText="Prior notice - backdated inception date"
          >
            <p className="mb-4">
              The insured warrants no known or reported losses as of:{" "}
              <span className="font-bold">{moment.utc().format("DD/MM/YYYY")}</span>
            </p>

            <InputCheckbox
              isChecked={isNoKnownClaimsConfirmed}
              labelText="I accept"
              name="confirm_no_known_claims"
              onChange={() => setNoKnownClaimsConfirmed(!isNoKnownClaimsConfirmed)}
            />

            <p className="mt-4 text-gray-700 text-sm">
              For a backdated inception date, you cannot bind the policy without agreeing to the warranty of no known
              claims. Please confirm the warranty or change the inception date.
            </p>
          </Banner>
        )}

        <Banner
          className="mb-8 shadow-none  bg-gray-50"
          color="orange"
          headingText="Are you sure you want to bind this quote?"
        >
          <InputCheckbox
            className="mt-4"
            labelText="I understand"
            name="confirm_bind"
            onChange={() => setBindConfirmed(!isBindConfirmed)}
            isChecked={isBindConfirmed}
          />

          <p className="mt-4 text-gray-700 text-sm">This cannot be undone.</p>
        </Banner>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={
              isInceptionBeforeToday
                ? !isValid || !isBindConfirmed || !isNoKnownClaimsConfirmed || isUpdating || isBindDateGreaterThanTransitDatePlus5 || !isValidLossPayeeDetailsName
                : !isValid || !isBindConfirmed || isUpdating || isBindDateGreaterThanTransitDatePlus5 || !isValidLossPayeeDetailsName
            }
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindQuoteModal_cargo = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal handleClose={handleClose} headingText={!isLoading && "Bind quote"} isLoading={isLoading} clearParamsOnClose>
      <BindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
        schemaData={{ ...schemaData.properties.BindQuoteForm, definitions: schemaData.definitions }}
        schemaRef={schemaData?.["$id"]}
      />
    </Modal>
  );
};

BindQuoteModal_cargo.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default BindQuoteModal_cargo;
