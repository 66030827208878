import React, { FC } from "react";
import { PolicyDocumentProps } from "../../types";

export const Single_general_information_and_1084_security: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  return (
    <section aria-labelledby="single_general_information_and_1084_security">
      <h1>GENERAL INFORMATION AND NOTICES</h1>

      <p>
        Please read this Policy carefully to ensure that it is in accordance with your requirements and that you
        understand its terms, exclusions and conditions. Contact Underwriters immediately if any corrections are
        necessary.
      </p>

      <p>
        Any enquiries you may have regarding your Policy may be addressed either to the insurance broker who arranged
        the Policy for you or directly to us.
      </p>

      <p>
        Chaucer Syndicates Services Limited
        <br />
        Security is provided by Chaucer Syndicates Ltd (at Lloyd’s of London), the registered office is:
        <br />
        Chaucer Syndicates Limited
        <br />
        52 Lime Street
        <br />
        London EC3M 7AF
        <br />
        Tel: +44 (0) 207 397 9700
        <br />
        Fax: +44 (0) 207 397 9710
      </p>

      <p>
        Web: <a href="https://www.chaucergroup.com">www.chaucergroup.com</a>
        <br />
        Lloyd’s of London are regulated by the Financial Conduct Authority (the ‘FCA’)
      </p>

      <p>
        <strong>DATA PROTECTION</strong>
        <br />
        General Data Protection Regulation (EU) 2016/679 <br />
        We have collected and may continue to collect certain information about individuals within or connected to your
        company and any subsidiaries (“data subjects”) in the course of conducting our relationship with you. This
        information will be processed for the purpose of underwriting your insurance coverage, managing the Policy,
        providing risk management advice and administering claims. We may pass the information to our reinsurers, legal
        advisers, loss adjusters or agents for these and other purposes. This may involve its transfer to countries
        which do not have data protection laws.
      </p>
      <p>
        Some of the information we collect may be classified as ‘sensitive’ – that is, information about disciplinary
        proceedings, convictions, sentences or alleged criminal activities.
      </p>

      <p>
        Data subjects have a right of access to, and correction of, information that we hold about them. If they would
        like to exercise either of these rights, they should contact our Data Protection Officer at:
      </p>

      <p>
        Chaucer Group
        <br />
        52 Lime Street
        <br />
        London EC3M 7AF
      </p>

      <p>
        INSURANCE ACT 2015
        <br />
        In respect of any;
      </p>
      <ol type="a">
        <li>duty of disclosure</li>
        <li>effect of warranties</li>
        <li>effects of acts of fraud</li>
      </ol>

      <p>
        the rights and obligations applying to the Assured and Underwriters shall be interpreted in accordance with the
        provisions of the Insurance Act 2015
      </p>

      <p>
        <strong>AUTHORISATION</strong>
        <br />
        The Assured agree that Underwriters may give to and obtain from any other underwriters, insurance reference
        bureaus and Credit Reporting Agencies: <br />
        Insurance claims information and information relating to the Assured ’s Credit or insurance history.
      </p>

      <p>
        <strong>POLICYHOLDER DISCLOSURE</strong>
        <br />
        <strong>NOTICE OF TERRORISM</strong>
        <br />
        <strong>INSURANCE COVERAGE</strong>
        <br />
        Coverage for acts of terrorism is already included in the policy (including any quotation for insurance) to
        which this notice applies. You should know that, under the policy, any losses caused by certified acts of
        terrorism would be partially reimbursed by the United States under a formula established by federal law. Under
        this formula, the United States pays 80% of covered terrorism losses exceeding the statutorily established
        deductible paid by the insurer providing the coverage. However, your policy may contain other exclusions which
        might affect your
        <br />
        coverage, such as exclusion for nuclear events. The term “act of terrorism” means any act that is certified by
        the Secretary of the Treasury, in consultation with the Secretary of Homeland Security and the Attorney General
        of the United States, to be an act of terrorism; to be a violent act or an act that is dangerous to human life,
        property, or infrastructure; to have resulted in damage within the United States, or outside the United States
        in the case of an air carrier or vessel or the premises of a United States mission; and to have been committed
        by an individual or individuals, as part of an effort to coerce the civilian population of the United States or
        to influence the policy or affect the conduct of the United States Government by coercion. The Terrorism Risk
        Insurance Act, as amended, contains a $100 billion cap that limits U.S. Government reimbursement as well as
        insurers’ liability for losses resulting from certified acts of terrorism when the amount of such losses exceeds
        $100 billion in any one calendar year. If the aggregate insured losses for all insurers exceed $100 billion,
        your coverage may be reduced.
      </p>

      <p>
        The portion of your annual premium that is attributable to coverage for certified acts of terrorism as defined
        in the Terrorism Risk Insurance Act of 2002, as amended, is: … at a rate of 0.01% on the sum insured.
      </p>
      <p>
        I ACKNOWLEDGE THAT I HAVE BEEN NOTIFIED THAT UNDER THE TERRORISM RISK INSURANCE ACT OF 2002, AS AMENDED, ANY
        LOSSES CAUSED BY CERTIFIED ACTS OF TERRORISM UNDER MY POLICY COVERAGE WILL BE PARTIALLY REIMBURSED BY THE UNITED
        STATES, SUBJECT TO A $100 BILLION CAP THAT MAY REDUCE MY COVERAGE, AND I HAVE BEEN NOTIFIED OF THE AMOUNT OF MY
        PREMIUM ATTRIBUTABLE TO SUCH COVERAGE.
        <br />
        09 January 2020
        <br />
        LMA9185
        <br />
        Form approved by Lloyd’s Market Association
      </p>
      <p>
        Note: By binding this policy you agree to the acknowledgement within the Policyholder Disclosure Notice of
        Terrorism.
      </p>
      <p>
        <strong>CUSTOMER COMPLAINTS PROCEDURE</strong>
        <br />
        In the event of a complaint, in the first instance please contact your broker.
        <br />
        We will send you and/or your Broker written acknowledgement of your complaint within five business days of its
        receipt, giving the name or job title of the individual handling the complaint for us
      </p>
      <p>
        If we are able to provide a final response within five business days of receipt of a complaint we may combine
        our acknowledgement of the complaint with the final response.
      </p>
      <p>We will, within four weeks of receiving your complaint, send you and/or your broker either:</p>
      <p>
        a final response or
        <br />
        a holding response, which explains why we have not been able to respond as yet we will by the end of eight weeks
        after its receipt of your complaint, send you and/or your broker either; <br />
        a final response or <br />a holding response, which explains why we have not been able to respond yet
      </p>
      <p>
        If you remain dissatisfied with our response, you may refer your complaint to the Financial Ombudsman Service
        (FOS) and must do so within six months to be eligible. You can contact the FOS by telephone on +44 (0) 800 023
        4567 and further information is available at http://www.financial‐ombudsman.org.uk/
      </p>

      <p>
        Financial Ombudsman Service
        <br />
        South Quay Plaza
        <br />
        183 Marsh Wall, London E14 9SR
        <br />
        Tel: +44 (0) 207 964 1000
        <br />
        Fax: +44 (0) 207 964 1001
        <br />
        Email : complaint.info@financial‐ombudsman.org.uk
      </p>
      <p>
        Financial Conduct Authority
        <br />
        25 The North Colonnade
        <br />
        Canary Wharf, London E14 5HS
        <br />
        Tel: +44 (0) 207 066 1000
        <br />
        Fax: +44 (0) 207 066 1099
      </p>
      <p>
        Complaints
        <br />
        Lloyd’s <br />
        One Lime Street, London EC3M 7HA <br />
        Tel: +44 (0) 207 327 5693 <br />
        Fax: +44 (0) 207 327 5225
        <br />
        Email:Complaints@Lloyds
      </p>
      <p>The Financial Services Compensation Scheme</p>

      <p>
        Insurance products underwritten by Lloyd’s Syndicates are covered by the Financial Services Compensation Scheme
        (FSCS). You may be entitled to compensation from the scheme if they become insolvent and cannot meet their
        obligations. This depends on the type of business and the circumstances of the claim. Where you are entitled to
        claim, insurance advising and arranging is covered for 90% of the claim, without any upper limit.
      </p>
      <p>
        You can contact the FSCS with the below details:
        <br />
        Financial Services Compensation Scheme (FSCS),
        <br />
        10th Floor, Beaufort House,
        <br />
        15 St Boltolph Street, London, EC3A 7QU
        <br />
        Website: www.fscs.org.uk&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telephone: 0207 892 7300
      </p>
      <p>
        Chaucer Syndicates Ltd a Lloyd’s Managing Agent are authorised and regulated by the Prudential Regulation
        Authority and regulated by the Financial Conduct Authority, their Financial Services Registration Number is
        204915.
      </p>
      <p>
        You can check this on the FS Register by visiting the Financial Conduct Authority website
        www.fsa.gov.uk/register/home.do or by contacting the Financial Conduct Authority on 0800 111 6768.
      </p>
    </section>
  );
};
