import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, ROUTES, STATUSES } from "../../../constants";
import { Cargo } from "../../../projects/cargo-us";
import * as api from "../../api";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import Loader from "../../components/Loader";
import PageLoader from "../../components/PageLoader";
import Table from "../../components/Table";
import { statusesInclude } from "../../helpers/statusesInclude";
import { useAuth } from "../../hooks";
import { ModalSwitch } from "../../hooks/useModal";
import { usePrivateRoute } from "../../hooks/usePrivateRoute";
import { useProductCheck } from "../../hooks/useProductCheck";
import { useScrollTop } from "../../hooks/useScrollTop";
import NavBar from "../NavBar";
import { columns } from "./config";

const IconEmpty = ({ className }) => (
  <svg fill="none" viewBox="0 0 48 48" className={className}>
    <circle cx="24" cy="24" r="24" fill="#F3F4F6" />
    <path
      stroke="#0EA5E9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M32 25V18C32 16.8954 31.1046 16 30 16H18C16.8954 16 16 16.8954 16 18V25M32 25V30C32 31.1046 31.1046 32 30 32H18C16.8954 32 16 31.1046 16 30V25M32 25H29.4142C29.149 25 28.8946 25.1054 28.7071 25.2929L26.2929 27.7071C26.1054 27.8946 25.851 28 25.5858 28H22.4142C22.149 28 21.8946 27.8946 21.7071 27.7071L19.2929 25.2929C19.1054 25.1054 18.851 25 18.5858 25H16"
    />
  </svg>
);

const Empty = ({ children, headingText }) => (
  <div className="flex items-center justify-center text-center">
    <div className="py-12">
      <IconEmpty className="w-12 inline mb-4" />
      <H3 className="mb-4">{headingText}</H3>
      {children}
    </div>
  </div>
);

const Inbox = () => {
  const { push } = useHistory();
  const { productRef } = useParams();
  const { isBroker, isAdmin, checkPermission, user } = useAuth();
  const statuses = [
    STATUSES.DECLINED,
    STATUSES.DRAFT,
    STATUSES.IN_PROGRESS,
    STATUSES.QUOTED,
    STATUSES.REFERRED,
    STATUSES.REFERRED_QUOTED,
  ];
  const params = { status: statuses.join(","), messages: true };
  const brokerStatuses = [
    STATUSES.DECLINED,
    STATUSES.DRAFT,
    STATUSES.IN_PROGRESS,
    STATUSES.QUOTED,
    STATUSES.REFERRED_QUOTED,
  ];
  const underwriterStatuses = [STATUSES.REFERRED];
  const myStatuses = isBroker ? brokerStatuses : underwriterStatuses;
  const theirStatuses = isBroker ? underwriterStatuses : brokerStatuses;

  const contractsQuery = useQuery(
    ["brokerContracts", productRef, params],
    () => api.getContracts({ productRef, params }),
    { select: (data) => data?.data?.data },
  );

  const schemasRefQuery = useQuery(["schemasRef", productRef], () => api.getSchemasRef(), {
    select: (data) => data?.data?.data,
  });

  let contractsQueryData = contractsQuery?.data?.map((contract) => {
    const schemaRef = schemasRefQuery?.data?.find((schema) => schema.id === contract?.schemaId)?.ref ?? "";
    return { ...contract, schemaRef };
  });

  if (productRef === "mc" && !isAdmin) {
    // filter Sure contracts by user id
    contractsQueryData = contractsQueryData?.filter((contract) => contract?.createdBy?.id === user?.id);
  }

  const dataWithCount = contractsQueryData
    ?.map((contractData) => {
      const key = `user#${user.id}#contract#${contractData.id}#last_known_message_id`;
      const lastKnownId = window.localStorage.getItem(key);
      const index = contractData.messages.indexOf(JSON.parse(lastKnownId));
      const count = contractData.messages.length - 1 - index;

      return { ...contractData, messagesCount: count };
    })
    .filter((contractData) => !statusesInclude([STATUSES.EXPIRED], contractData.state));

  const myData = dataWithCount?.filter((contractData) => {
    const { state, messagesCount } = contractData;
    const statusMatches = statusesInclude(myStatuses, state);

    return statusMatches || messagesCount > 0;
  });

  const theirData = dataWithCount?.filter((contractData) => {
    const { state, messagesCount } = contractData;
    const statusMatches = statusesInclude(theirStatuses, state);

    return statusMatches && messagesCount === 0;
  });

  useScrollTop();

  const handleRowClick = (contractData) => {
    const { status, id } = contractData;
    const canEdit = checkPermission(status)(ACTIONS.UPDATE_SUBMISSION);

    if (canEdit && status === STATUSES.DRAFT) {
      return push(compile(ROUTES.CONTRACT_EDIT)({ productRef, contractId: id }));
    }

    return push(compile(ROUTES.CONTRACT_VIEW)({ productRef, contractId: id }));
  };

  return (
    <>
      <ModalSwitch />

      <div className="pt-16 mt-1.5">
        <NavBar />
      </div>

      {contractsQuery.isLoading && <Loader className="my-64 mx-auto" />}

      {!contractsQuery.isLoading && (
        <div className="m-8">
          <div className="bg-white shadow rounded mb-8">
            <Table
              headingText="Team inbox"
              headingDecorator={
                <Button
                  iconName="replay"
                  className="h-10 -my-2"
                  onClick={() => contractsQuery.refetch()}
                  isDisabled={contractsQuery.isFetching}
                >
                  Refresh
                </Button>
              }
              columns={process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? Cargo.config.inboxColumns : columns}
              rows={myData}
              onRowClick={handleRowClick}
            />

            {myData?.length === 0 && (
              <Empty headingText="Inbox 0">
                <p>
                  Congratulations! <br /> Your team is up to date
                </p>
              </Empty>
            )}
          </div>

          <div className="bg-white shadow rounded mb-8">
            <Table
              headingText={isBroker ? "Awaiting response from underwriter" : "Awaiting response from broker"}
              columns={process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? Cargo.config.inboxColumns : columns}
              rows={theirData}
              onRowClick={handleRowClick}
            />

            {theirData?.length === 0 && (
              <Empty headingText="Outbox 0">
                <p>
                  You have no work awaiting <br />a response.
                </p>
              </Empty>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const InboxPage = () => {
  const { isLoading } = usePrivateRoute();

  useProductCheck();

  if (isLoading) {
    return <PageLoader />;
  }

  return <Inbox />;
};

export default InboxPage;
