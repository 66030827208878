import localforage from "localforage";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Template } from "../../@types/types";
import Button from "../components/Button";
import Form from "../components/Form";
import InputText from "../components/InputText";
import InputTextarea from "../components/InputTextarea";
import Modal from "../components/Modal";
import { useAuth } from "../hooks";

const DebuggerCreateTemplateModal = ({ handleClose, submissionForm, schemaRef, schemaVersion }) => {
  const [formValues, setFormValues] = useState<{ name: string; description?: string }>({});
  const { user, tenant } = useAuth();

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const template: Template = {
      createdAt: new Date().toISOString(),
      createdBy: {
        email: user.email,
        fullName: user.fullName,
        id: user.id,
        isSystem: false,
        organisationName: tenant.name,
        role: user.role,
      },
      data: submissionForm,
      description: formValues.description,
      id: uuid(),
      name: formValues.name,
      schemaRef,
      schemaVersion,
    };

    await localforage.setItem(`${process.env.CLIENT_REF}#${process.env.DEFAULT_PRODUCT_REF}#template#${template.id}`, template);

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Save template">
      <Form onSubmit={handleSubmit} className="p-6">
        <InputText
          labelText="Template name"
          name="name"
          value={formValues.name}
          isRequired
          className="mb-8"
          onChange={handleChange}
        />

        <InputTextarea
          labelText="Template description"
          name="description"
          value={formValues.description}
          className="mb-8"
          onChange={handleChange}
        />

        <div className="flex">
          <Button className="mr-4" isDisabled={!formValues.name} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DebuggerCreateTemplateModal;
