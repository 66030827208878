import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { ACTIONS, STATUSES } from "../../../constants";
import { currencyFormatter, dateFormatter } from "../../../formatters";
import Banner from "../../components/Banner";
import { useAuth } from "../../hooks";

const Banners_v2 = ({ contractData }) => {
  const { checkPermissions } = useAuth(contractData);
  const { status, type, sanctionsCheckStatus, associatedCompaniesSanctionsCheck, subsidiaryCompaniesSanctionsCheck, vesselSanctionsCheck } = contractData;
  const isEndorsement = type === "endorsement";
  const isReferred = status === STATUSES.REFERRED;
  const isNtu = status === STATUSES.REJECTED_NOT_TAKEN_UP;
  const isNp = status === STATUSES.REJECTED_NOT_PROGRESSED;
  const isDeclined = status === STATUSES.DECLINED;
  const isCancelled = status === STATUSES.CANCELLED || (isEndorsement && get(contractData, "cancellation"));
  const hasEndoPremium = Boolean(get(contractData, "endorsement_premium"));
  const actualPremium = get(contractData, "endorsement_premium.actualPremium");
  const actualPremiumAmount = get(actualPremium, "amount");
  const isReferredCancellation = isReferred && contractData?.referralReasons?.includes("Known losses.");
  const kind = (isReferredCancellation && "cancellation") || (isEndorsement && "endorsement") || "submission";
  const isPreBind = status !== STATUSES.CANCELLED && status !== STATUSES.BOUND;
  const hasSanctionsCheckErrorForInsured = sanctionsCheckStatus === "error";
  const sanctionsCheckErrorForAssociatedCompanies =
    associatedCompaniesSanctionsCheck?.filter((item) => item.status === "error") ?? [];
  const sanctionsCheckErrorForSubsidiaryCompanies =
    subsidiaryCompaniesSanctionsCheck?.filter((item) => item.status === "error") ?? [];
  const sanctionsCheckErrorForVessel = vesselSanctionsCheck?.status === "error";

  const sanctionsCheckErrorList = [];
  if (sanctionsCheckErrorForAssociatedCompanies.length > 0) {
    sanctionsCheckErrorList.push(
      `${
        sanctionsCheckErrorForAssociatedCompanies.length > 1 ? "associated companies" : "associated company"
      } (${sanctionsCheckErrorForAssociatedCompanies.map((item) => item.name).join(", ")})`,
    );
  }
  if (sanctionsCheckErrorForSubsidiaryCompanies.length > 0) {
    sanctionsCheckErrorList.push(
      `${
        sanctionsCheckErrorForSubsidiaryCompanies.length > 1 ? "subsidiary companies" : "subsidiary company"
      } (${sanctionsCheckErrorForSubsidiaryCompanies.map((item) => item.name).join(", ")})`,
    );
  }
  if (sanctionsCheckErrorForVessel) {
    sanctionsCheckErrorList.push(`vessel (${vesselSanctionsCheck.name})`);
  }

  return (
    <>
      {isReferred && process.env.DEFAULT_PRODUCT_REF === "mc" && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={
            checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS)
              ? `This ${kind} has been referred to you for the following reason(s):`
              : `Your ${kind} has been referred to our admin for review.`
          }
        >
          {checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && contractData?.referralReasons && (
            <ul className="list-disc list-inside">
              {contractData?.referralReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}
        </Banner>
      )}

      {isReferred && process.env.DEFAULT_PRODUCT_REF !== "mc" && !checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={`Your ${kind} has been referred to our underwriters for review.`}
        />
      )}

      {isReferred && process.env.DEFAULT_PRODUCT_REF !== "mc" && checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && contractData?.referralReasons?.length !== 0 && (
          <Banner
            className="mb-8"
            color="orange"
            headingText={`This ${kind} has been referred to you for the following reason(s):`}
          >
            <ul className="list-disc list-inside">
              {contractData?.referralReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          </Banner>
        )}

      {isPreBind && hasSanctionsCheckErrorForInsured && (
        <Banner className="mb-8" color="orange" headingText="Sanctions check - no results">
          <p>
            The sanctions check returned no results for your insured details. Please carry out a manual check outside of
            this system.
          </p>
        </Banner>
      )}

      {isPreBind && sanctionsCheckErrorList.length !== 0 && (
        <Banner className="mb-8" color="orange" headingText="Sanctions check - no results">
          <p>
            The sanctions check returned no results for your {sanctionsCheckErrorList.join(" and ")}. Please carry out a
            manual check outside of this system.
          </p>
        </Banner>
      )}

      {isNtu && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not taken up for the following reason(s):"
        >
          {contractData?.rejectionReasons && (
            <ul className="list-disc list-inside">
              {contractData?.rejectionReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.rejectionNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.rejectionNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isNp && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not progressed for the following reason(s):"
        >
          {contractData?.rejectionReasons && (
            <ul className="list-disc list-inside">
              {contractData?.rejectionReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.rejectionNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.rejectionNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isDeclined && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to decline to quote for the following reason(s):"
        >
          {contractData?.declineReasons && (
            <ul className="list-disc list-inside">
              {contractData?.declineReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.declineNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.declineNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isReferredCancellation && (
        <Banner className="mb-8" color="red" headingText="Cancellation details.">
          {contractData?.submission?.quote && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {contractData?.submission?.quote?.reason_other || contractData?.submission?.quote?.reason}
            </p>
          )}

          {contractData?.submission?.quote?.cancellation_date && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(contractData?.submission?.quote?.cancellation_date)}
            </p>
          )}
        </Banner>
      )}

      {isCancelled && (
        <Banner className="mb-8" color="red" headingText="This policy has been cancelled.">
          {get(contractData, process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? "cancellationReason" : "cancellation.reason") && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {process.env.DEFAULT_PRODUCT_REF === "cargo-us"
                ? get(contractData, "cancellationReason")
                : get(contractData, "cancellation.reason_other") || get(contractData, "cancellation.reason")}
            </p>
          )}

          {get(
            contractData,
            process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? "cancellationDate" : "cancellation.cancellation_date",
          ) && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(
                get(
                  contractData,
                  process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? "cancellationDate" : "cancellation.cancellation_date",
                ),
              )}
            </p>
          )}
        </Banner>
      )}

      {hasEndoPremium && isEndorsement && (
        <Banner className="mb-8" color="red" headingText="Endorsement premium change:">
          {actualPremium && (
            <p className="mb-2">
              {actualPremiumAmount < 0 && <span className="font-medium">Return premium: </span>}
              {actualPremiumAmount > 0 && <span className="font-medium">Additional premium: </span>}
              {currencyFormatter({ amount: Math.abs(actualPremium?.amount) })}
            </p>
          )}
        </Banner>
      )}
    </>
  );
};

Banners_v2.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default Banners_v2;
