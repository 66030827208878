import React, { FC } from "react";
import { PolicyDocumentProps } from "../../types";

export const Single_household_goods_and_personal_effects: FC<PolicyDocumentProps> = ({ contract, quote }) => {
  return (
    <section aria-labelledby="single_household_goods_and_personal_effects">
      <h1>HOUSEHOLD GOODS &amp; PERSONAL EFFECTS</h1>
      <p>
        This Insurance will be subject to the following Institute Clauses along with any other policy conditions:
        Institute Cargo Clauses (A) or (Air) or (C), Institute War Clauses (Cargo) or (Air Cargo), Institute Strikes
        Clauses (Cargo) or (Air Cargo), Institute Replacement Clause, Institute Classification Clause. All as
        appropriate to the mode of transit and as stated.
      </p>
      <p>In addition the clauses below are deemed to apply where applicable:</p>

      <p>
        <strong>DURATION CLAUSE</strong>
        <br />
        This insurance attaches from the time the insured property leaves the assured’s residence or place of storage at
        the place named for the commencement of the transit (or Airport or Port or Border for a “Port Only” restricted
        country), including where applicable whilst at packers’ premises being packed or awaiting shipment for a period
        not exceeding 30 days and terminates either:
        <br />
        <ol type="I" style={{ marginLeft: "0" }}>
          <li>
            <p style={{ marginLeft: "2em" }}>on delivery to the assured’s premises at the destination named, or</p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>
              on delivery to a place of storage other than in the ordinary course of transit, or
            </p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>
              on expiry of: 60 days after completion of discharge overside from the overseas vessel at the final port of
              discharge or 30 days after unloading from the aircraft at the final place of discharge or
            </p>
          </li>
          <li>
            <p style={{ marginLeft: "2em" }}>at Airport or Port or Border for a “Port Only” restricted country</p>
          </li>
        </ol>
        ...whichever shall first occur.
      </p>

      <p>
        <strong>AVERAGE CLAUSE</strong>
        <br />
        In the event of the sum insured being less than the total value at the time and place of loss of the insured
        property, the Insured shall only be entitled to recover hereunder such proportion of the loss as the sum insured
        bears to the total value of the insured property.
      </p>

      <p>
        <strong>DEPRECIATION</strong>
        <br />
        Underwriters’ liability is restricted to the reasonable cost of repair of the damaged article and no claim is to
        attach hereto for depreciation consequent thereon. In no case shall liability hereunder for such repairs exceed
        the sum insured in respect of the damaged article.
      </p>

      <p>
        <strong>REPLACEMENT CLAUSE FOR SECOND HAND GOODS</strong>
        <br />
        In the event of a claim arising under the Institute Replacement Clause, this insurance is only to pay such
        proportion as the insured value bears to the cost of the goods when new based on present values.
      </p>

      <p>
        <strong>NON CONTRIBUTION CLAUSE</strong>
        <br />
        This insurance does not cover any loss or damage which at the time of happening of such loss or damage is
        insured by or would but for the existence of this Policy, be insured by any other existing Policy or Policies
        except in respect of any excess beyond the amount which would have been payable under such other Policy or
        Policies had this Insurance not been effected.
      </p>

      <p>
        <strong>PAIRS AND SETS CLAUSE</strong>
        <br />
        Where an insured item consists of articles in a pair or set, the assured shall not be entitled to recover more
        than the proportionate sum insured in respect of the particular part or parts which may be lost or damaged.
      </p>

      <p>
        <strong>FRAGILES &amp; ANTIQUES WARRANTY</strong>
        <br />
        Warranted that the maximum value of fragiles &amp;/or antiques does not exceed 15% of the overall consignment
        value unless specifically agreed with insurers prior to the commencement of transit.
      </p>

      <p>
        <strong>EXCESS / DEDUCTIBLE</strong>
        <br />
        Deductible as stipulated each &amp; every claim.
      </p>

      <p>
        <strong>EXCLUSIONS</strong>
      </p>

      <p>
        <strong>INVENTORY REQUIREMENTS</strong>
        <br />
        Excluding claims for missing items unless a full valued inventory is completed and signed by the owners of the
        goods prior to the commencement of transit. Warranted that any item valued above £750 or US$1,000 is listed.
      </p>

      <p>
        <strong>OWNER PACKED EFFECTS</strong>
        <br />
        Excluding breakage, scratching, denting, chipping, bruising, bending, marring, staining and tearing of owner
        packed effects, including trunks, suitcases and the like.
      </p>

      <p>
        <strong>MECHANICAL, ELECTRICAL DERANGEMENT</strong>
        <br />
        Excluding loss or damage due to mechanical, electrical or electronic breakdown &amp;\or derangement unless there
        is evidence of external damage to the insured item or its packing.
      </p>

      <p>
        <strong>MOTH, VERMIN, WEAR &amp; TEAR</strong>
        <br />
        Excluding loss or damage due to moth, vermin, mildew, mould, rust, discolouration, inherent vice, wear, tear and
        gradual deterioration.
      </p>

      <p>
        <strong>CLIMATIC CONDITIONS</strong>
        <br />
        Excluding loss or damage by climatic or atmospheric conditions or extremes of temperature unless such claim is
        recoverable under the terms of the Institute Cargo Clauses (C). No claim to attach hereto for damage to strings,
        reeds &amp;/or drumheads in respect of musical instruments.
      </p>

      <p>
        <strong>CONFISCATION</strong>
        <br />
        Excluding the risks of confiscation &amp; seizure.
      </p>

      <p>
        <strong>EXCLUDED GOODS</strong>
        <br />
        Excluding loss of or damage to cash, bank notes, cheques, travellers cheques, money orders, postal orders,
        national saving certificates, premium bonds, stamps, deeds, tickets, passports, manuscripts, lottery tickets or
        documents of any description, medals, coins, bonds, securities, jewellery, watches, trinkets, personal
        ornaments, precious stones &amp; metals, furs and any other article of similar description. Also excluding any
        accompanied luggage or any article used or worn during the course of the transit.
      </p>

      <p>
        <strong>PERISHABLE GOODS, LIQUIDS</strong>
        <br />
        Excluding loss of or damage caused by perishable goods, acids, paints, aerosols, medicines and all liquids.
      </p>

      <p>
        <strong>RESPRAYING</strong>
        <br />
        Respraying of vehicles is limited to damaged parts only.
      </p>

      <p>
        <strong>EXCESS / DEDUCTIBLE</strong>
        <br />
        Deductible as stipulated each &amp; every claim.
      </p>

      <p>
        <strong>EXCLUSIONS</strong>
      </p>

      <p>
        <strong>VEHICLES OVER 5 YEARS OF AGE OR VEHICLES WITH NO CERTIFICATE OF CONDITION *</strong>
        <br />
        Excluding the risks of scratching, denting, chipping, bruising, marring, staining, rust, oxidisation &amp;
        discolouration.
        <br />
        <strong>* Certificate of Condition is defined as:</strong> A document stating the condition of the vehicle at
        the time the vehicle enters the custody of the carrier, freight forwarder or steamship company noting vehicle
        condition and defects. The certificate of condition must be agreed by the carrier, freight forwarder or
        steamship company and the owner of the vehicle and also signed at the same time.
      </p>

      <p>
        <strong>CLASSIC CARS</strong>
        <br />
        Excluding any motor vehicles over twelve (12) years of age without prior approval from Underwriters.
      </p>

      <p>
        <strong>MECHANICAL, ELECTRICAL DERANGEMENT</strong>
        Excluding the risks of mechanical, electrical or electronic breakdown &amp;/or derangement.
      </p>

      <p>
        <strong>CLIMATIC CONDITIONS</strong>
        <br />
        Excluding loss or damage arising from climatic or atmospheric conditions or extremes of temperature or freezing
        of coolant.
      </p>

      <p>
        <strong>RUST, OXIDISATION &amp; DISCOLOURATION</strong>
        <br />
        Excluding the risks of rust, oxidisation and discolouration unless caused by a peril insured under the terms of
        the Institute Cargo Clauses (C).
      </p>

      <p>
        <strong>ACCESSORIES</strong>
        <br />
        Excluding loss or damage to accessories &amp;\or portable items unless declared prior to shipment.
      </p>

      <p>
        <strong>AUDIO AND ELECTRICAL EQUIPMENT</strong>
        <br />
        Excluding theft or pilferage of audio and electrical equipment including but not limited to CD and DVD players,
        radios, speakers, satellite navigational devices and similar items unless stolen with the vehicle.
      </p>

      <p>
        <strong>OWN POWER</strong>
        <br />
        Excluding loss or damage whilst the insured vehicle is being driven under it’s own power or whilst being towed,
        except whilst being loaded or unloaded from the carrying conveyance including containers.
      </p>
      <p>
        <strong>THIRD PARTY LIABILITY</strong>
        <br />
        Excluding damages, injury or liability to any third party absolutely.
      </p>
      <p>
        <strong>MOTOR INSURANCE</strong>
        <br />
        Excluding any claim recoverable under a policy of Motor Insurance.
      </p>
      <p>
        <strong>CONFISCATION</strong>
        <br />
        Excluding the risks of confiscation &amp; seizure.
      </p>
    </section>
  );
};
