import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-query";
import { useParams, useRouteMatch } from "react-router-dom";
import { STATUSES, ROUTES } from "../../../constants";
import { UMR } from "../../../constants/umr";
import { getEndoStatusesMap, getContractStatusesMap } from "../../../constants/documents";
import * as api from "../../api";
import Accordion from "../../components/Accordion";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import Tag from "../../components/Tag";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useDocumentsQuery } from "../../hooks/useDocumentsQuery";

const AccordionPanelDocuments = ({ contractData, endorsementId, ...props }) => {
  const { mutateAsync: getAttachmentLink } = useMutation(api.getAttachmentLink);
  const { contractId, productRef } = useParams();
  const { status } = contractData;
  const isReferred = status === STATUSES.REFERRED;
  const isGallagher = UMR.GALLAGHER.includes(contractData?.placingBroker?.umr);
  const isRopner = UMR.ROPNER.includes(contractData?.placingBroker?.umr);
  const isCancellation = Boolean(contractData.cancellationDate) & !isReferred;
  const isContract = Boolean(contractData?.boundQuote);
  const isRenewal = process.env.DEFAULT_PRODUCT_REF === "mel" && contractData.renewedFrom ? true : false;
  const endorsementRef = isRenewal && endorsementId ? contractData.ref : null;
  const isAuditLogRoute = useRouteMatch(ROUTES.CONTRACT_VIEW_AUDIT_LOG);
  const statusesMap = endorsementId
    ? getEndoStatusesMap(process.env.DEFAULT_PRODUCT_REF)
    : getContractStatusesMap(process.env.DEFAULT_PRODUCT_REF);

  if (isContract) {
    statusesMap[STATUSES.EXPIRED] = "quote_document,policy_document,broker_invoice_document";
  }

  const isCargoContract = !isAuditLogRoute && !endorsementId && process.env.DEFAULT_PRODUCT_REF === "cargo-us";

  const showEndorsementDocuments = isRenewal || isCargoContract;

  const expected = statusesMap[isCancellation ? STATUSES.CANCELLED : status] || "";

  const finalExpected = expected
    .split(",")
    .filter((type) => {
      if (process.env.DEFAULT_PRODUCT_REF === "cargo-us") {
        return true;
      } else {
        if (isRopner) {
          return type !== "broker_invoice_document";
        }
        if (isGallagher) {
          return type !== "broker_jacket_document";
        }

        return type !== "broker_invoice_document" && type !== "broker_jacket_document";
      }
    })
    .filter(Boolean);

  const { documentsData, isLoading, isError } = useDocumentsQuery({
    contractId,
    endorsementId,
    predicate: (data) => {
      if (finalExpected.length === 0) {
        return true;
      }
      if (isRenewal && endorsementId) {
        return (
          data.every((item) => item.taskFinished) &&
          finalExpected.some((type) => data.find((item) => item.documentType === type))
        );
      }

      return (
        data.every((item) => item.taskFinished) &&
        finalExpected.every((type) => data.find((item) => item.documentType === type))
      );
    },
    showEndorsementDocuments,
  });

  const filteredDocs = documentsData.filter(({ documentType, fileName }) => {
    if (isRenewal && endorsementId) {
      return finalExpected?.includes(documentType) && fileName.indexOf(endorsementRef) !== -1;
    }
    return finalExpected?.includes(documentType);
  });

  const hasData = filteredDocs.length > 0;

  const handleDownloadClick = async ({ documentId }) => {
    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = get(res, "data.data");

    downloadDocument(url, fileName);
  };

  return (
    <Accordion.Panel tag={<Tag text={filteredDocs.length} className="border-gray-400" />} {...props}>
      {(isLoading || isError) && (
        <div className="p-8">
          <Loader className="mx-auto" />
        </div>
      )}

      {!isLoading && !isError && (
        <>
          {filteredDocs.map((document) => (
            <div
              key={document.fileName}
              className="flex px-3 py-4 border-b border-grey-200 hover:bg-blue-50 hover:text-blue-600 transition"
              onClick={() => handleDownloadClick({ documentId: document.documentId })}
              onKeyPress={() => handleDownloadClick({ documentId: document.documentId })}
              role="button"
              tabIndex="0"
            >
              <p className="pr-4">{document.fileName}</p>
              <Icon name="details" className="cursor-pointer w-6 fill-current text-gray-700" />
            </div>
          ))}

          {!hasData && <p className="text-center my-6">No document available.</p>}
        </>
      )}
    </Accordion.Panel>
  );
};

AccordionPanelDocuments.propTypes = {
  contractData: PropTypes.object.isRequired,
  endorsementId: PropTypes.string,
};

AccordionPanelDocuments.defaultProps = {
  endorsementId: "",
};

export default AccordionPanelDocuments;
