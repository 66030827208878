import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import InputField from "./InputField";
import Icon from "./Icon";

const sizes = {
  small: "h-10",
  normal: "h-12",
};

const InputTextarea = ({
  className,
  descriptionText,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  onChange,
  size,
  value,
  errors,
  ...props
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    name={name}
    errors={errors}
  >
    <textarea
      className={clsx(
        "aui-input",
        "aui-input-textarea",
        errors && "aui-input--errors",
        isReadOnly && "aui-input aui-input-readonly",
      )}
      id={id || name}
      name={name}
      required={isRequired}
      value={value}
      onChange={onChange}
      disabled={isReadOnly}
    />
    {isReadOnly && (
      <div className={clsx("flex w-10 z-10 items-center justify-center absolute right-0 top-0 mt-2", sizes[size])}>
        <Icon name="lock" className="w-3 fill-current text-gray-800" />
      </div>
    )}
  </InputField>
);

InputTextarea.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

InputTextarea.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  labelText: undefined,
};

export default InputTextarea;
