import { ACTIONS } from "./actions";

export const ACTION_TEXT_MAP = {
  [ACTIONS.CANCEL_AND_REPLACE]: "Cancel & Replace",
  [ACTIONS.CANCEL_ENDORSEMENT_MANUAL]: "Cancel policy",
  [ACTIONS.CANCEL_ENDORSEMENT]: "Cancel policy",
  [ACTIONS.CREATE_ENDORSEMENT_MTA]: "Create endorsement",
  [ACTIONS.DECLINE_TO_QUOTE_CONTRACT]: "Decline to quote",
  [ACTIONS.PROCESS_REFERRAL]: "Pass to broker",
  [ACTIONS.PROVIDE_COMMERCIAL_PRICE]: "Provide quote",
  [ACTIONS.REFER_CONTRACT]: "Pass to underwriter",
  [ACTIONS.REJECT_CONTRACT_NOT_PROGRESSED]: "Do not progress",
  [ACTIONS.REJECT_CONTRACT_NOT_TAKEN_UP]: "Not taken up",
  [ACTIONS.UPDATE_CONTRACT_COMMISSION]: "Amend commission",
  [ACTIONS.UPDATE_QUOTE_COMMISSION]: "Amend commission",
  [ACTIONS.UPDATE_SUBMISSION]: "Edit submission",
};

export default ACTION_TEXT_MAP;
