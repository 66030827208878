export const getColorFromSchemaRef = (schemaRef) => {
  if (schemaRef === "cargo-us-annual") {
    return "blue";
  }

  if (schemaRef === "cargo-us-single") {
    return "green";
  }

  if (schemaRef === "mel") {
    return "green";
  }

  return "gray";
};
