import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { ACTIONS, STATUSES } from "../../../constants";
import { currencyFormatter, dateFormatter } from "../../../formatters";
import Banner from "../../components/Banner";
import { useAuth } from "../../hooks";
import Banners_v2 from "./Banners_v2";

const defaultProductRef = process.env.DEFAULT_PRODUCT_REF;

const Banners = ({ contractData }) => {
  const { checkPermissions } = useAuth(contractData);
  const { status, type } = contractData;
  const isEndorsement = type === "endorsement";
  const isReferred = status === STATUSES.REFERRED;
  const isNtu = status === STATUSES.REJECTED_NOT_TAKEN_UP;
  const isNp = status === STATUSES.REJECTED_NOT_PROGRESSED;
  const isDeclined = status === STATUSES.DECLINED || status === STATUSES.DECLINED_EXPIRED;
  const isCancelled = status === STATUSES.CANCELLED || (isEndorsement && get(contractData, "cancellation"));
  const hasEndoPremium = Boolean(get(contractData, "endorsement_premium"));
  const actualPremium = get(contractData, "endorsement_premium.actualPremium");
  const actualPremiumAmount = get(actualPremium, "amount");
  const referralReasons = get(contractData, "referralReasons", []);
  const isReferredCancellation = isReferred && referralReasons.includes("Known losses.");

  return (
    <>
      {checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && isReferred && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={`This ${
            (isReferredCancellation && "cancellation") || (isEndorsement && "endorsement") || "submission"
          } has been referred to you for the following reason(s):`}
        >
          <ul className="list-disc list-inside">
            {referralReasons.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </Banner>
      )}

      {!checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) && isReferred && (
        <Banner
          className="mb-8"
          color="orange"
          headingText={`Your ${
            (isReferredCancellation && "cancellation") || (isEndorsement && "endorsement") || "submission"
          } has been referred to our underwriters for review.`}
        />
      )}

      {isNtu && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not taken up for the following reason(s):"
        >
          {contractData?.rejectionReasons && (
            <ul className="list-disc list-inside">
              {contractData?.rejectionReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.rejectionNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.rejectionNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isNp && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to not progressed for the following reason(s):"
        >
          {contractData?.rejectionReasons && (
            <ul className="list-disc list-inside">
              {contractData?.rejectionReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.rejectionNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.rejectionNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isDeclined && (
        <Banner
          className="mb-8"
          color="red"
          headingText="Your submission was set to decline to quote for the following reason(s):"
        >
          {contractData?.declineReasons && (
            <ul className="list-disc list-inside">
              {contractData?.declineReasons?.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          )}

          {contractData?.declineNote && (
            <div className="mt-4">
              <p className="font-semibold">Note:</p>
              <p>{contractData?.declineNote}</p>
            </div>
          )}
        </Banner>
      )}

      {isReferredCancellation && (
        <Banner className="mb-8" color="red" headingText="Cancellation details.">
          {contractData?.cancellationReason && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {contractData?.cancellationReason}
            </p>
          )}

          {contractData?.cancellationDate && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(contractData?.cancellationDate)}
            </p>
          )}
        </Banner>
      )}

      {isCancelled && (
        <Banner className="mb-8" color="red" headingText="This policy has been cancelled.">
          {contractData?.cancellationReason && (
            <p className="mb-2">
              <span className="font-medium">Reason for cancellation: </span>
              {contractData?.cancellationReason}
            </p>
          )}

          {contractData?.cancellationDate && (
            <p className="mb-2">
              <span className="font-medium">Cancellation date: </span>
              {dateFormatter(contractData?.cancellationDate)}
            </p>
          )}
        </Banner>
      )}

      {hasEndoPremium && isEndorsement && (
        <Banner className="mb-8" color="red" headingText="Endorsement premium change:">
          {actualPremium && (
            <p className="mb-2">
              {actualPremiumAmount < 0 && <span className="font-medium">Return premium: </span>}
              {actualPremiumAmount > 0 && <span className="font-medium">Additional premium: </span>}
              {currencyFormatter({ amount: Math.abs(actualPremium?.amount) })}
            </p>
          )}
        </Banner>
      )}
    </>
  );
};

Banners.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default defaultProductRef === "mel" ? Banners : Banners_v2;
