import moment from "moment";
import { SchemaRef } from "../../@types/types";
import { asDate, countryIsWarAndStrikeIncluded, countryToRegion, countryToWNRAD, getFormattedAddress } from "./helpers";
import cargoTypes from "./refData/cargoTypes";
import countries from "./refData/countries";
import table10b from "./refData/table10b";
import {
  Cargo,
  CargoGroup,
  CargoSubmission,
  CargoTempControl,
  Conveyance,
  Exhibition,
  LossHistory,
  LossHistoryTotal,
  ParsedSubmission,
  Shipment,
  StorageCover,
} from "./types";

export const parseSubmission = (submission: CargoSubmission, schemaRef: SchemaRef): ParsedSubmission => {
  const isSingle = schemaRef === "cargo-us-single";
  const result: ParsedSubmission = {
    insured: {},
    contract: {},
    cargo: {},
    conveyance: {},
    shipment: {},
    exhibition: {},
    storage: {},
    samples: {},
  };

  if (submission?.insured?.country_iso !== undefined) {
    result.insured.country = submission.insured.country_iso;
    result.insured.region = countryToRegion(submission.insured.country_iso, true);
  }

  if (submission?.insured?.subsidiary_companies !== undefined) {
    result.insured.subsidiaryCompaniesFormatted = submission?.insured?.subsidiary_companies?.filter(Boolean).join(", ");
  }

  if (submission?.insured?.associated_companies !== undefined) {
    result.insured.associatedCompaniesFormatted = submission?.insured?.associated_companies?.filter(Boolean).join(", ");
  }

  if (submission?.insured?.insured_name !== undefined) {
    result.insured.name = submission.insured.insured_name;

    result.insuredName = submission.insured.insured_name;
  }

  if (submission?.insured?.trading_currency !== undefined) {
    result.insured.tradingCurrency = submission.insured.trading_currency;
  }

  if (submission?.insured?.company_turnover !== undefined) {
    result.insured.companyTurnover = submission.insured.company_turnover;
  }

  if (submission?.insured?.type_of_customer !== undefined) {
    result.insured.typeOfCustomer = submission.insured.type_of_customer;
  }

  if (submission?.insured?.insured_address !== undefined) {
    result.insured.address = {};
    result.insured.address.addressLine1 = submission.insured.insured_address.address_line_1;
    result.insured.address.city = submission.insured.insured_address.city;
    result.insured.address.zipcode = submission.insured.insured_address.zipcode;
    result.insured.address.state = submission.insured.insured_address.state;
    result.insured.address.country = submission.insured.insured_address.country;
    result.insured.formattedAddress = getFormattedAddress(submission.insured.insured_address, ", ");
  }

  if (submission?.insured?.insurance_claim_history_refused !== undefined) {
    result.insured.hadInsuranceRefused = submission.insured.insurance_claim_history_refused === "Yes";
  }

  if (submission?.insured?.insurance_claim_history_reason !== undefined) {
    result.insured.insuranceRefusedReason = submission.insured.insurance_claim_history_reason;
  }

  if (submission?.insured?.loss_history_five_years === "Yes" && submission?.insured?.loss_history_breakdown) {
    const lossHistory: LossHistory[] = submission.insured.loss_history_breakdown.map((item: any) => ({
      year: item.loss_history_year,
      premium: item.loss_history_premium,
      loss: item.loss_history_losses,
    }));

    const lossHistoryTotal: LossHistoryTotal = lossHistory.reduce(
      (prev: LossHistoryTotal, next: LossHistory) => ({
        losses: prev.losses + (next?.loss?.amount || 0),
        premiums: prev.premiums + (next?.premium?.amount || 0),
      }),
      { losses: 0, premiums: 0 },
    );

    result.insured.lossHistoryTotal = lossHistoryTotal;
    result.insured.lossHistory = lossHistory;
  }

  if (submission?.insured?.client_trade !== undefined) {
    result.insured.clientTrade = submission?.insured?.client_trade;
  }

  if (submission?.contract?.inception_date !== undefined) {
    result.contract.inceptionDateIso = submission.contract.inception_date;
    result.contract.inceptionDateFormatted = asDate(submission.contract.inception_date);

    result.inceptionDate = submission.contract.inception_date;
  }

  const type = isSingle ? "single" : submission?.contract?.contract_period === "No" ? "annual" : "contract";
  result.contract.type = type;

  if (submission?.contract?.expiry_date !== undefined) {
    result.contract.expiryDateIso = submission.contract.expiry_date;
    result.contract.expiryDateFormatted = asDate(submission.contract.expiry_date);
  }

  if (submission?.contract?.inception_date !== undefined && submission?.contract?.expiry_date !== undefined) {
    const inceptionMoment = moment.utc(submission.contract.inception_date).startOf("day");
    const expiryMoment = moment.utc(submission.contract.expiry_date).startOf("day");
    const isInceptionBeforeToday = moment.utc(submission.contract.inception_date).isBefore(moment.utc(), "day");
    const policyPeriodInDays = expiryMoment.diff(inceptionMoment, "day");

    const isContractPeriodLessOrGreaterThan12Months = result?.contract?.type === "contract" ?? false;
    const policyPeriodInMonths = isContractPeriodLessOrGreaterThan12Months
      ? expiryMoment.diff(inceptionMoment, "months")
      : 12;

    result.contract.policyPeriodInDays = policyPeriodInDays;
    result.contract.policyPeriodInMonths = policyPeriodInMonths;
    result.contract.isInceptionBeforeToday = isInceptionBeforeToday;
  }

  if (submission?.cargo_details?.condition_of_cargo !== undefined) {
    result.cargo.condition = submission.cargo_details.condition_of_cargo;
  }

  if (submission?.cargo_details?.icc_clause !== undefined) {
    result.cargo.iccClause = submission.cargo_details.icc_clause === "Yes";
  }

  if (submission?.cargo_details?.cargo_details_breakdown !== undefined) {
    const cargos: Cargo[] = submission.cargo_details.cargo_details_breakdown.map((cargo: any, index: number): Cargo => {
      const row = cargoTypes.find((row) => row[0] === cargo?.cargo_type);

      return {
        deductible: cargo?.cargo_type_deductible,
        group: row?.[1] as CargoGroup,
        isExcluded: isSingle ? (row?.[4] as boolean) : (row?.[3] as boolean),
        isNotSuitablyPacked: cargo.packing_declaration && cargo.packing_declaration === "No",
        isReferred: row?.[2] as boolean,
        isTempControlOther: cargo?.temperature_control && cargo.temperature_control === "Other",
        percentage: isSingle ? 1 : cargo?.cargo_type_percentage / 100 || 0,
        seqNumber: index + 1,
        tempControl: cargo?.temperature_control as CargoTempControl,
        type: cargo?.cargo_type,
        descriptionOfInterestForCert: cargo?.description_of_interest_for_cert,
        marksNumbers: cargo?.marks_numbers,
      };
    });

    result.cargo.cargos = cargos;
  }

  if (submission?.cargo_details?.duty !== undefined) {
    result.cargo.addDuty = submission.cargo_details.duty === "Yes";
  }

  if (submission?.cargo_details?.basis_of_valuation !== undefined) {
    result.cargo.basisOfValuation = submission?.cargo_details?.basis_of_valuation;
  }

  if (submission?.cargo_details?.basis_of_valuation_percentage !== undefined) {
    const percentage = submission.cargo_details.basis_of_valuation_percentage;

    result.cargo.basisOfValuationPercentage = percentage === 0 ? 0 : percentage / 100;
  }

  if (!isSingle && submission?.conveyance_limits?.conveyance_breakdown !== undefined) {
    const conveyances: Conveyance[] = submission.conveyance_limits.conveyance_breakdown.map(
      (item: any, index: number): Conveyance => {
        const aovTransits = item?.aov_transits === "Yes";

        return {
          seqNumber: index + 1,
          aovTransits,
          containerType: item?.container_load_type,
          limit: item?.limit_per_conveyance,
          ownVehicleLimit: item?.own_vehicle_transit_limit,
          turnoverPercentage: item?.percentage_of_turnover / 100,
          ownVehicleTurnoverPercentage: item?.own_vehicle_percentage_of_turnover / 100,
          type: item?.conveyance_type,
        };
      },
    );

    result.conveyance.conveyances = conveyances;
  }

  if (isSingle && submission?.conveyance_limits !== undefined) {
    const conveyances: Conveyance[] = [
      {
        aovTransits: submission?.conveyance_limits?.aov_transits && submission.conveyance_limits.aov_transits === "Yes",
        containerType: submission?.conveyance_limits?.container_load_type,
        insuredValue: submission?.conveyance_limits?.insured_value,
        type: submission?.conveyance_limits?.conveyance_type,
        vesselName: submission?.conveyance_limits?.vessel_name,
        imoNumber: submission?.conveyance_limits?.imo_number,
        flightAirlineNumber: submission?.conveyance_limits?.flight_airline_number,
        carOnDeck: submission?.conveyance_limits?.car_on_deck === "Yes",
      },
    ];

    result.conveyance.conveyances = conveyances;
  }

  if (submission?.shipment_details_annual?.shipments !== undefined) {
    const shipments: Shipment[] = submission.shipment_details_annual.shipments.map(
      (item: any, index: number): Shipment => {
        const byCountry = item?.geographical_area_by_country_region === "Country";
        const row = countries?.find((row) => row[0] === item?.country);

        return {
          contingentTurnover: item?.geographical_area_turnover_contingent,
          country: item?.country,
          isDeclined: byCountry && (row?.[6] as boolean),
          isReferred: byCountry && (row?.[5] as boolean),
          ownTurnover: item?.geographical_area_turnover,
          region: byCountry && item.country !== undefined ? countryToRegion(item.country) : item?.geographical_areas,
          seqNumber: index + 1,
        };
      },
    );

    result.shipment.shipments = shipments;
  }

  if (submission?.shipment_details_annual?.inland_transit !== undefined) {
    result.shipment.inlandTransit = submission.shipment_details_annual.inland_transit === "Yes";
  }

  if (isSingle && submission?.shipment_details_single?.voyage_from_country !== undefined) {
    const countryFrom = submission?.shipment_details_single?.voyage_from_country;
    const regionFrom = countryToRegion(countryFrom);
    const WNRADFrom = countryToWNRAD(countryFrom);

    result.shipment.countryFrom = countryFrom;
    result.shipment.regionFrom = regionFrom;
    result.shipment.WNRADFrom = WNRADFrom;
    result.shipment.countryFromIsWarAndStrikesIncluded = countryIsWarAndStrikeIncluded(countryFrom);
  }

  if (isSingle && submission?.shipment_details_single?.voyage_to_country !== undefined) {
    const countryTo = submission?.shipment_details_single?.voyage_to_country;
    const regionTo = countryToRegion(countryTo);
    const WNRADTo = countryToWNRAD(countryTo);

    result.shipment.countryTo = countryTo;
    result.shipment.regionTo = regionTo;
    result.shipment.WNRADTo = WNRADTo;
    result.shipment.countryToIsWarAndStrikesIncluded = countryIsWarAndStrikeIncluded(countryTo);
  }

  if (isSingle && submission?.shipment_details_single?.voyage_from_town !== undefined) {
    result.shipment.cityFrom = submission?.shipment_details_single?.voyage_from_town;
  }

  if (isSingle && submission?.shipment_details_single?.voyage_to_town !== undefined) {
    result.shipment.cityTo = submission?.shipment_details_single?.voyage_to_town;
  }

  if (isSingle && submission?.shipment_details_single?.date_of_transit !== undefined) {
    const dateOfTransit = submission?.shipment_details_single?.date_of_transit;
    const isDateOfTransitBeforeToday = moment.utc(submission.shipment_details_single.date_of_transit).isBefore(moment.utc(), "day");

    result.shipment.dateOfTransit = dateOfTransit;
    result.shipment.dateOfTransitFormatted = asDate(dateOfTransit);
    result.shipment.isDateOfTransitBeforeToday = isDateOfTransitBeforeToday;
  }

  if (isSingle) {
    const additionalInformation = {
      isLossPayeeDifferentToInsured: submission?.additional_information?.loss_payee_different_to_insured === "Yes",
      lossPayeeDetailsName: submission?.additional_information?.loss_payee_details_name,
      lossPayeeDetailsAddress: {
        addressLine1: submission?.additional_information?.loss_payee_details_address?.address_line_1,
        addressLine2: submission?.additional_information?.loss_payee_details_address?.address_line_2,
        city: submission?.additional_information?.loss_payee_details_address?.city,
        country: submission?.additional_information?.loss_payee_details_address?.country,
        state: submission?.additional_information?.loss_payee_details_address?.state,
        zipcode: submission?.additional_information?.loss_payee_details_address?.zipcode,
      },
      lossPayeeDetailsFormattedAddress: submission?.additional_information?.loss_payee_details_address
        ? getFormattedAddress(submission.additional_information.loss_payee_details_address, ", ")
        : "",
      surveyorName: submission?.additional_information?.surveyor_name,
      surveyorAddress: {
        addressLine1: submission?.additional_information?.surveyor_address?.address_line_1,
        addressLine2: submission?.additional_information?.surveyor_address?.address_line_2,
        city: submission?.additional_information?.surveyor_address?.city,
        country: submission?.additional_information?.surveyor_address?.country,
        state: submission?.additional_information?.surveyor_address?.state,
        zipcode: submission?.additional_information?.surveyor_address?.zipcode,
      },
      surveyorFormattedAddress: submission?.additional_information?.surveyor_address
        ? getFormattedAddress(submission?.additional_information?.surveyor_address, "\n")
        : "",
      assuredOwnReference: submission?.additional_information?.assured_own_reference,
      letterOfCreditWording: submission?.additional_information?.letter_of_credit_wording,
    };

    result.additionalInformation = additionalInformation;
  }

  if (submission?.exhibitions?.exhibitions_cover !== undefined) {
    const hasExhibitions = submission.exhibitions.exhibitions_cover === "Yes";

    result.exhibition.hasExhibitions = hasExhibitions;
  }

  if (!isSingle && submission?.exhibitions?.exhibitions !== undefined) {
    const exhibitions: Exhibition[] = submission?.exhibitions?.exhibitions?.map(
      (item: any, index: number): Exhibition => ({
        duration: item?.exhibition_duration,
        limit: item?.exhibition_limit,
        number: item?.exhibition_number,
        region: item?.exhibition_area,
        seqNumber: index + 1,
      }),
    );

    result.exhibition.exhibitions = exhibitions;
  }

  if (isSingle && submission?.exhibitions !== undefined) {
    const exhibitions: Exhibition[] = [
      {
        duration: submission?.exhibitions?.exhibition_duration,
        region: submission?.exhibitions?.exhibition_area,
        returnBack: submission?.exhibitions?.exhibition_return && submission.exhibitions.exhibition_return === "Yes",
      },
    ];

    result.exhibition.exhibitions = exhibitions;
  }

  if (submission?.storage?.storage_cover !== undefined) {
    const hasStorages = submission.storage.storage_cover === "Yes";

    result.storage.hasStorages = hasStorages;
  }

  if (submission?.storage?.storages !== undefined) {
    const storages: StorageCover[] = submission?.storage?.storages?.map((item: any, index: number): StorageCover => {
      const storage: StorageCover = {
        seqNumber: index + 1,
      };

      if (item?.storage_location_address !== undefined) {
        const country = item?.storage_location_address?.country;
        const usState = item?.storage_location_address?.state;

        storage.formattedAddress = getFormattedAddress(item?.storage_location_address);
        storage.address = {};
        storage.address.addressLine1 = item?.storage_location_address?.address_line_1;
        storage.address.city = item?.storage_location_address?.city;
        storage.address.zipcode = item?.storage_location_address?.zipcode;
        storage.address.country = country;

        if (country !== undefined) {
          const region = countryToRegion(country);

          storage.region = region;
          storage.isOutsideWesternEuropeUsAndCanada = region !== "Western Europe" && region !== "USA & Canada";
        }

        /* Table 10b: US State Peril Table */
        if (country === "United States") {
          const row = table10b?.find((item) => item[0] === usState);

          storage.address.state = item?.storage_location_address?.state;

          if (row !== undefined) {
            storage.perils = [
              { level: row[2], type: "Quake" },
              { level: row[3], type: "Windstorm" },
              { level: row[4], type: "Inland Wind" },
            ];
          }
        }
      }

      if (item?.storage_location_alarm_maintemance_contract !== undefined) {
        storage.alarmsNoMaintenance = item?.storage_location_alarm_maintemance_contract === "No";
      }

      if (item?.storage_location_alarms_monitored !== undefined) {
        storage.alarmsNotMonitored = item?.storage_location_alarms_monitored === "No";
      }

      if (item?.storage_location_fire_security_alarms !== undefined) {
        storage.alarmsSecurity = item?.storage_location_fire_security_alarms;
      }

      if (item?.storage_location_average_in_store !== undefined) {
        storage.averageInStorePercentage = item?.storage_location_average_in_store / 100;
      }

      if (item?.storage_location_building_age !== undefined) {
        storage.buildingAge = item?.storage_location_building_age;
      }

      if (item?.storage_location_composite_panels !== undefined) {
        storage.compositePanels = item?.storage_location_composite_panels === "Yes";
      }

      if (item?.storage_location_flood_claim !== undefined) {
        storage.hasFloodClaim = item?.storage_location_flood_claim === "Yes";
      }

      if (item?.storage_location_on_flood_plain !== undefined) {
        storage.isOnFloodPlain = item?.storage_location_on_flood_plain === "Yes";
      }

      if (item?.storage_enclosed_roof !== undefined) {
        storage.hasNoRoof = item?.storage_enclosed_roof === "No";
      }

      if (item?.storage_location_limit !== undefined) {
        storage.limit = item?.storage_location_limit;
      }

      if (item?.storage_location_type !== undefined) {
        storage.locationType = item?.storage_location_type;
      }

      if (item?.storage_location_multi_tenure !== undefined) {
        storage.multiTenure = item?.storage_location_multi_tenure === "Yes";
      }

      if (item?.storage_location_physical_security !== undefined) {
        storage.noPhysicalSecurity = item?.storage_location_physical_security === "No";
      }

      if (item?.storage_location_sprinklers !== undefined) {
        storage.noSprinklers = item?.storage_location_sprinklers === "No";
      }

      if (item?.storage_location_standards_compliance !== undefined) {
        storage.notStandardsCompliant = item?.storage_location_standards_compliance === "No";
      }

      if (item?.storage_location_processing !== undefined) {
        storage.isProcessingAtLocation = item?.storage_location_processing === "Yes";
      }

      if (item?.storage_location_hotwork !== undefined) {
        storage.isHotworkCarriedOut = item?.storage_location_hotwork === "Yes";
      }

      if (item?.storage_location_floor_construction !== undefined) {
        storage.floorConstruction = item?.storage_location_floor_construction;
      }

      if (item?.storage_location_walls_construction !== undefined) {
        storage.wallConstruction = item?.storage_location_walls_construction;
      }

      if (item?.storage_location_roof_construction !== undefined) {
        storage.roofConstruction = item?.storage_location_roof_construction;
      }

      if (item?.storage_location_physical_security_type !== undefined) {
        storage.physicalSecurityTypes = item?.storage_location_physical_security_type;
      }

      return storage;
    });

    result.storage.storages = storages;
  }

  if (submission?.samples?.samples_cover !== undefined) {
    result.samples.hasSamples = submission.samples.samples_cover === "Yes";
  }

  if (submission?.samples?.number_of_vehicles !== undefined) {
    result.samples.numVehicles = submission?.samples?.number_of_vehicles;
  }

  if (submission?.samples?.samples_limit !== undefined) {
    result.samples.limit = submission?.samples?.samples_limit;
  }

  return result;
};
